import { ColumnDef } from "@tanstack/react-table";
import { DataTableRowActionsGiftVou } from "./data-table-row-actions";
import { GiftVoucherT } from "@/react-app-env";
import { DataTableColumnHeader } from "../Table/data-table-column-header";


export const GiftVoucherColumns=({  onEdit,onDelete }: {onEdit:any,onDelete:any}): ColumnDef<GiftVoucherT>[] => [
  {
    accessorKey: "branchName",
    header: "Branch Name",
    cell: ({ row }) => (
      <div className="text-left">{row.getValue("branchName")}</div>
    ),
  },
  {
    accessorKey: "voucherCode",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Voucher Code" />
    ),
    cell: ({ row }) => (
      <div className="text-left">{row.getValue("voucherCode")}</div>
    ),
  },
  {
    accessorKey: "voucherAmount",
    header: "Voucher Amount",
    cell: ({ row }) => (
      <div className="text-left">{row.getValue("voucherAmount")}</div>
    ),
  },
  {
    accessorKey: "quantity",
    header: "Qty",
    cell: ({ row }) => (
      <div className="text-left">{row.getValue("quantity")}</div>
    ),
  },
 
  {
    id: "actions",
    cell: ({ row }) => <DataTableRowActionsGiftVou row={row}  onEdit={  onEdit } onDelete={  onDelete }   />,
  }, 
];
