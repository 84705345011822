import {  CheckoutProductT } from "@/react-app-env";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import CustomerInfo, { PromotionDetails } from "./CustomerInfo";
import { Label } from "../../../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../../components/ui/radio-group";
import { useEffect } from "react";
import { formatToCurrency } from "../../../constants";

const ShippingInfo = ({ user, reftechUser, setAddressId,addressId }: any) => {

  const handleAddressChange = (value: string) => {
    setAddressId(value)
  };
  const { fullName, address } = user || {};

  useEffect(() => {
    if(addressId){
      setAddressId(addressId);
    }else{
      setAddressId(address?.length > 0 && address[0]?.id);
    }
  }, [address, addressId, setAddressId]);

  return (
    <div className="border-2 border-gray-500 my-2 md:p-4 flex flex-col md:flex-row md:justify-between gap-6 min-h-[200px]">
      <div className="flex flex-col gap-4">
        <h5 className="h5-bold">Shipping Info</h5>
        {address?.length > 0 && <RadioGroup defaultValue={addressId || address[0]?.id} onValueChange={handleAddressChange} >
          {address?.length > 0 &&
            address.map((add: any) => {
              let detail;
              const { hno, street, city, pincode, state, country, fullName } = JSON.parse(add?.address);
              detail = (
                <div className="flex gap-2 w-full flex-wrap" key={add?.id}>
                  <RadioGroupItem value={add?.id} id={add?.id} />
                  <Label htmlFor={add?.id}> <p>
                    Shipping to: <strong>{fullName}</strong>
                  </p>
                    <div className="flex gap-1">
                      <p>
                        {hno}, {street}, {city}, {pincode}, {state}, {country}
                      </p>
                      <CustomerInfo
                        customerId={user.id}
                        id={add?.id}
                        address={add?.address}
                        type="Edit"
                        reftechUser={reftechUser}
                      />
                    </div></Label>
                </div>
              );
              return detail;
            })}
        </RadioGroup>}
        <CustomerInfo customerId={user?.id} type="Create" reftechUser={reftechUser} />
      </div>
    </div>
  );
};

export const PaymentInfo = ({ setOrderTotal, setCurrency, user, addressId ,setGiftVou, giftVou,cartData }: any) => {
  const { currency } = useSelector((state: RootState) => state.counterCurrency);

  const Subtotal = cartData?.result?.products?.length>0 ? (cartData?.result?.products?.reduce((acc: number, product: CheckoutProductT) => {  
    return acc + product?.grossAmount;
  }, 0)) : 0

  let shippingAmount = cartData?.result?.shippingCost?cartData?.result?.shippingCost : 0
  // let shippingAmount = cartData?.result?.length>0 ? (cartData?.result?.reduce((acc: number, product: CheckoutProductT) => {
  //   return acc + product?.shippingCost;
  // }, 0)) : 0

  const GstAmount = cartData?.result?.products?.length>0 ? (cartData?.result?.products?.reduce((acc: number, product: CheckoutProductT) => {
    return acc + product?.cgstAmount+product?.igstAmount+product?.sgstAmount;
  }, 0)) : 0

  const DisAmount =  cartData?.result?.products?.length>0 ? (cartData?.result?.products?.reduce((acc: number, product: CheckoutProductT) => {
    return acc + product?.discountAmount
  }, 0)) : 0

  let orderTotal = cartData?.result?.products?.length>0 ? (cartData?.result?.products?.reduce((acc: number, product: CheckoutProductT) => {
    return acc + product?.netAmount
  }, 0)) : 0


  const { fullNam, address } = user || {};
  const selectedAddress = address?.find((add: any) => add.id === addressId)
  const { hno, street, city, pincode, state, country, fullName } = JSON.parse(selectedAddress?.address);

  useEffect(() => {
    setOrderTotal(orderTotal-giftVou?.amount+shippingAmount)
    setCurrency(currency)
  }, [Subtotal, GstAmount, currency, setCurrency, orderTotal, setOrderTotal, giftVou?.amount,shippingAmount]);

  return <div className="border-2 border-gray-500 my-2 md:p-4 flex flex-col md:flex-row md:justify-between gap-6 min-h-[200px]">
    <div className="space-y-4">
      <h5 className="h5-bold">Shipping Info</h5>
      <div className="space-y-1 pl-3" >
        <p> Shipping to: <strong>{fullName}</strong> </p>
        <div className="flex gap-1 flex-wrap pl-1">{hno}, {street}, {city}, {pincode}, {state}, {country}</div>
      </div>
      <PromotionDetails giftVou={giftVou}setGiftVou={setGiftVou} />
    </div>
    {orderTotal?<div className="shadow-lg p-4  md:w-[350px] w-full border-green-900 border-2 text-sm  flex flex-col gap-5 ">
      <p className="text-lg font-semibold">Price Details:</p>
      <div className="flex justify-between gap-4">
        <p>Subtotal</p>
        <p className="flex items-center">{formatToCurrency(Subtotal, currency)}</p>
      </div>
      {DisAmount > 0 && <div className="flex justify-between gap-4">
        <p>Discount Amount</p>
        <p className="flex items-center">-{formatToCurrency(DisAmount, currency)}</p>
      </div>}
      {giftVou.id > 0 && <div className="flex justify-between gap-4">
        <p>Gift Voucher Amount</p>
        <p className="flex items-center">-{formatToCurrency(giftVou.amount, currency)}</p>
      </div>}
      <div className="flex justify-between gap-4">
        <p>GST Amount</p>
        <p className="flex items-center">{formatToCurrency(GstAmount, currency)}</p>
      </div>
      <div className="flex justify-between gap-4">
        <p>Shipping Fee</p>
        <p className="flex items-center">{formatToCurrency(shippingAmount, currency)}</p>
      </div>
      <div className="flex justify-between gap-4 text-lg text-green-800 font-bold">
        <p>Total Price</p>
        <p className="flex items-center">{formatToCurrency(orderTotal-giftVou?.amount+shippingAmount, currency)}</p>
      </div>
    </div>:null}
  </div>
}


export default ShippingInfo;

