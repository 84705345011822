import { apiSlice } from "../../../redux/apiSlice";


export const AproductsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addNewProduct: builder.mutation({
            query: initialProdData => ({
                url: '/product/saveProduct',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Product', id: "LIST" }
            ]
        }),
        getAllBranch: builder.query({
            query: () => ({ url: "/admin/getAllBranch" }),
            providesTags: (result, error, arg) => {
              if (result?.ids) {
                return [
                  { type: "Branch", id: "LIST" },
                  ...result.ids.map((id: any) => ({ type: "Branch", id })),
                ];
              } else return [{ type: "Branch", id: "LIST" }];
            },
          }),
        updateProduct: builder.mutation({
            query: initialProdData => ({
                url: '/product/updateProduct',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Product', id: "LIST" }
            ]
        }),
        updateProductStock: builder.mutation({
            query: initialProductData => ({
                url: '/product/updateProductStock',
                method: 'POST',
                body: { ...initialProductData }
            }),
            invalidatesTags: [
                { type: 'ProductStock', id: "LIST" }
            ]
        }),
        getProductStockByProductId: builder.mutation({
            query: initialProductData => ({
                url: '/product/getProductStockByProductId',
                method: 'POST',
                body: { ...initialProductData }
            }),
            invalidatesTags: [
                { type: 'ProductStock', id: "LIST" }
            ]
        }),
        deleteProductA: builder.mutation({
            query: ( id ) => ({
                url: `/product/deleteProduct/${id}`,
                method: 'DELETE',
                }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Product', id: arg.id }
            ]
        }),
    }),
})

export const {
    useAddNewProductMutation,useGetProductStockByProductIdMutation,
    useDeleteProductAMutation,useUpdateProductStockMutation,useGetAllBranchQuery,
    useUpdateProductMutation
} = AproductsApiSlice

