import React, { Dispatch,  useEffect, useMemo, useState } from 'react'
import { Button } from '../../components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog"
import { Input } from "../../components/ui/input"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form"
import { toast } from 'react-toastify'
import {   useGetAllHSNCodeMutation, useGetAllTaxQuery, useSaveHSNMutation } from '../../redux/slice/admin/ACategoryApiSlice'
import { DataTable } from '../../components/shared/admin/components/Table/data-table'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select'
import { HSNDefaultValues, HSNFormSchema } from '../../constants'
import { HSNCodeColumns } from '../../components/shared/admin/components/HSNTable/HSNCodeColumns'
import { TaxDetailsT } from '@/react-app-env'
import { Textarea } from '../../components/ui/textarea'

const HSNCodeSettings = () => {
  const [getAllHSNCode, { data: hsnData, isLoading, isSuccess, isError }] = useGetAllHSNCodeMutation()
  const [refetch, setRefetch] = useState(false)
  const [pageSize, setPageSize] = useState(20)
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    getAllHSNCode({ pageSize, pageNumber })
  }, [getAllHSNCode, pageNumber, pageSize, refetch])

  const columns = useMemo(() => HSNCodeColumns({ setRefetch }), []);

let content;

if (isLoading) {
  content = (
    <div className="flex justify-center items-center h-full w-full my-auto mx-40">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
    </div>
  );
}

if (isError) content = <p>{hsnData?.result?.message}</p>

if (isSuccess){
  content=  <section >
  <main className=" border-2 rounded-md border-gray-500 md:m-2">
     <div className="flex justify-between p-4">
      <h5 className='h5-bold'>HSN Code</h5>
      <AddHSNCode setRefetch={setRefetch} />
    </div>
    {hsnData?.result.length>0?<div className=" px-2">
      {hsnData?.result?.length>0 &&    <DataTable data={hsnData?.result} columns={columns} 
       pageSize={pageSize} setPageSize={setPageSize} pageNumber={pageNumber} setPageNumber={setPageNumber} dataCount={hsnData?.dataCount} />}
    </div>:<p className=" p-4 rounded-md border-2  md:overflow-hidden shadow-lg">
      No HSN Codes Available 
    </p>}
  </main>
  </section>
}

return <>{content}</>;

}

export function AddHSNCode({ setRefetch }: { setRefetch: Dispatch<React.SetStateAction<boolean>> }) {
  const [open, setOpen] = useState(false)
  const [SaveHSN, { data: taxD, isSuccess }] = useSaveHSNMutation()

  const form = useForm<z.infer<typeof HSNFormSchema>>({
    resolver: zodResolver(HSNFormSchema),
    defaultValues: HSNDefaultValues
  })

  useEffect(() => {
    if (isSuccess && taxD?.success) {
      toast.success("New HSN Code Added Successfully")
      setRefetch(p => !p)
    }
  }, [isSuccess, setRefetch, taxD?.success])

  async function onSubmit(values: z.infer<typeof HSNFormSchema>) {
    await SaveHSN({ ...values })
    handleClose()
  }

  const { reset } = form;
  const handleClose = () => {
    setOpen(false);
    reset()
  };

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true)
      } else {
        handleClose()
      }
    }}>
      <DialogTrigger asChild>
        <Button size={"sm"} className='bg-gradient-to-r from-green-500 to-green-600 py-1'>Add HSN Code</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px] overflow-y-auto max-h-screen">
        <DialogHeader>
          <DialogTitle>Add HSN Code</DialogTitle>

        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="md:grid-cols-2 grid-cols-1 gap-4 py-2 grid">
            <FormField
              control={form.control}
              name="chapterCode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Chapter Code</FormLabel>
                  <FormControl>
                    <Input placeholder="Chapter Code" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="chapterName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Chapter Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Chapter Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="hsnCode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>HSN Code</FormLabel>
                  <FormControl>
                    <Input placeholder="HSN Code" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="taxId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tax</FormLabel>
                  <FormControl>
                    <TaxSelect value={field.value} onChange={field.onChange} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className=" md:col-span-2 col-span-1">
              <FormField
                control={form.control}
                name="productDescription"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea placeholder="Description" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className='flex md:gap-10 gap-4 my-3 md:col-span-2 col-span-1 items-end'>
              <Button type="button" onClick={handleClose}>Cancel</Button>
              <Button type="submit" className="headermenu">Save HSN Code</Button>
            </div>
          </form>
        </Form>


      </DialogContent>
    </Dialog>
  )
}

export const TaxSelect = ({ onChange, value }: { onChange: any, value: number }) => {
  const { data: taxData, } = useGetAllTaxQuery("taxList", { refetchOnFocus: true, refetchOnMountOrArgChange: true })

  const handleChange = (value: String) => {
      onChange(Number(value))
  }

  return (
      <>
           <Select value={value?.toString()} onValueChange={handleChange}>
              <SelectTrigger className="w-full bg-white border border-gray-400 h-10">
                  <SelectValue placeholder="Select Branch" />
              </SelectTrigger>
              <SelectContent className="bg-white">
                  {taxData?.result?.map((tax: TaxDetailsT) => <SelectItem value={tax.id.toString()} key={tax.id}>{`${tax.taxName}` }</SelectItem>)}
              </SelectContent>
          </Select>
      </>
  )
}
export default HSNCodeSettings