
import { apiSlice } from "../apiSlice";


export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        register: builder.mutation({
            query: credentials => ({
                url: '/webuser/registerUser',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        login: builder.mutation({
            query: credentials => ({
                url: '/webuser/authenticate',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        getCustomer: builder.query({
            query: (id) =>({url:`/customer/getCustomer/${id}`}),
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                  return [
                    { type: 'User', id: 'LIST' },
                    ...result.ids.map((id:any) => ({ type: 'User', id })),
                  ];
                } else return [{ type: 'User', id: 'LIST' }];
              },
        }),
        getAllCustomer: builder.mutation({
            query: initialUserData => ({
                url: '/customer/getAllCustomer',
                method: 'POST',
                body: { ...initialUserData}
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
        saveCustomer: builder.mutation({
            query: initialUserData => ({
                url: '/customer/saveCustomer',
                method: 'POST',
                body: { ...initialUserData}
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
        userChangePassword: builder.mutation({
            query: updateUserData => ({
                url: `/webuser/userChangePassword/${updateUserData.id}`,
                method: 'PUT',
                body: {...updateUserData,}
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
        userForgotPassword: builder.mutation({
            query: updateUserData => ({
                url: `/webuser/userForgotPassword`,
                method: 'POST',
                body: {...updateUserData,}
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
    }),
})

export const {
    useRegisterMutation,
    useGetCustomerQuery,
    useLoginMutation,
    useSaveCustomerMutation,
    useGetAllCustomerMutation,
    useUserChangePasswordMutation,
    useUserForgotPasswordMutation
} = userApiSlice

