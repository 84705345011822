import { useEffect, useMemo, useState } from "react";
import { Button } from "../../components/ui/button";
import { useGetAllProductsMutation } from "../../redux/slice/ProductsApiSlice";
import {  useNavigate, useParams } from "react-router-dom";
import { DataTable } from "../../components/shared/admin/components/Table/data-table";
import { columns } from "../../components/shared/admin/components/Table/columns";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { useGetAllCategoryQuery } from "../../redux/slice/CategoryApiSlice";



const ProductsAdmin = () => {
  const [productByCategory, { isSuccess, isError, isLoading, data: products }] = useGetAllProductsMutation()
  const [reftch, setReftch] = useState(false)
  const {catId}=useParams() ?? 1;

  useEffect(() => {
    if(catId){
      productByCategory({ catId })
    }
  }, [productByCategory,catId,reftch])

  const columnsU = useMemo(() => columns({ setReftch }), [setReftch]);


  let content;

  if (isLoading) {
    content = (
        <div className="flex justify-center items-center h-full my-6">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
    );
}

  if (isError) content = <p>{products?.result?.message}</p>

  if (isSuccess){
    content=  <section >
    <main className=" border-2  rounded-md border-gray-500 md:m-2">
       <div className="flex justify-between p-4">
        <h5 className='h5-bold'>Products</h5>
        <FilterByCategory  categoryId={catId!}/>
      </div>
      {products?.result.length>0?<div className=" px-2">
        {products?.result?.length>0 && <DataTable data={products?.result} columns={columnsU}/>}
      </div>:<p className=" p-4 rounded-md border-2  md:overflow-hidden shadow-lg ">
        No Products Available Under Selected Category
      </p>}
    </main>
    </section>
  }

  return <>{content}</>;
}

export default ProductsAdmin

 export const FilterByCategory = ({categoryId }:{ categoryId:string}) => {
  const{data:categories}=useGetAllCategoryQuery("categoriesList",{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true})
  const navigate=useNavigate()
  const handleValueChange = (selectedValue: string) => {
    navigate(`/admin/products/${selectedValue}`)
  };

  return (
    <Select onValueChange={handleValueChange} value={categoryId.toString()}>
      <SelectTrigger className="text-md font-semibold border border-gray-400 ">
        <SelectValue />
      </SelectTrigger>
      <SelectContent className='border-none bg-gray-100  hover:text-black '>
      <SelectItem
            key={"all"}
            value={"all"}
            className="select-item p-regular-14"
          >
            All
          </SelectItem>
        {categories?.result?.map((category:any) => (
          <SelectItem
            key={category.id}
            value={category.id.toString()}
            className="select-item p-regular-14"
          >
            {category.categoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}; 
/* export const FilterByCategory = ({ onChangeHandler,categoryId }: any) => {
  const{data:categories}=useGetAllCategoryQuery("categoriesList",{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true})
  const navigate=useNavigate()
  const handleValueChange = (selectedValue: string) => {
    onChangeHandler(selectedValue);
    navigate(`/admin/dashboard/products/${selectedValue}`)
  };

  return (
    <Select onValueChange={handleValueChange} value={categoryId.toString()}>
      <SelectTrigger className="bg-green-50 text-md font-semibold px-0">
        <SelectValue />
      </SelectTrigger>
      <SelectContent className='border-none bg-gray-100  hover:text-black '>
      <SelectItem
            key={"all"}
            value={"all"}
            className="select-item p-regular-14"
          >
            All
          </SelectItem>
        {categories?.result?.map((category:any) => (
          <SelectItem
            key={category.id}
            value={category.id.toString()}
            className="select-item p-regular-14"
          >
            {category.categoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}; */