
import { Card } from "../../ui/card";
import { shopByPriceCollection } from "../../../constants/index";
import { MdCurrencyRupee } from "react-icons/md";
import Slider from "react-slick";
/* import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; */
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { FaDollarSign } from "react-icons/fa";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';



// const ShopByPrice = () => {
//   const {currency} = useSelector((state: RootState)=>state.counterCurrency)

//   const settings = {
//     infinite: true,
//     autoplay: true,
//     speed: 2000,
//     autoplaySpeed: 1000,
//     slidesToShow: 5,
//     slidesToScroll: 1,
//     pauseOnHover: true,
//    prevArrow: <FaAngleLeft size={48} color="#570909" />,
//     nextArrow: <FaAngleRight size={48} color="#570909" />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 400,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <section className="wrapper-newprice bgcolorgold bg-dotted-pattern bg-contain py-3 ">
//       <div className="flex justify-center">
//         {/* <h5 className="h3-bold">Shop By Price</h5> */}
//         <h5 className="text-4xl font-medium">Shop By Price</h5>
//       </div>
//       <div className="pt-3">
//         <Slider {...settings} className="flex gap-4 my-4">
//           {shopByPriceCollection?.map((shopbyprice) => (
//             <Card
//               key={shopbyprice.id}
//               className="w-[230px] h-[330px] mx-2 relative"
//             >
//               <Link
//                to={`/shopbyprice/${currency === "USD" ? shopbyprice.priceUSD : shopbyprice.priceINR}`}
//           className="hover:z-10"
//               >
//                 <div className=" border-white rounded-lg border-2 absolute top-5 left-5 right-5 bottom-5"></div>
//                 <img
//                   src={shopbyprice.url}
//                   alt={currency==="USD"?"priceUSD":"priceINR"}
//                   width={200}
//                   height={150}
//                   className="rounded-lg w-full h-full"
//                 />
//                 <div className="flex justify-center text-2xl font-semibold text-white flex-col items-center p-1 absolute top-[40%] left-[30%] z-10">
//                   <p >Under</p>
//                   <p >
//                   {/*  {currency==="USD"? <><FaDollarSign size={20} />{shopbyprice.priceUSD}</>:
//                    <><MdCurrencyRupee size={24} />{shopbyprice.priceINR}</>} */}
//                 { new Intl.NumberFormat('en-US', { style: 'currency', currency: currency,maximumFractionDigits:0 }).format(currency==="USD"?shopbyprice.priceUSD:shopbyprice.priceINR)}
//                   </p>
//                 </div>
//               </Link>
//             </Card>
//           ))}
//         </Slider>
//       </div>
//     </section>
//   );
// };

const ShopByPrice = () => {
  const { currency } = useSelector((state: RootState) => state.counterCurrency)

  return (
    <section className="wrapper bgcolorgold bg-dotted-pattern bg-contain py-3 mt-4 font-display ">
      <div className="flex justify-center py-1">
        {/* <h5 className="h3-bold">Shop By Price</h5> */}
        <h5 className="text-4xl ">Shop By Price</h5>
      </div>
      <div className="pt-3">
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlay={true}
          autoPlaySpeed={2000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          draggable
          focusOnSelect={false}
          infinite={true}
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1250
              },
              items: 5,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0
              },
              items: 1,
              partialVisibilityGutter: 30
            },
            mobileAboveTabletBelow: {
              breakpoint: {
                max: 780, // This is the upper limit for the range
                min: 464,  // This is the lower limit for the range
              },
              items: 2, // Number of items to display in this range
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 780
              },
              items: 3,
              partialVisibilityGutter: 30
            },
            laptopMedium: {
              breakpoint: {
                max: 1250,
                min: 1024
              },
              items: 4,
              partialVisibilityGutter: 30
            }
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          slidesToSlide={1}
          swipeable
          
        >
         {shopByPriceCollection?.map((shopbyprice) => (
            <Card
              key={shopbyprice.id}
              className="carousel-card max-w-[400px] h-[350px] mx-2 relative"
            >
              <Link
                to={`/shopbyprice/${currency === "USD" ? shopbyprice.priceUSD : shopbyprice.priceINR}`}
                className="hover:z-10"
              >
                <div className=" border-white rounded-lg border-2 absolute top-5 left-5 right-5 bottom-5"></div>
                <img
                  src={shopbyprice.url}
                  alt={currency === "USD" ? "priceUSD" : "priceINR"}
                  width={200}
                  height={150}
                  className="rounded-lg w-full h-full"
                />
                <div className="flex justify-center text-2xl font-semibold text-white flex-col items-center p-1 absolute top-[40%] left-[30%] z-10">
                  <p >Under</p>
                  <p >
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: currency, maximumFractionDigits: 0 }).format(currency === "USD" ? shopbyprice.priceUSD : shopbyprice.priceINR)}
                  </p>
                </div>
              </Link>
            </Card>
          ))}
        </Carousel>
      </div>
    </section>
  );
};
// const ShopByPrice = () => {
//   const { currency } = useSelector((state: RootState) => state.counterCurrency)

//   return (
//     <section className="wrapper-newprice bgcolorgold bg-dotted-pattern bg-contain py-3 font-display ">
//       <div className="flex justify-center py-1">
//         {/* <h5 className="h3-bold">Shop By Price</h5> */}
//         <h5 className="text-4xl ">Shop By Price</h5>
//       </div>
//       <div className="pt-3">
//         <Carousel
//           additionalTransfrom={0}
//           arrows
//           autoPlay={true}
//           autoPlaySpeed={2000}
//           centerMode={false}
//           className=""
//           containerClass="container-with-dots"
//           draggable
//           focusOnSelect={false}
//           infinite={true}
//           minimumTouchDrag={80}
//           pauseOnHover
//           renderArrowsWhenDisabled={false}
//           renderButtonGroupOutside={false}
//           renderDotsOutside={false}
//           responsive={{
//             desktop: {
//               breakpoint: {
//                 max: 3000,
//                 min: 1250
//               },
//               items: 5,
//               partialVisibilityGutter: 40
//             },
//             mobile: {
//               breakpoint: {
//                 max: 464,
//                 min: 0
//               },
//               items: 1,
//               partialVisibilityGutter: 30
//             },
//             mobileAboveTabletBelow: {
//               breakpoint: {
//                 max: 780, // This is the upper limit for the range
//                 min: 464,  // This is the lower limit for the range
//               },
//               items: 2, // Number of items to display in this range
//               partialVisibilityGutter: 30,
//             },
//             tablet: {
//               breakpoint: {
//                 max: 1024,
//                 min: 780
//               },
//               items: 3,
//               partialVisibilityGutter: 30
//             },
//             laptopMedium: {
//               breakpoint: {
//                 max: 1250,
//                 min: 1024
//               },
//               items: 4,
//               partialVisibilityGutter: 30
//             }
//           }}
//           rewind={false}
//           rewindWithAnimation={false}
//           rtl={false}
//           shouldResetAutoplay
//           showDots={false}
//           slidesToSlide={1}
//           swipeable
//         >
//          {shopByPriceCollection?.map((shopbyprice) => (
//             <Card
//               key={shopbyprice.id}
//               className="2xl:w-[270px] 2xl:h-[380px]  w-[230px] max-sm:w-[310px]  h-[330px] mx-2 relative"
//             >
//               <Link
//                 to={`/shopbyprice/${currency === "USD" ? shopbyprice.priceUSD : shopbyprice.priceINR}`}
//                 className="hover:z-10"
//               >
//                 <div className=" border-white rounded-lg border-2 absolute top-5 left-5 right-5 bottom-5"></div>
//                 <img
//                   src={shopbyprice.url}
//                   alt={currency === "USD" ? "priceUSD" : "priceINR"}
//                   width={200}
//                   height={150}
//                   className="rounded-lg w-full h-full"
//                 />
//                 <div className="flex justify-center text-2xl font-semibold text-white flex-col items-center p-1 absolute top-[40%] left-[30%] z-10">
//                   <p >Under</p>
//                   <p >
//                     {new Intl.NumberFormat('en-US', { style: 'currency', currency: currency, maximumFractionDigits: 0 }).format(currency === "USD" ? shopbyprice.priceUSD : shopbyprice.priceINR)}
//                   </p>
//                 </div>
//               </Link>
//             </Card>
//           ))}
//         </Carousel>
//       </div>
//     </section>
//   );
// };

export default ShopByPrice;
