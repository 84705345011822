import * as z from "zod"

  const isImage = (file: File): boolean => {
    return typeof file.type === 'string' && file.type.startsWith('image/');
  };
  
  const isPDF = (file: File): boolean => {
    return typeof file.type === 'string' && file.type === 'application/pdf';
  };
  
  const fileSchema = z.custom<File>((file: unknown) => {
    if (file instanceof File) {
      if (isImage(file) || isPDF(file)) {
        return file;
      } else {
        throw new Error('Invalid file type. Please upload an image or a PDF.');
      }
    } else {
      throw new Error('Invalid file type. Please upload an image or a PDF.');
    }
  });
  

  function isValidBase64(value:string) {
    if (typeof value !== "string") return false;
    return value.includes("data:image") || value.includes("products")
  }
  


const productFormSchema = z.object({
    productName: z.string().min(3, {
      message: "Name must be at least 3 characters.",
    }),
    sku: z.string().min(3, {
      message: "SKU must be at least 5 characters.",
    }),
    hsnCode: z.string().min(3, {
      message: "HSN Code must be at least 5 characters.",
    }),
    // productDescription: z.string().min(2, {
    //   message: "Description must be at least 2 characters.",
    // }),
    productDescription: z.string().min(50, {
      message: "Description must be at least 50 chars.",
    }),
    categoryId: z.number().positive({
      message: "categoryId must be a positive number.",
    }),
    colorId: z.number().positive({
      message: "colorId must be a positive number.",
    }),
    availableQuantity: z.number()/* .positive({
      message: "availableQuantity must be a positive number.",
    }) */,
   /*  colorId:z.array(z.number().positive()).refine(data => data.length > 0, {
      message: 'Please select at least one color.',
    }),
    descriptionShort: z.string().refine((value) => DesShort(value), {
      message: "Description must be at least 3 lines.",
    }), */
    priceINR: z.number().positive({
      message: "priceINR must be a positive number.",
    }),
    soldQuantity: z.number()/* .positive({
      message: "soldQuantity must be a positive number.",
    }) */,
    subcategoryId: z.number().positive({
      message: "subcategoryId must be a positive number.",
    }),
    discountPercent: z.number(),
/*      thumbnail: z.string().refine((value) => value.includes("data:image/jpeg;base64"), {
      message: "Thumbnail must be a valid Base64 encoded string.",
    }),  */
      thumbnail: z.string().refine((value) => isValidBase64(value), {
        message: "Thumbnail must be a valid Base64 encoded string",
      }),
    priceUSD: z.number().positive({
      message: "discount must be a positive number.",
    }),
  });
 
  export default productFormSchema