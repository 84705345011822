import { cancellationPolicy, productSpecifications, refundPolicy, returnPolicy } from "../../constants";

const Return = () => {
  return (
    <div className="bgcolorgold">
      <div className="wrapper flex flex-col gap-4">
        <div>
          <h5 className="h5-bold">CANCELLATION AND RETURN POLICY</h5>
          <div className="flex flex-col gap-2 wrapper-weaves">
            <p className="p-regular-16">
              It takes around 7-10 business days for the delivery. Please bear
              with us, we will always try to deliver your order at the earliest.
            </p>
            <p className="p-regular-16">
              The below-detailed Cancellation and Return policies will apply to
              any product(s) sold on Sakhi.com
            </p>
          </div>
        </div>
        <div>
          <p className="p-semibold-20">RETURN POLICY</p>
          <ol className="flex flex-col gap-3 wrapper-weaves">
            {returnPolicy.map((point, index) => (
              <li key={index}>{`${index + 1}. ${point}`}</li>
            ))}
          </ol>
          <p className="wrapper-weaves">
            <strong>NOTE :</strong>If you find any issues with the product like
            (Damage, color shade, length issue, Etc.) please initiate a return
            request within 24-48 hours of receiving it. Also, please make sure
            you record a clear video of the item unboxing, as this can help us
            manage your return request more efficiently
          </p>
          <div className="wrapper-weaves">
            <p>
              <strong>Customer Support Details</strong>
            </p>
            <p>Contact Number- +91-8885252277</p>
            <p>Email Id- manager@Sakhi.com </p>
          </div>
          <div className="wrapper-weaves">
            <p>
              <strong>DESIGNATED BRAND ADDRESS-</strong>
            </p>
            <p>
              Gottipati Towers, Plots 1768/1769, Block Srinivasa Avenue, 3rd
              Layout, Pragathi Nagar, Kukatpalli, Hyderabad, Telangana, 500047
            </p>
          </div>
        </div>
        <div>
          <p className="p-semibold-20">PRODUCT SPECIFICATION(S) ON THE WEBSITE</p>
          <ol className="flex flex-col gap-3 wrapper-weaves">
            {productSpecifications.map((point, index) => (
              <li key={index}>{`${index + 1}. ${point}`}</li>
            ))}
          </ol>
        </div>
        <div>
          <p className="p-semibold-20">PROCEDURE FOR REFUNDING</p>
          <ol className="flex flex-col gap-3 wrapper-weaves">
            {refundPolicy.map((point, index) => (
              <li key={index}>{`${index + 1}. ${point}`}</li>
            ))}
          </ol>
        </div>
        <div>
          <p className="p-semibold-20">ORDER CANCELLATION</p>
          <ol className="flex flex-col gap-3 wrapper-weaves">
            {cancellationPolicy.map((point, index) => (
              <li key={index}>{`${index + 1}. ${point}`}</li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Return;
