import { DataTable } from "../../components/shared/admin/components/Table/data-table";
import { useGetAllCustomerMutation } from "../../redux/slice/UserApiSlice";
import { userColumns } from "../../components/shared/admin/components/Customers/Columns";
import { useEffect } from "react";



const Customers = () => {
  const [getAllcustomers, { isSuccess, isError, isLoading, data: getAllCustomers }] = useGetAllCustomerMutation()
  useEffect(() => {
    getAllcustomers({ isOnline: 1 })
  }, [getAllcustomers])
  let content
  if (isLoading) {
    content = (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }
  if (isError) content = <p>{getAllCustomers?.result?.message}</p>
  if (!getAllCustomers?.result?.length) {
    content = <p className="p-4 text-center text-lg font-semibold w-full">
      NO CUSTOMERS YET
    </p>
  }
  if (isSuccess && getAllCustomers?.result?.length > 0) {
    content = <>
      <div className="flex justify-between p-4">
        <h5 className='h5-bold'>CUSTOMERS</h5>
      </div>
      <div className=" p-4 rounded-md border-2 md:overflow-hidden shadow-lg">
        {getAllCustomers?.result?.length > 0 && <DataTable data={getAllCustomers?.result} columns={userColumns} />}
      </div>
    </>
  }
  return (<section className="md:p-2">
    <main className=" border-2 rounded-md  border-gray-500 ">
      {content}
    </main>
  </section>)
}

export default Customers

