import { ProductWithImages, TCartItems } from "@/react-app-env"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

type cartType={
    cartItems:TCartItems[],
}

const initialState:cartType = {
    cartItems: []
}

export const cartSlice = createSlice({
  name: 'cartSaree',
  initialState,
  reducers: {
    addSareeToCart: (state,action:PayloadAction<ProductWithImages>) => {
      const saree = action.payload
      const existingCartItem = state.cartItems.filter(item=>item.id===saree.id)

      if(!existingCartItem.length){
        state.cartItems.push({...saree,count:1})
      }else{
        if(existingCartItem[0].count<saree.availableQuantity!) {
            existingCartItem[0].count +=1
            existingCartItem[0].priceUSD +=saree.priceUSD
            existingCartItem[0].priceINR +=saree.priceINR
        }
      }
    },
    removeSareesFromCart: (state, action: PayloadAction<number>) => {
        const itemIdToRemove = action.payload;
        state.cartItems = state.cartItems.filter((item) => item.id !== itemIdToRemove);
    },
    removeAllSareesFromCart: (state) => {
        state.cartItems = [];
    },
    removeSareeFromCart: (state, action: PayloadAction<ProductWithImages>) => {
        const saree = action.payload
        const existingCartItem = state.cartItems.filter(item=>item.id===saree.id)
        if(existingCartItem[0].count>1){
            existingCartItem[0].count -=1
            existingCartItem[0].priceUSD -=saree.priceUSD
            existingCartItem[0].priceINR -=saree.priceINR
        }
        state.cartItems = state.cartItems.filter((item) => item.id !== saree.id);
    },
  },
})

export const { addSareeToCart,removeSareesFromCart,removeSareeFromCart,removeAllSareesFromCart} = cartSlice.actions


export default cartSlice.reducer