import { SubNavigation } from "../RootLayout/NavItems";


const SubHeader = () => {
  return ( 
    <header className="w-full  hidden md:block sticky top-0 z-50 bg-white ">
      <div className="flex justify-center gap-2 set-wrap ">
        <SubNavigation />
      </div>
    </header>
  );
};

export default SubHeader;
