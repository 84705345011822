"use client"
import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { Row } from "@tanstack/react-table"
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../ui/alert-dialog"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "../../../../ui/dropdown-menu"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../ui/form"
import { Button } from "../../../../ui/button"
import {  MdDeleteOutline, MdEdit } from "react-icons/md"
import { useEffect, useState } from "react"
import {  TaxDetailsT } from "@/react-app-env"
import { toast } from "react-toastify"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog"
import { useDeleteTaxMutation, useSaveTaxMutation } from "../../../../../redux/slice/admin/ACategoryApiSlice"
import { Input } from "../../../../../components/ui/input"
import { TaxFormSchema } from "../../../../../constants"

interface DataTableRowActionsProps<TData> {
  row: Row<TaxDetailsT>
}

export function DataTableRowActionsTax<TData>({
  row
}: DataTableRowActionsProps<TData>) {
  const taxD = row.original;
  const [isDeleteopen, setIsDeleteOpen] = useState<boolean>(false)
  const [isEditopen, setIsEditOpen] = useState<boolean>(false)

  return (<>
    {isDeleteopen && <DeleteTax taxD={taxD} setIsDeleteOpen={setIsDeleteOpen} isDeleteopen={isDeleteopen} />}
    {isEditopen && <EditTax taxDet={taxD} setIsEditOpen={setIsEditOpen} isEditopen={isEditopen} />}
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={()=>setIsEditOpen(true)}>
          Edit Tax<DropdownMenuShortcut><MdEdit size={20} /></DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => setIsDeleteOpen(true)}>
          Delete
          <DropdownMenuShortcut><MdDeleteOutline color="red" size={20} /></DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  </>)
}


export function EditTax({ taxDet, isEditopen, setIsEditOpen }: {  taxDet: TaxDetailsT, isEditopen: boolean, setIsEditOpen: any }) {
  const [open, setOpen] = useState(false)
  const [SaveTax, { data: taxD, isSuccess }] = useSaveTaxMutation()

  const form = useForm<z.infer<typeof TaxFormSchema>>({
    resolver: zodResolver(TaxFormSchema),
    defaultValues: taxDet
  })

  useEffect(() => {
    if (isEditopen) {
      setOpen(true)
    }
  }, [isEditopen])

  useEffect(() => {
    if (isSuccess && taxD?.success) {
        toast.success("Edit Tax  Successfully")
        setOpen(false);
        setIsEditOpen(false)

    }
  }, [isSuccess, setIsEditOpen, taxD?.success])

  async function onSubmit(values: z.infer<typeof TaxFormSchema>) {
    await SaveTax({ ...values,id:taxDet.id })
  }

  const handleClose = () => {
    setOpen(false);
    setIsEditOpen(false)

  };

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true)
      } else {
        handleClose()
      }
    }}>
     {/*  <DialogTrigger asChild>
        <Button size={"sm"} className='bg-gradient-to-r from-green-500 to-green-600 py-1'>Add Tax</Button>
      </DialogTrigger> */}
      <DialogContent className="sm:max-w-[500px] overflow-y-auto max-h-screen">
        <DialogHeader>
          <DialogTitle>Edit Tax</DialogTitle>

        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="md:grid-cols-2 grid-cols-1 gap-4 py-2 grid">
            <FormField
              control={form.control}
              name="taxName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tax Name</FormLabel>
                  <FormControl>
                    <Input placeholder="GST 5%" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="taxPercent"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tax Percent</FormLabel>
                  <FormControl>
                    <Input placeholder="10.2" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="igstPercent"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>IGST Percent</FormLabel>
                  <FormControl>
                    <Input placeholder="10.2" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="cgstPercent"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>CGST Percent</FormLabel>
                  <FormControl>
                    <Input placeholder="5.1" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="sgstPercent"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>SGST Percent</FormLabel>
                  <FormControl>
                    <Input placeholder="5.1" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className='flex md:gap-10 gap-4 my-3 md:col-span-2 col-span-1 items-end'>
              <Button type="button" onClick={handleClose}>Cancel</Button>
              <Button type="submit" className="headermenu">Save Tax</Button>
            </div>
          </form>
        </Form>


      </DialogContent>
    </Dialog>
  )
}
export function DeleteTax({ taxD, isDeleteopen, setIsDeleteOpen }: {  taxD: TaxDetailsT, isDeleteopen: boolean, setIsDeleteOpen: any }) {
  const [open, setOpen] = useState(false)
  const [deleteTax,{data,isSuccess}] = useDeleteTaxMutation()

  useEffect(() => {
    if (isDeleteopen) {
      setOpen(true)
    }
  }, [isDeleteopen])

  useEffect(() => {
    if (data?.success&&isSuccess) {
    toast.success("Tax Deleted SuccessFully")
    setOpen(false)
    setIsDeleteOpen(false)
    }
  }, [data?.success, isSuccess, setIsDeleteOpen])


  const handleClose = () => {
    setOpen(false)
    setIsDeleteOpen(false)
  }


  const handleDelete = async () => {
    await deleteTax(taxD.id)
  }

  return (<>
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <AlertDialogTrigger asChild>
        {/*  <Button size={"sm"}
      className="flex bg-red-500 data-[state=open]:bg-muted"
    >
      Delete Bill
    </Button> */}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete Tax from servers.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleDelete}>Delete</Button>
          {/*  <AlertDialogAction onClick={handleDelete}>Delete</AlertDialogAction> */}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  </>)
}





