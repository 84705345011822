import { useParams } from "react-router-dom"
import UploadImages from "../../components/shared/admin/components/UploadImages"


const UploadImagesPage = () => {
    const {id}=useParams()
    const {catId}=useParams()
    return (
        <div className=" xl:min-w-[1000px] border-2 rounded-md border-gray-300 md:p-4 md:m-2">
        <h5 className="h5-bold mb-4">Upload More Images for Product</h5>
         <UploadImages id={id!} catId={catId!}/>
      </div>
      )
}

export default UploadImagesPage