import { apiSlice } from "../../../redux/apiSlice";


export const AcategoryApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        saveNewCategoryA: builder.mutation({
            query: initialProdData => ({
                url: '/admin/saveCategory',
                method: 'POST',
                body: { ...initialProdData }
            }),
            invalidatesTags: [
                { type: 'Category', id: "LIST" }
            ]
        }),
        saveColorA: builder.mutation({
            query: initialColorData => ({
                url: '/admin/saveColor',
                method: 'POST',
                body: { ...initialColorData }
            }),
            invalidatesTags: [
                { type: 'Color', id: "LIST" }
            ]
        }),
        saveNewSubCategoryA: builder.mutation({
            query: initialProdData => ({
                url: '/admin/saveSubcategory',
                method: 'POST',
                body: { ...initialProdData }
            }),
            invalidatesTags: [
                { type: 'SubCategory', id: "LIST" }
            ]
        }),
        saveGiftVoucher: builder.mutation({
            query: (voucherData) => ({
                url: "/admin/saveGiftVoucher",
                method: "POST",
                body: { ...voucherData }
            }),
            invalidatesTags: [
                { type: 'GVou', id: "LIST" }
            ]
        }),
        getAllTax: builder.query({
            query: () => ({ url: "/admin/getAllTax" }),
            providesTags: (result, error, arg) => {
              if (result?.ids) {
                return [
                  { type: "Tax", id: "LIST" },
                  ...result.ids.map((id: any) => ({ type: "Tax", id })),
                ];
              } else return [{ type: "Tax", id: "LIST" }];
            },
          }),
        getAllGiftVoucherByBranch: builder.mutation({
            query: (voucherData) => ({
                url: "/customer/getAllGiftVoucherByBranch",
                method: "POST",
                body: { ...voucherData }
            }),
            invalidatesTags: [
                { type: 'GVou', id: "LIST" }
            ]
        }),
        saveHSN:builder.mutation({
            query:(employeeData)=>({
              url:"admin/saveHSN",
              method:"POST",
              body:{...employeeData}
            }),
            invalidatesTags: [
              { type: 'HSNCode', id: "LIST" }
          ]}),
          getAllHSNCode:builder.mutation({
            query:(hsnData)=>({
              url:"admin/getAllHSNCode",
              method:"POST",
              body:{...hsnData}
            }),
            invalidatesTags: [
              { type: 'HSNCode', id: "LIST" }
          ]}),
          saveTax:builder.mutation({
            query:(employeeData)=>({
              url:"admin/saveTax",
              method:"POST",
              body:{...employeeData}
            }),
            invalidatesTags: [
              { type: 'Tax', id: "LIST" }
          ]}),
        deleteGiftVoucher: builder.mutation({
            query: (id) => ({
                url: `/admin/deleteGiftVoucher/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'GVou', id: arg.id }
            ]
        }),
        deleteCategoryA: builder.mutation({
            query: (id) => ({
                url: `/admin/deleteCategory/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Category', id: arg.id }
            ]
        }),
        deleteSubCategoryA: builder.mutation({
            query: (id) => ({
                url: `/admin/deleteSubcategory/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'SubCategory', id: arg.id }
            ]
        }),
        deleteColorA: builder.mutation({
            query: (id) => ({
                url: `/admin/deleteColor/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Color', id: arg.id }
            ]
        }),
        deleteHSN: builder.mutation({
            query: (id) => ({
                url: `/admin/deleteHSN/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'HSNCode', id: arg.id }
            ]
        }),
        deleteTax: builder.mutation({
            query: (id) => ({
                url: `/admin/deleteTax/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Tax', id: arg.id }
            ]
        }),
    }),
});


export const {
    useSaveNewCategoryAMutation,useSaveHSNMutation,
    useDeleteCategoryAMutation,useDeleteHSNMutation,useDeleteTaxMutation,
    useSaveNewSubCategoryAMutation,useGetAllHSNCodeMutation,useSaveTaxMutation,
    useDeleteSubCategoryAMutation,useGetAllTaxQuery,
    useSaveColorAMutation, useGetAllGiftVoucherByBranchMutation,
    useDeleteColorAMutation, useDeleteGiftVoucherMutation, useSaveGiftVoucherMutation
} = AcategoryApiSlice

