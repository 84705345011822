import { useGetAdminDashboardDataQuery } from "../../redux/slice/admin/ADashboardApiSlice";
import { Card } from "../../components/ui/card";
import { CatOrders, CatProducts, CustomersChart, MonthSales } from "../../components/shared/admin/components/Charts/MonthSales";

export default function HomeAdmin() {
  const { data: getDashboardData } = useGetAdminDashboardDataQuery('dashboardsList', { pollingInterval: 6000, refetchOnFocus: true, refetchOnMountOrArgChange: true })
  const result = getDashboardData?.result
  const data = [
    {
      id: 1,
      title: "Total products",
      name: "totalProducts",
      count: 5,
      image:
        "/assets/dashboard/products.png",
    },
    {
      id: 2,
      title: "Total coustmers",
      name: "totalCustomers",
      count: 5,
      image:
        "/assets/dashboard/customers.png",
    },
    {
      id: 3,
      title: "Total Orders",
      name: "totalOrders",
      image:
        "/assets/dashboard/totalOrdersIcon.png",
    },
    {
      id: 4,
      title: "Active Orders",
      name: "activeOrders",
      image:
        "/assets/dashboard/activeOrders.png",
    },
    {
      id: 5,
      title: "Cancelled Orders",
      name: "cancelledOrders",
      image:
        "/assets/dashboard/cancelledOrders.png",
    },
    {
      id: 6,
      title: "Delivered Orders",
      name: "deliveredOrders",
      image:
        "/assets/dashboard/deliveredOrders.png",
    },
  ];
  const chartDataOrders = [{ count: result?.activeOrders, name: "Active Orders" },
  { count: result?.cancelledOrders, name: "Cancelled Orders" },
  { count: result?.deliveredOrders, name: "Delivered Orders" }]


  return (
    <div className="md:p-2">
      <div>
        <h5 className='h5-bold'>Dashboard</h5>
        <span className="text-gray-500 ">Welcome to Dashboard.</span>
      </div>
      <div className="my-6 py-4 shadow-lg border-2 flex-col flex gap-8 border-gray-200 bg-gray-200 rounded-md">
        <div className="mx-2 p-4 flex flex-wrap gap-6 justify-between">
          {data?.map((x) => {
            return (
              <Card key={x.id} className="w-[320px] h-[130px] shadow-lg p-3 bg-purple-50">
                <div className="flex justify-between">
                  <p className="text-orange-600 font-medium ">{x.title}</p>
                  <img src={x?.image} alt="img" width={70} height={70} />
                </div>
                <div className="text-green-600 font-semibold text-4xl">
                  {result && result[x.name!]}
                </div>
              </Card>
            );
          })}
        </div>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-2 ">
      <CatProducts />
      <CatOrders orderData={chartDataOrders} />
      </div>
      <div className="grid grid-cols-1 gap-2 my-2">
          <MonthSales />
      </div>
      <div className="grid  grid-cols-1 gap-2 ">
          <CustomersChart />
      </div>
    </div>
  );
}
