import React, { Dispatch, useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from '../../components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog"
import { Input } from "../../components/ui/input"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form"
import { toast } from 'react-toastify'
import { useDeleteGiftVoucherMutation, useSaveGiftVoucherMutation,useGetAllGiftVoucherByBranchMutation } from '../../redux/slice/admin/ACategoryApiSlice'
import { DataTable } from '../../components/shared/admin/components/Table/data-table'
import { GiftVoucherColumns } from '../../components/shared/admin/components/VouchersTable/GiftVoucherColumns'

const GiftVouchers = () => {
  const [getAllGiftVoucherByBranch, { data: allVouchers, isLoading, isSuccess, isError, }] = useGetAllGiftVoucherByBranchMutation()
  const [refetch, setReftch] = useState(false)
  const [deleteGiftVoucher] = useDeleteGiftVoucherMutation()


  useEffect(() => {
      getAllGiftVoucherByBranch({ branchId:1 })
  }, [getAllGiftVoucherByBranch, refetch]);

  const onDelete = useCallback(async (id: number) => {
    await deleteGiftVoucher(id)
    setReftch(p => !p)
  }, [deleteGiftVoucher]);

  const onEdit = useCallback(() => {
    setReftch(p => !p)
  }, []);

  const columns = useMemo(() => GiftVoucherColumns({ onEdit, onDelete }), [onDelete, onEdit]);
let content;

if (isLoading) {
  content = (
    <div className="flex justify-center items-center h-full mt-48">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
    </div>
  );
}

if (isError) content = <p>{allVouchers?.result?.message}</p>

if (isSuccess){
  content=  <section >
  <main className=" border-2 rounded-md border-gray-500 md:m-2">
     <div className="flex justify-between p-4">
      <h5 className='h5-bold'>Vouchers</h5>
      <AddGiftVouchers setReftch={setReftch}/>
    </div>
    {allVouchers?.result.length>0?<div className=" px-2">
      {allVouchers?.result?.length>0 &&    <DataTable data={allVouchers?.result} columns={columns}  />}
    </div>:<p className=" p-4 rounded-md border-2  md:overflow-hidden shadow-lg">
      No Vouchers Available 
    </p>}
  </main>
  </section>
}

return <>{content}</>;

}

const formSchema = z.object({
  voucherCode: z.string().min(2, {
    message: "Voucher code must be at least 2 characters.",
  }),
  voucherAmount: z.string().regex(/^\d+(\.\d{1,2})?$/, {
    message: "Voucher amount must be a valid decimal number.",
  }),
  quantity: z.number().int().nonnegative({
    message: "Quantity must be a non-negative integer.",
  }),
});
export function AddGiftVouchers({setReftch}:{setReftch:Dispatch<React.SetStateAction<boolean>>}) {
  const [open, setOpen] = useState(false)
  const [saveGiftVoucher, { data: giftVou,isSuccess }] = useSaveGiftVoucherMutation()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      voucherCode: "",
      voucherAmount: "",
      quantity: 10
    },
  })

  useEffect(()=>{
    if(isSuccess&&giftVou.success){
      toast.success("New Gift Voucher Added Successfully")
      setReftch(p=>!p)
    }/* else if(!isSuccess || !giftVou.success){
      toast.error("Failed To Add New Gift Voucher")
    } */
  },[giftVou, isSuccess, setReftch])

  async function onSubmit(values: z.infer<typeof formSchema>) {
    await saveGiftVoucher({ ...values,branchId:1 })
    handleClose()
  }

  const { reset } = form;
  const handleClose = () => {
    setOpen(false);
    reset()
  };

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true)
      } else {
        handleClose()
      }
    }}>
      <DialogTrigger asChild>
        <Button size={"sm"} className='bg-gradient-to-r from-green-500 to-green-600 py-1'>Add Gift Voucher</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px] overflow-y-auto max-h-screen">
        <DialogHeader>
          <DialogTitle>Add Gift Voucher</DialogTitle>

        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} >
            <div className="md:grid-cols-2 grid-cols-1 gap-4 py-2 grid">

            <FormField
              control={form.control}
              name="voucherCode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Voucher Code</FormLabel>
                  <FormControl>
                    <Input placeholder="voucherCode" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="voucherAmount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Voucher Amount</FormLabel>
                  <FormControl>
                    <Input placeholder="voucherAmount" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="quantity"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Quntity</FormLabel>
                  <FormControl>
                    <Input placeholder="quantity" {...field} onChange={e => field.onChange(+e.target.value)} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            </div>
            <div className='flex md:gap-10 gap-4 my-3 justify-end'>
            <Button type="button" onClick={handleClose}>Cancel</Button>
              <Button type="submit" className="headermenu">Add Voucher</Button>
            </div>

          </form>
        </Form>


      </DialogContent>
    </Dialog>
  )
}

export default GiftVouchers