import { apiSlice } from "../apiSlice";


export const ACustomerAddressApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        saveCustomerAddress: builder.mutation({
            query: initialAddress => ({
                url: '/customer/saveCustomerAddress',
                method: 'POST',
                body: {
                    ...initialAddress,
                }
            }),
            invalidatesTags: [
                { type: 'Address', id: "LIST" }
            ]
        }),
        deleteCustomerAddress: builder.mutation({
            query: ( id ) => ({
                url: `/customer/deleteCustomerAddress/${id}`,
                method: 'DELETE',
                }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Address', id: arg.id }
            ]
        }),
        getStateDDL: builder.query({
            query: () =>({url:`/common/getStateDDL`}),
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                  return [
                    { type: 'State', id: 'LIST' },
                    ...result.ids.map((id:any) => ({ type: 'State', id })),
                  ];
                } else return [{ type: 'State', id: 'LIST' }];
              },
        }),
        getGSTbyHSN: builder.mutation({
            query: ( hsnCode ) => ({
                url: `/common/getGSTbyHSN`,
                method: 'POST',
                body: { ...hsnCode }}),
        }),
        getRandomNumber: builder.mutation({
            query: ( ) => ({
                url: `/common/getRandomNumber`,
                method: 'POST',}),
        }),
    }),
})

export const {
    useSaveCustomerAddressMutation,useGetGSTbyHSNMutation,
    useDeleteCustomerAddressMutation,useGetStateDDLQuery,useGetRandomNumberMutation
} = ACustomerAddressApiSlice

