import { SidebarProvider, SidebarTrigger } from "../../components/ui/sidebar";
import HeaderLayout from "../../components/shared/admin/layout/HeaderLayout";
import { Outlet } from "react-router-dom";
import { AppSidebar } from "../../components/app-sidebar";
import { WEBSITENAME } from "../../constants/appConfig";

// const DashboardLayout = () => {
//   return (
//     <div className="flex flex-col md:flex-row">
//       <div className="md:w-56  md:overflow-hidden">
//         <div className="md:overflow-hidden md:w-56 md:fixed md:h-screen">
//           <HeaderLayout />
//         </div>
//       </div>
//       <div className="w-full">
//         <Outlet />
//         </div>
//     </div>
//   );
// };
const DashboardLayout = () => {
  return (
    <SidebarProvider>
      <AppSidebar />
      <main>
        <div className="flex items-center gap-40">
        <SidebarTrigger />
        <h1 className="  font-semibold ">{WEBSITENAME}</h1>
        </div>
  
        <Outlet />
      </main>
    </SidebarProvider>
  );
};

export default DashboardLayout;

