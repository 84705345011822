import {
    ChevronLeftIcon,
    ChevronRightIcon,
    DoubleArrowLeftIcon,
    DoubleArrowRightIcon,
  } from "@radix-ui/react-icons"
  import { Table } from "@tanstack/react-table"
  
  import { Button } from "../../../../ui/button"
  import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "../../../../ui/select"
  
  interface DataTablePaginationProps<TData> {
    table: Table<TData>,
    pageSize:number,
    pageNumber:number,
    dataCount:number,
    setPageSize:React.Dispatch<React.SetStateAction<number>>,
    setPageNumber:React.Dispatch<React.SetStateAction<number>>
  }
  
  export function DataTablePaginationCount<TData>({
    table,pageSize,pageNumber,setPageSize,setPageNumber,
    dataCount
  }: DataTablePaginationProps<TData>) {
    const lastPage = Math.ceil(dataCount! / pageSize!);
    return (
      <div className="flex items-center justify-between  px-2">
        <div className="flex  flex-wrap items-center justify-between md:gap-10 w-full">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-medium">Rows per page</p>
            <Select
              value={`${pageSize}`}
              onValueChange={(value) => {
                setPageSize(Number(value))
              }}
            >
              <SelectTrigger className="h-8 w-[70px] bg-indigo-400">
                <SelectValue placeholder={table.getState().pagination.pageSize} />
              </SelectTrigger>
              <SelectContent side="top">
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <SelectItem key={pageSize} value={`${pageSize}`}>
                    {pageSize}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            Page {pageNumber} of{" "}
            {lastPage}
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => setPageNumber(1)}
              disabled={pageNumber<=1}
            >
              <span className="sr-only">Go to first page</span>
              <DoubleArrowLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => setPageNumber((pre:number)=>pre-1)}
              disabled={pageNumber<=1}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => setPageNumber((pre:number)=>pre+1)}
              disabled={pageNumber===lastPage}
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => setPageNumber(lastPage)}
              disabled={pageNumber===lastPage}
            >
              <span className="sr-only">Go to last page</span>
              <DoubleArrowRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    )
  }
  