import { useDeleteCategoryAMutation, useDeleteSubCategoryAMutation, useSaveNewCategoryAMutation, useSaveNewSubCategoryAMutation } from "../../redux/slice/admin/ACategoryApiSlice";
import { Button } from "../../components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../components/ui/dialog"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "../../components/ui/alert-dialog"
import { Input } from "../../components/ui/input"
import { Label } from "../../components/ui/label"
import { ChangeEvent, startTransition, useEffect, useState } from "react";
import { useGetAllCategoryQuery, useGetSubcategoryQuery } from "../../redux/slice/CategoryApiSlice";
import { MdDelete, MdEdit } from "react-icons/md";

export function AddCat({ type,catId,refetch }: { type: string,catId?:Number,refetch:any }) {
    const [addNewCategory] = useSaveNewCategoryAMutation();
    const [addNewSubCategory] = useSaveNewSubCategoryAMutation();
    const [newCategory, setNewCategory] = useState<string>("")
    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(false)
    }
    const handleCategory = async() => {
        if (type === "Category") {
           await addNewCategory({ categoryName: newCategory.toUpperCase() });
        } else {
            await addNewSubCategory({ subcategoryName: newCategory.toUpperCase(), categoryId: catId  });
        }
        setNewCategory("")
        refetch()
        setOpen(false)
    };
    return (
        <Dialog open={open} onOpenChange={() => {
            if (!open) {
                setOpen(true);
            } else {
                handleClose();
            }
        }}>
            <DialogTrigger asChild>
                <Button variant="default">{type === "Category" ? "Add New Category" : "Add New Sub Category"}</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>{type === "Category" ? "Add Category" : "Add Sub Category"}</DialogTitle>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                            {type === "Category" ? "Category" : "Sub Category"}
                        </Label>
                        <Input
                            id="name"
                            value={newCategory}
                            placeholder="category name"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setNewCategory(e.target.value)}
                            className="col-span-3 input-field"
                        />
                    </div>
                </div>
                <DialogFooter>
                    <Button type="submit" onClick={handleCategory}>Save changes</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}


const CateoriesPageAdmin = () => {
    const { data: categories, refetch } = useGetAllCategoryQuery("categoriesList", { pollingInterval: 6000, refetchOnFocus: true, refetchOnMountOrArgChange: true, });
    const [catId,setCatId]=useState<Number>(1)
    return (
        <div className="border-2 rounded-md border-gray-300  md:p-4 md:m-2">
            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-20 xl:gap-32 gap-10 md:justify-between ">
                <div>
                    <div className="flex gap-12 items-center">
                        <p className="text-xl font-semibold">Categories</p>
                        <AddCat type="Category" refetch={refetch}/>
                    </div>
                    <div className="my-10 flex flex-col gap-10">
                        {categories?.result?.length > 0 &&
                            categories?.result?.map((category: any) => <div key={category.id} className="flex justify-between">
                                <Button onClick={()=>setCatId(category.id)} variant={category.id===catId?"default":"outline"} className={`${category.id===catId && "headermenu"}`} >{category.categoryName}</Button>
                                <div className="flex gap-4">
                                    <EditCat type="Category" category={category} refetch={refetch} />
                                    <DeleteCat  type="Category" catId={category.id} />
                                </div>
                            </div>)}
                    </div>
                </div>
                <Subcategories catId={catId} />
            </div>
        </div>
    )
}


const Subcategories = ({ catId }: { catId: Number }) => {
    const { data: subCategories, refetch } = useGetSubcategoryQuery(catId);

    return <div>
        <div className="flex gap-12 items-center">
            <p className="text-xl font-semibold">SubCategories</p>
            <AddCat type="SubCategory" catId={catId} refetch={refetch}/>
        </div>
        <div className="my-4 flex flex-col gap-4">
            {subCategories?.result?.length > 0 &&
                subCategories?.result?.map((subcategory: any) => <div key={subcategory.id} className="flex justify-between">
                    <p>{subcategory.subcategoryName}</p>
                    <div className="flex gap-4">
                        <EditCat type="SubCategory" category={subcategory} refetch={refetch} />
                        <DeleteCat type="SubCategory" catId={subcategory.id} />
                    </div>
                </div>)}
        </div>
    </div>
}

type editCateProps = {
    type: string,
    category: {
        id: Number,
        categoryId?: Number,
        categoryName?: string,
        subcategoryName?: string
    },
    refetch: any
}
export function EditCat({ type, category, refetch }: editCateProps) {
    const [editNewCategory] = useSaveNewCategoryAMutation();
    const [editNewSubCategory] = useSaveNewSubCategoryAMutation();
    const [newCategory, setNewCategory] = useState<string>("")
    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(false)
    }
    
    useEffect(()=>{
        if(type==="SubCategory"&&category?.subcategoryName){
            setNewCategory(category?.subcategoryName)
        }else if(category?.categoryName){
            setNewCategory(category?.categoryName)
        }
    },[category,type])
    

    const handleCategory = async () => {
          if (type === "Category") {
            await editNewCategory({ categoryName: newCategory.toUpperCase(),id:category.id });
          } else {
            await editNewSubCategory({ subcategoryName: newCategory.toUpperCase(), categoryId: category.categoryId,id:category.id });
          }
        refetch()
        setOpen(false)
    };
    return (
        <Dialog open={open} onOpenChange={() => {
            if (!open) {
                setOpen(true);
            } else {
                handleClose();
            }
        }}>
            <DialogTrigger asChild>
                <Button variant="outline" size="icon"><MdEdit size={20} /></Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>{type === "Category" ? "Edit Category" : "Edit Sub Category"}</DialogTitle>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                            {type === "Category" ? "Category" : "Sub Category"}
                        </Label>
                        <Input
                            id="name"
                            defaultValue={type === "Category" ? category.categoryName : category.subcategoryName}
                            placeholder="category name"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setNewCategory(e.target.value)}
                            className="col-span-3 input-field"
                        />
                    </div>
                </div>
                <DialogFooter>
                    <Button type="button" onClick={handleCategory}>Save changes</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}




export function DeleteCat({catId,type}:any) {
    const [deleteSubcategory/* ,{isSuccess:subCSuccess,data:subCData} */]=useDeleteSubCategoryAMutation()
    const [deleteCategory/* ,{isSuccess,data} */] = useDeleteCategoryAMutation();

    const handleRemoveCat =  () => {
        if (type === "Category") {
         deleteCategory(catId);
        } else {
          deleteSubcategory(catId);
        }
      };
    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <Button variant="outline"><MdDelete size={20} color="red" /></Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                        This action cannot be undone. This will permanently delete Category.
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                onClick={() => startTransition(handleRemoveCat)}
              >
                Delete
              </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default CateoriesPageAdmin
