"use client"
import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { Row } from "@tanstack/react-table"
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../ui/alert-dialog"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "../../../../ui/dropdown-menu"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../ui/form"
import { Button } from "../../../../ui/button"
import {  MdDeleteOutline, MdEdit } from "react-icons/md"
import { useEffect, useState } from "react"
import {  ShippingCostT } from "@/react-app-env"
import { toast } from "react-toastify"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog"
import { Input } from "../../../../ui/input"
import { SCFormSchema } from "../../../../../constants"
import { SCArea, SCCountrySelect } from "../Dropdown"
import { useDeleteShippingCostMutation, useSaveShippingCostMutation } from "../../../../../redux/slice/admin/ACreateOrderApiSlice"

interface DataTableRowActionsProps<TData> {
  row: Row<ShippingCostT>,
  refetch:any
}

export function DataTableRowActionsTax<TData>({
  row,refetch
}: DataTableRowActionsProps<TData>) {
  const SCD = row.original;
  const [isDeleteopen, setIsDeleteOpen] = useState<boolean>(false)
  const [isEditopen, setIsEditOpen] = useState<boolean>(false)

  return (<>
    {isDeleteopen && <DeleteTax SCDet={SCD} setIsDeleteOpen={setIsDeleteOpen} isDeleteopen={isDeleteopen} />}
    {isEditopen && <EditTax SCDet={SCD} setIsEditOpen={setIsEditOpen} isEditopen={isEditopen} refetch={refetch}/>}
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={()=>setIsEditOpen(true)}>
          Edit Shipping Cost<DropdownMenuShortcut><MdEdit size={20} /></DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => setIsDeleteOpen(true)}>
          Delete
          <DropdownMenuShortcut><MdDeleteOutline color="red" size={20} /></DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  </>)
}


 export function EditTax({ SCDet, isEditopen, setIsEditOpen ,refetch}: { refetch:any, SCDet: ShippingCostT, isEditopen: boolean, setIsEditOpen: any }) {
  const [open, setOpen] = useState(false)
  const [SaveSc, { data: SCD, isSuccess }] = useSaveShippingCostMutation()

  const form = useForm<z.infer<typeof SCFormSchema>>({
    resolver: zodResolver(SCFormSchema),
    defaultValues:SCDet
  })
  const {watch}=form
  const country = watch("country")

  useEffect(() => {
    if (isEditopen) {
      setOpen(true)
    }
  }, [isEditopen])

  useEffect(() => {
    if (isSuccess && SCD?.success) {
        toast.success(" Shipping Cost Edited Successfully")
        setOpen(false);
        setIsEditOpen(false)
        refetch()
    }
  }, [isSuccess, setIsEditOpen, SCD?.success, refetch])

  async function onSubmit(values: z.infer<typeof SCFormSchema>) {
    await SaveSc({ ...values,id:SCDet.id })
  }

  const handleClose = () => {
    setOpen(false);
    setIsEditOpen(false)

  };

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true)
      } else {
        handleClose()
      }
    }}>
      {/* <DialogTrigger asChild>
        <Button size={"sm"} className='bg-gradient-to-r from-green-500 to-green-600 py-1'>Edit Shipping Cost</Button>
      </DialogTrigger> */}
      <DialogContent className="sm:max-w-[500px] overflow-y-auto max-h-screen">
        <DialogHeader>
          <DialogTitle>Edit Shipping Cost</DialogTitle>

        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="md:grid-cols-2 grid-cols-1 gap-4 py-2 grid">
          <FormField
                control={form.control}
                name="country"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Country</FormLabel>
                    <FormControl>
                      <SCCountrySelect value={field.value} onChange={field.onChange} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            <FormField
              control={form.control}
              name="area"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Area</FormLabel>
                  <FormControl>
                {country==="India" ? <SCArea value={field.value} onChange={field.onChange} />:
                    <Input  {...field} className='input-field' placeholder='any' />}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Shipping Amount</FormLabel>
                  <FormControl>
                    <Input placeholder="10.2" {...field} onChange={(e)=>field.onChange(+e.target.value)} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <div className='flex md:gap-10 gap-4 my-3 md:col-span-2 col-span-1 items-end'>
              <Button type="button" onClick={handleClose}>Cancel</Button>
              <Button type="submit" className="headermenu">Save Shipping Cost</Button>
            </div>
          </form>
        </Form>


      </DialogContent>
    </Dialog>
  )
} 
export function DeleteTax({ SCDet, isDeleteopen, setIsDeleteOpen }: {  SCDet: ShippingCostT, isDeleteopen: boolean, setIsDeleteOpen: any }) {
  const [open, setOpen] = useState(false)
  const [deleteSC,{data,isSuccess}] = useDeleteShippingCostMutation()

  useEffect(() => {
    if (isDeleteopen) {
      setOpen(true)
    }
  }, [isDeleteopen])

  useEffect(() => {
    if (data?.success&&isSuccess) {
    toast.success("Tax Deleted SuccessFully")
    setOpen(false)
    setIsDeleteOpen(false)
    }
  }, [data?.success, isSuccess, setIsDeleteOpen])


  const handleClose = () => {
    setOpen(false)
    setIsDeleteOpen(false)
  }


  const handleDelete = async () => {
    await deleteSC(SCDet.id)
  }

  return (<>
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <AlertDialogTrigger asChild>
        {/*  <Button size={"sm"}
      className="flex bg-red-500 data-[state=open]:bg-muted"
    >
      Delete Bill
    </Button> */}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete Tax from servers.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleDelete}>Delete</Button>
          {/*  <AlertDialogAction onClick={handleDelete}>Delete</AlertDialogAction> */}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  </>)
}





