import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog"
import { FaPlus } from "react-icons/fa6";
import { Button } from "../../../components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form"
import { Input } from "../../../components/ui/input"
import { useDeleteCustomerAddressMutation, useGetStateDDLQuery, useSaveCustomerAddressMutation } from "../../../redux/slice/ACustomerAddressApiSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CountrySelect, StatesSelect } from "../admin/components/Dropdown"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../../components/ui/tooltip";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { formatToCurrency } from "../../../constants";
import { useGetGiftVoucherByCodeMutation } from "../../../redux/slice/CartApiSlice";
import { IoSearch } from "react-icons/io5";
import { Separator } from "../../../components/ui/separator";

const formSchema = z.object({
  fullName: z.string().min(3, {
    message: 'FullName must be at least 3 characters.',
  }),
  hno: z.string().min(3, {
    message: 'House no must be at least 3 characters.',
  }),
  street: z.string().min(3, {
    message: 'strre must be at least 3 characters.',
  }),
  city: z.string().min(3, {
    message: 'city must be at least 3 characters.',
  }),
  pincode: z.string().min(3, {
    message: 'Pincode must be at least 3 characters.',
  }),
  state: z.string().min(3, {
    message: 'State must be at least 3 characters.',
  }),
  country: z.string().min(3, {
    message: 'Country must be at least 3 characters.',
  }),
  mobile: z.string().refine((value) => /^\d{10}$/.test(value), {
    message: 'Mobile number must be exactly 10 digits.',
  }),
})

const initialValuesNew = {
  fullName: '',
  mobile: '',
  hno: '',
  street: '',
  city: '',
  pincode: '',
  state: '',
  country: 'India',
}

function CustomerInfo({ customerId, address, type, id, reftechUser }: { customerId: number, address?: any, type: string, id?: number, reftechUser: any }) {
  const navigate = useNavigate()
  const [deleteAddress, { data: delAddData }] = useDeleteCustomerAddressMutation()
  const [saveCustomerAddress, { data }] = useSaveCustomerAddressMutation()
  const { data: states } = useGetStateDDLQuery("StatesList")
  const [open, setOpen] = useState(false)
  const initialValues = type === "Create" ? initialValuesNew : JSON.parse(address)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues
  });
  const { reset, watch, setValue } = form;
  const handleClose = () => {
    setOpen(false)
    reset()
  }
  const country = watch("country")

  useEffect(() => {
    if (country) {
      setValue("state", "")
    }
  }, [country, setValue])
  
  useEffect(() => {
    if (data?.success || delAddData?.success) {
      setOpen(false)
      reftechUser()
    }
  }, [data, navigate, reftechUser, delAddData])

  const handleDeleteAddress = async () => {
    await deleteAddress(id)
  }

  async function onSubmit(values: z.infer<typeof formSchema>) {

    const addressDetails = {
      hno: values.hno,
      street: values.street,
      city: values.city,
      pincode: values.pincode,
      state: values.state,
      country: values.country,
      mobile: values.mobile,
      fullName: values.fullName
    };
    const filState = states?.result?.find((state: any) => state.state === values.state)

    if (type === "Edit") {
      await saveCustomerAddress({ address: JSON.stringify(addressDetails), id, customerId, isPrimary: 0, stateId: filState?.id ? filState?.id : 0, state: values.state, country: values.country });
    } else {
      await saveCustomerAddress({ address: JSON.stringify(addressDetails), customerId, stateId: filState?.id ? filState?.id : 0, state: values.state, country: values.country });
    }
  }

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <DialogTrigger className={`underline text-red-800 text-md font-semibold flex items-center gap-1`}>
        {type === "Create" && <FaPlus />}{type === "Create" ? "Add New Address" : "Edit Address"}
      </DialogTrigger>
      <DialogContent className="overflow-auto max-h-screen">
        <DialogTitle className="space-y-1">
        <p className="text-md font-semibold">{type === "Create" ? "Enter a new delivery address" : "Edit previous Address"}</p>
        <p className="text-red-400 text-sm font-semibold">All fields are required*</p>
        </DialogTitle>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="fullName"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>FullName</FormLabel>
                  <FormControl>
                    <Input className="input-field" placeholder="FullName" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="mobile"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Mobile</FormLabel>
                  <FormControl>
                    <Input className="input-field" placeholder="Mobile no" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex md:flex-row flex-col gap-2">
              <FormField
                control={form.control}
                name="hno"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>House No</FormLabel>
                    <FormControl>
                      <Input className="input-field" placeholder="House no" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="street"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Street</FormLabel>
                    <FormControl>
                      <Input className="input-field" placeholder="Street" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex md:flex-row flex-col gap-2">
              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>City</FormLabel>
                    <FormControl>
                      <Input className="input-field" placeholder="City" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="pincode"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Pincode</FormLabel>
                    <FormControl>
                      <Input className="input-field" placeholder="Pincode" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex md:flex-row flex-col gap-2">
              <FormField
                control={form.control}
                name="country"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Country</FormLabel>
                    <FormControl>
                      <CountrySelect value={field.value} onChange={field.onChange} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="state"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>State</FormLabel>
                    <FormControl>
                      {
                        country === "India" ? <StatesSelect value={field.value} onChange={field.onChange} /> :
                          <Input className="input-field" placeholder="State" {...field} />
                      }
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              /></div>
            <div className="flex justify-between">
            {type === "Edit" ? <Button type="button" onClick={handleDeleteAddress} >Delete Address</Button> : <Button type="button" onClick={handleClose} >Cancel</Button>}
              <Button type="submit" className="headermenu">{type === "Edit" ? "Edit Address" : "Add New Address"}</Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}


export const PromotionDetails = ({ setGiftVou, giftVou }: any) => {
  const [isAddVoucher, setIsAddVoucher] = useState(false)

  return <div className="max-w-56 border-2 shadow-md rounded-lg text-[12px] p-2">
    <div className="flex items-center justify-between gap-2">
      <p className="text-sm font-medium">Coupon code:</p>
      {giftVou?.id > 0 ? <TooltipProvider delayDuration={100}>
        <Tooltip >
          <TooltipTrigger asChild>
            <Button variant="ghost" onClick={() => setGiftVou({ id: 0, voucherCode: "", amount: 0 })}><MdDelete color="red" size={20} /></Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Delete Coupon</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider> : <TooltipProvider delayDuration={100}>
        <Tooltip >
          <TooltipTrigger asChild>
            <Button variant="ghost" onClick={() => setIsAddVoucher(true)}><IoIosAddCircleOutline size={20} /></Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Add Coupon</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>}
      {isAddVoucher && <AddVoucher setGiftVou={setGiftVou} setIsAddVoucher={setIsAddVoucher} />}
    </div>
    {giftVou?.id > 0 && <div className="space-y-1 text-sm font-medium  px-2">
      <p className="line-clamp-1">{giftVou?.voucherCode}</p>
      <p><span className="text-gray-500">Amount:</span>{formatToCurrency(giftVou?.amount)}</p>
    </div>}
  </div>

}


const voucherCodeSchema = z.object({
  voucherCode: z.string().min(2, "Voucher code must be at least 2 characters."),
});

export function AddVoucher({ setIsAddVoucher, setGiftVou }: any) {
  const [open, setOpen] = useState(true);
  const [getGiftVoucherByCode, { data: voucherC, isSuccess: isVouSuccess }] = useGetGiftVoucherByCodeMutation();

  const handleClose = () => {
    setOpen(false);
    setIsAddVoucher(false);
  };


  const form = useForm({
    resolver: zodResolver(voucherCodeSchema),
    defaultValues: { voucherCode: "" },
  });

  const { handleSubmit, control } = form;


  const onSubmit = (values: any) => {
    getGiftVoucherByCode({ voucherCode: values.voucherCode })

  };

  const handleAddVoucher = (voucherId: any) => {
    setGiftVou(voucherId);
    setIsAddVoucher(false);
    setOpen(false);
  };


  return (
    <Dialog
      open={open}
      onOpenChange={() => (open ? handleClose() : setOpen(true))}
    >
      <DialogContent className="sm:max-w-[450px]">
        <DialogHeader>
          <DialogTitle>Add Coupon Code</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-2">
          <Form {...form}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex items-end gap-2 w-full"
            >
              <FormField
                control={control}
                name="voucherCode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Voucher Code*</FormLabel>
                    <FormControl className="flex gap-2">
                      <Input
                        placeholder="Voucher Code"
                        {...field} className="w-[280px] md:w-[320px]"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button size={"sm"} className="hover:bg-indigo-400 headermenu">
                <IoSearch />
              </Button>
            </form>
          </Form>
        </div>
        <Separator />
        {(isVouSuccess && voucherC?.result?.id > 0) ? (
          <div>
            <table className="table-auto w-full border">
              <thead>
                <tr className="bg-gray-100 text-sm py-2">
                  <th className="py-1">Coupon Code</th>
                  <th className="py-1">Amount</th>
                  <th className="py-1">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr >
                  <td className="border text-center text-sm py-2">
                    {voucherC?.result?.voucherCode}
                  </td>
                  <td className="border text-center text-sm py-2">
                    {voucherC?.result?.voucherAmount}
                  </td>
                  <td className="border text-center text-sm py-2">
                    <Button
                      size={"sm"}
                      onClick={() =>
                        handleAddVoucher({
                          id: voucherC?.result?.id,
                          voucherCode: voucherC?.result?.voucherCode,
                          amount: Number(voucherC?.result?.voucherAmount),
                        })
                      }
                      className="text-xs headermenu text-white hover:bg-green-600"
                    >
                      Add Coupon
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (isVouSuccess && voucherC?.result?.id === 0 && <div className="text-red-600 font-semibold">
           Coupon Not Available
        </div>)
        }
        <Button className="bg-red-500 max-w-[150px] self-end" onClick={handleClose}>
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
}





export default CustomerInfo;
