import ProductAddEdit from "../../components/shared/admin/components/ProductAddEdit"
import ProductForm from "../../components/shared/admin/components/ProductForm"

const NewProduct = () => {

      return (
        <div className="w-full rounded-md border-2 border-gray-300 md:p-4 md:m-2">
        <h5 className="h5-bold mb-4">Create Product</h5>
        <ProductAddEdit  type="Create" />
      </div>
      )

}

export default NewProduct