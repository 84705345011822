import { Button } from "../../../../components/ui/button";
import { Form, FormField, FormItem, FormLabel, FormMessage } from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import { useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAddProductImagesAMutation } from "../../../../redux/slice/admin/AImagesUploadApiSlice";
import { toast } from "react-toastify";

const formSchema = z.object({
  images: z.string(),
});

const UploadImages = ({ id,catId }: { id: string,catId:string }) => {
  const [imagesBase64, setImagesBase64] = useState<string[]>([]);
  const navigate = useNavigate();
  const[uploadImages,{isLoading}] = useAddProductImagesAMutation()
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      images: "",
    },
  });

  const onSubmit =async (values: z.infer<typeof formSchema>) => {
  await Promise.all(
      imagesBase64.map(async (item: any) => {
        const response = await uploadImages({ productId:id,image:item});
        if ('error' in response) {
          console.error('error:', response.error);
          toast.error("Images Not Uploaded");
          return;
        }
        console.log('Images are added to product', response?.data);
      })
    );
  navigate(`/admin/products/${catId}`)
  toast.success("Images Uploaded SuccessFully")
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newImagesBase64: string[] = [];
      const readAsDataURL = (file: File) => {
        return new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result as string);
          };
          reader.readAsDataURL(file);
        });
      };
      for (let i = 0; i < Math.min(5 - imagesBase64.length, files.length); i++) {
        /* const base64String = await readAsDataURL(files[i]!);
        newImagesBase64.push(base64String); */
        const file = files[i];
        if (file.size <= 8 * 1024 * 1024) { // Check if the file size is less than or equal to 5MB
          const base64String = await readAsDataURL(file);
          newImagesBase64.push(base64String);
        } else {
          toast.error(`${file.name} is larger than 5MB and will not be uploaded.`);
        }
      }
      setImagesBase64((prev) => [...prev, ...newImagesBase64]);
    }
  };
  

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <div className="flex flex-col gap-2">
          <FormField
            control={form.control}
            name="images"
            render={({ field }) => (
              <FormItem className="w-[500px]">
                <FormLabel>Choose Multiple Images for the Product (Max:5 Images)</FormLabel>
                <Input
                  type="file"
                  accept="image/*, application/pdf"
                  multiple
                  className="input-field"
                  onChange={handleFileChange}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex flex-wrap gap-4">
            {imagesBase64.length > 0 &&
              imagesBase64.map((image: any, index: number) => (
                <img key={index} src={image} alt={`uploadedimage-${index}`} width="70" height="70" />
              ))}
          </div>
        </div>

        <div className="flex md:justify-between items-center md:flex-row flex-col mt-4">
          <Button type="submit" className="button col-span-1 headermenu" disabled={imagesBase64.length<=0||isLoading}>
           {isLoading?"Submitting": "Upload Images"}
          </Button>
          <Button type="button" onClick={() => navigate(-1)} className="button col-span-1 bg-gray-500">
            Cancel
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default UploadImages;
