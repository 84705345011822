import { Card } from "../../ui/card";
import { Link } from "react-router-dom";


const Weaves = () => {
  return (
    <section className=" wrapper font-display mt-4 ">
      <div className="flex justify-center py-1">
        <h5 className="text-4xl ">Category Styles</h5>
      </div>
      <div className="xl:wrapper grid md:grid-cols-12  grid-cols-1 gap-1">
        <Link to={`/category/1`} className="md:col-span-3 ">
          <div className="overflow-hidden">
            <img src="/assets/category/eventCollCat.png"
              alt="kanchi"
              className="w-full transform hover:scale-105 transition-transform duration-300 max-md:hidden" />
            <img src="/assets/category/eventCollCatM.png"
              alt="kanchi"
              className="w-full transform hover:scale-105 transition-transform duration-300 md:hidden" />
          </div>
        </Link>
        <div className="grid grid-cols-1  md:col-span-6">
          <div className="grid gap-1 grid-cols-2 max-sm:grid-cols-1">
            <Link to={`/category/1`}>
              <div className="overflow-hidden">
                <img src="/assets/category/silkCat.jpg"
                  alt="silk"
                  className=" transform hover:scale-105 transition-transform duration-300 max-md:hidden" />
                <img src="/assets/category/silkCatM.jpg"
                  alt="silk"
                  className=" transform hover:scale-105 transition-transform duration-300 md:hidden" />
              </div>
            </Link>
            <Link to={`/category/2`}>
              <div className="overflow-hidden">
                <img src="/assets/category/banarasCat.jpg"
                  alt="banaras"
                  className=" transform hover:scale-105 transition-transform duration-300 max-md:hidden" />
                <img src="/assets/category/banarasCatM.jpg"
                  alt="banaras"
                  className=" transform hover:scale-105 transition-transform duration-300 md:hidden" />
              </div>
            </Link>
            <Link to={`/category/3`} className="max-sm:block ">
              <div className="overflow-hidden">
                <img src="/assets/category/cottonCatM.jpg"
                  alt="cotton"
                  className=" transform hover:scale-105 transition-transform duration-300 md:hidden" />
              </div>
            </Link>
            <Link to={`/category/4`} className="max-sm:block ">
              <div className="overflow-hidden">
                <img src="/assets/category/fancyCatM.jpg"
                  alt="fancy"
                  className=" transform hover:scale-105 transition-transform duration-300 md:hidden" />
              </div>
            </Link>
          </div>
          <Link to={`/category/3`}>
            <div className="overflow-hidden">
              <img src="/assets/category/cottonCat.jpg"
                alt="cotton"
                className=" transform hover:scale-105 transition-transform duration-300 max-md:hidden" />
            </div>
          </Link>
        </div>
        <Link to={`/category/4`} className="md:col-span-3">
          <div className="overflow-hidden">
            <img src="/assets/category/fancyCat.jpg"
              alt="fancy"
              className=" transform hover:scale-105 transition-transform duration-300 max-md:hidden" />

          </div>
        </Link>
      </div>


    </section>
  );
};


export default Weaves