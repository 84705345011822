"use client"

import * as React from "react"
import {
  Home,
  Settings2,
} from "lucide-react"

import { NavMain } from "../components/nav-main"
import { NavUser } from "../components/nav-user"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from "../components/ui/sidebar"
import { Link } from "react-router-dom"
import { HiOutlineDocumentDuplicate, HiOutlineUserGroup } from "react-icons/hi"
import { FaCartArrowDown } from "react-icons/fa6"



const currentUser = JSON.parse(localStorage.getItem("sakhiweb")!);
const data = {
  user: {
    name: "Admin",
    email: `${currentUser?.fullName}`,
    avatar: "/images/avatar.png",
  },
  navMain: [
    {
      title: "DashBoard",
      url: "/admin",
      icon: Home,
      isActive: true,
    
    },
    {
      title: "Orders",
      url: "#",
      icon: FaCartArrowDown,
      items: [
        {
          title: "Active Orders",
          url: "/admin/orders/active",
        },
        {
          title: "Orders By Date",
          url: "/admin/orders/date-range",
        },
        {
          title: "Cancelled Orders",
          url: "/admin/orders/cancelled",
        },
        {
          title: "Closed Orders",
          url: "/admin/orders/closed",
        },
      ],
    },
    {
      title: "Products",
      url: "#",
      icon: HiOutlineDocumentDuplicate,
      items: [
        {
          title: "Products",
          url: "/admin/products/1",
        },
        {
          title: "Create Product",
          url: "/admin/newproduct",
        },
      ],
    },
    {
      title: "Settings",
      url: "/admin/categories",
      icon: Settings2,
      items: [
        {
          title: "Categories",
          url: "/admin/categories",
        },
        {
          title: "Product Colors",
          url: "/admin/colors",
        },
        {
          title: "Gift Vouchers",
          url: "/admin/voucher",
        },
        {
          title: "Tax",
          url: "/admin/tax",
        },
        {
          title: "HSN Code",
          url: "/admin/hsncode",
        },
        {
          title: "Shipping Cost",
          url: "/admin/shippingcost",
        },
      ],
    },
    {
      title: "Customers",
      url: "/admin/customers",
      icon: HiOutlineUserGroup,
    },
  
  ],
}

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  return (
    <Sidebar collapsible="icon" {...props} className="border-r-2 border-gray-500">
      <SidebarHeader >
        <Link
          className="rounded-md  p-2 max-w-[200px] mx-auto"
          to="/admin"
        >
          <img alt="logo"
            src="/assets/logo/sakhi_logo.png" className='rounded-md'
          />
        </Link>
      </SidebarHeader>
      <SidebarContent className="headermenu text-white">
        <NavMain items={data.navMain} />
      </SidebarContent>
      <SidebarFooter className="headermenu text-white">
        <NavUser user={data.user} />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  )
}
