import { ColumnDef } from "@tanstack/react-table"
import { DataTableColumnHeader } from "../Table/data-table-column-header"
import { OrderT } from "@/react-app-env"
import { DataTableRowActions, DataTableRowActionsCourier, DataTableRowActionsOrder, OrderAmountCalulations, RazorPayTransactions, TaxCalulations } from "./data-table-row-actions"
import { formatToCurrency } from "../../../../../constants"



export const orderColumnsCancelled: ColumnDef<OrderT>[] = [

  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Id" />
    ),
    cell: ({ row }) => {
      const product = row.original
    return <div className="w-[80px]">{product.id}</div>
  },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "orderNumber",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Order Number" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: "orderDate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Order Date" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: "productName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Product Name" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: "mobile",
    header:  ({ column }) => (
      <DataTableColumnHeader column={column} title="Mobile" />
    ),
     cell: ({ row }) => (
      <div className="text-left">{row.getValue("mobile")}</div>
    ), 
  },
  {
    accessorKey: "deliveryAddress",
    header:  ({ column }) => (
      <DataTableColumnHeader column={column} title="Delivery Address" />
    ),
    cell:({row})=>{
      const {city,country,fullName,hno,mobile,pincode,state,street} =JSON.parse(row.getValue("deliveryAddress"));
      return <div className="text-left w-[250px] flex flex-wrap"><p>{fullName},{mobile},</p>
      <p >{hno},{street},{city}</p>
      <p>{pincode},{state},{country}</p></div>;
    }
  },
  /* {
    accessorKey: "price",
    header: () => <div className="text-left">Price</div>,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("price"));
      const currency = row.original.currency
      const formatted =formatToCurrency(amount,currency)
      return <div className="text-left font-medium">{formatted}</div>;
    },
  },
  {
    accessorKey: "discount",
    header: () => <div className="text-left">Discount</div>,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("discount"));
      const currency = row.original.currency
      const formatted =formatToCurrency(amount,currency)
      return <div className="text-left font-medium">{formatted}</div>;
    },
  },
  {
    accessorKey: "quantity",
    header: "Quantity",
    cell: ({ row }) => (
      <div className="text-center">{row.getValue("quantity")}</div>
    ),
  },
  {
    accessorKey: "totalAmount",
    header: "TotalAmount",
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("totalAmount"));
      const currency = row.original.currency
      const formatted =formatToCurrency(amount,currency)
      return <div className="text-left font-medium">{formatted}</div>;
    },
  }, */
  {
    accessorKey: "taxAmount",
    header: "TaxAmount",
    cell: ({ row }) => {
      return <TaxCalulations row={row} />;
    },
  },
  {
    accessorKey: "orderTotal",
    header: "Order Total",
    cell: ({ row }) => {
      return <OrderAmountCalulations row={row} />;
    },
  },
/*   {
    accessorKey: "orderTotal",
    header: "OrderTotal",
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("orderTotal"));
      const currency = row.original.currency
      const formatted =formatToCurrency(amount,currency)
      return <div className="text-left font-medium">{formatted}</div>;
    },
  },
  {
    accessorKey: "transactionNo",
    header: "TransactionNo",
  },
  {
    accessorKey: "transactionPaymentId",
    header: "PaymentId",
  }, */
  {
    accessorKey: "transactionNo",
    header: "Payment Info",
    cell: ({ row }) => <RazorPayTransactions row={row} />,
  },
  {
    accessorKey: "CourierBillNo",
    header: "Curier Info",
    cell: ({ row }) => <DataTableRowActionsCourier row={row} />,
  },
  {
    accessorKey: "orderStatus",
    header: "OrderStatus",
    cell: ({ row }) => <DataTableRowActionsOrder row={row} type="cancelled"/>,
  },
  {
    id:"actions",
    cell:({row})=><DataTableRowActions row={row}/>
  }

]
