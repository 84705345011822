import { NavLink, useLocation, useNavigate } from "react-router-dom";
import IconsHeader from "../RootLayout/IconsHeader";
import MobileNav from "../RootLayout/MobileNav";
import SubHeader from "./SubHeader";
import { Button } from "../../../components/ui/button";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";


const Header = () => {
  const { auth } = useSelector((state: RootState) => state.authCon)
  
  return (
    <header className="w-full headersticky max-sm:py-3 top-0 z-50 font-display shadow-lg bg-white" >
      <div className="flex-between wrapper-nav">
          <MobileNav/>
          <div className="md:flex gap-4 xl:min-w-[400px] max-lg:hidden "> </div>
        <NavLink to="/" className="w-[270px]">
        <img
          alt="logo"
          src="/assets/logo/sakhi_logo.png" className="w-full"
        /></NavLink>
        <div className="flex justify-end md:gap-6 lg:gap-6 gap-4 items-center text-green-900 md:p-4 ">  
        <IconsHeader/>
        </div>
      </div>
      <SubHeader/>
    </header>
  );
};

export default Header;


