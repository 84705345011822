import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import CartDetails, { CartDetailsAPI } from "../components/shared/Detail/CartDetails";
import { Button } from "../components/ui/button";
import { cartProduct } from "@/react-app-env";
import { useGetShoppingCartByCustomerQuery } from "../redux/slice/CartApiSlice";
import { useNavigate } from "react-router-dom";
import {  formatToCurrency } from "../constants";

const Cart = () => {
  const currentUser = JSON.parse(localStorage.getItem("sakhiweb")!);
  const { cartItems } = useSelector((state: RootState) => state.counterCart);
  const { currency } = useSelector((state: RootState) => state.counterCurrency);
  const { data: cartItemsAPI } = useGetShoppingCartByCustomerQuery(currentUser?.refId, { skip: !currentUser?.refId, refetchOnFocus: true, refetchOnMountOrArgChange: true })

  const navigate = useNavigate()
  const filterReadyToBuy = cartItemsAPI?.result?.length > 0 && cartItemsAPI?.result.filter((product: cartProduct) => product.readyToBuy === 1)
  const totalQty = filterReadyToBuy?.length > 0 ? filterReadyToBuy.reduce((acc:number,product: cartProduct)=>product.quantity+acc,0):0;
  const totalPrice = cartItemsAPI?.result?.length > 0 ? (cartItemsAPI?.result?.reduce((acc: number, product: cartProduct) => {
    const productTotal = product?.readyToBuy === 1 ? (currency === "USD" ? product.priceUSD : product.priceINR) * product.quantity : 0;
    return acc + productTotal;
  }, 0)) : (cartItems?.reduce((acc, product) => {
    const productTotal = (currency === "USD" ? product.priceUSD : product.priceINR)
    return acc + productTotal;
  }, 0))

  const DisAmount = cartItemsAPI?.result?.length > 0 ? cartItemsAPI?.result?.reduce((val: number, product: cartProduct) => {
    const discount = (product?.readyToBuy === 1 && product?.discountPercent) ? (currency === "USD" ? product.priceUSD * Number(product?.discountPercent) / 100 : product.priceINR * Number(product?.discountPercent) / 100) : 0;
    const disAmount = discount*product.quantity;
    return disAmount + val;
  }, 0) : cartItems?.reduce((val, item) => {
    const discount = item.discountPercent ? (currency === "USD" ? item.priceUSD * Number(item.discountPercent) / 100 : item.priceINR * Number(item.discountPercent) / 100) : 0;
    const disAmount = discount*item.count;
    return disAmount + val;
  }, 0)

  return (
    <div className="bgcolorgold ">
      <div className="wrapper-weaves my-4">
        <div className="flex md:justify-between md:flex-row flex-col gap-4 ">
          <div className="shadow-lg  px-2 bg-gray-100 w-full font-display">
            <h5 className="h5-bold">Shopping Cart</h5>
            <div className="flex justify-between px-2 items-center border-b-2 pb-3 border-gray-200">
              <p>Your Items</p>
              <p>price</p>
            </div>
            <div className="p-2 flex flex-col gap-3">
              {cartItemsAPI?.result?.length > 0 ? cartItemsAPI?.result?.map((item: cartProduct) => (
                <CartDetailsAPI key={item.id} saree={item} />
              )) : (cartItems?.length > 0 ? (
                cartItems.map((item:any) => (
                  <CartDetails key={item.id} saree={item} />
                ))
              ) : (
                <p className="text-center text-lg font-semibold">No cart Items Found</p>
              ))}
            </div>
          </div>
          {totalPrice > 0 && <div className="shadow-lg p-4 border-gray-500 border-2  md:w-[350px] w-full max-h-[270px] text-sm  flex flex-col gap-7 ">
            <div className="flex justify-between gap-4">
              <p>Total Qty</p>
              <p className="flex items-center">{totalQty}</p>
            </div>
            <div className="flex justify-between gap-4">
              <p>Sub Total</p>
              <p className="flex items-center">{totalPrice}</p>
            </div>
           {DisAmount>0&& <div className="flex justify-between gap-4">
              <p>Discount Amount</p>
              <p className="flex items-center">-{formatToCurrency(DisAmount,currency)}</p>
            </div>}
           {/* {GstAmount>0&& <div className="flex justify-between gap-4">
              <p>GST Amount</p>
              <p className="flex items-center">{new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(GstAmount)}</p>
            </div>} 
            <div className="flex justify-between gap-4">
              <p>Shipping</p>
              <p className="flex items-center">{formatToCurrency(shippingAmount,currency)}</p>
            </div>*/}
            <div className="flex justify-between gap-4 text-md font-semibold">
              <p>Total Price</p>
              <p className="flex items-center">{formatToCurrency(totalPrice -DisAmount,currency)}</p>

            </div>
            <Button onClick={() => { navigate("/checkout") }} className="headermenu">Proceed to buy</Button>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Cart;
