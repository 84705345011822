import React, { Dispatch, useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from '../../components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog"
import { Input } from "../../components/ui/input"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form"
import { DataTable } from '../../components/shared/admin/components/Table/data-table'
import { TaxColumnsSet } from '../../components/shared/admin/components/TaxTable/TaxColumns'
import { useGetAllTaxQuery, useSaveTaxMutation } from '../../redux/slice/admin/ACategoryApiSlice'
import { TaxDefaultValues, TaxFormSchema } from '../../constants'
import { toast } from 'react-toastify'

const TaxSettings = () => {
  const { data: taxData, isLoading, isSuccess, isError } = useGetAllTaxQuery("taxList", { refetchOnFocus: true, refetchOnMountOrArgChange: true })
 

let content;

if (isLoading) {
  content = (
    <div className="flex justify-center items-center h-full mt-48">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
    </div>
  );
}

if (isError) content = <p>{taxData?.result?.message}</p>

if (isSuccess){
  content=  <section >
  <main className=" border-2 rounded-md border-gray-500 md:m-2">
     <div className="flex justify-between p-4">
      <h5 className='h5-bold'>Tax Settings</h5>
      <AddTax />
    </div>
    {taxData?.result.length>0?<div className=" px-2">
      {taxData?.result?.length>0 &&    <DataTable data={taxData?.result} columns={TaxColumnsSet}  />}
    </div>:<p className=" p-4 rounded-md border-2  md:overflow-hidden shadow-lg">
      No Tax Available 
    </p>}
  </main>
  </section>
}

return <>{content}</>;

}


export function AddTax() {
  const [open, setOpen] = useState(false)
  const [SaveTax, { data: taxD, isSuccess }] = useSaveTaxMutation()

  const form = useForm<z.infer<typeof TaxFormSchema>>({
    resolver: zodResolver(TaxFormSchema),
    defaultValues: TaxDefaultValues
  })

  useEffect(() => {
    if (isSuccess && taxD?.success) {
        toast.success("New Tax Added Successfully")
    }
  }, [isSuccess, taxD?.success])

  async function onSubmit(values: z.infer<typeof TaxFormSchema>) {
    await SaveTax({ ...values })
    handleClose()
  }

  const { reset } = form;
  const handleClose = () => {
    setOpen(false);
    reset()
  };

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true)
      } else {
        handleClose()
      }
    }}>
      <DialogTrigger asChild>
        <Button size={"sm"} className='bg-gradient-to-r from-green-500 to-green-600 py-1'>Add Tax</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px] overflow-y-auto max-h-screen">
        <DialogHeader>
          <DialogTitle>Add Tax</DialogTitle>

        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="md:grid-cols-2 grid-cols-1 gap-4 py-2 grid">
            <FormField
              control={form.control}
              name="taxName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tax Name</FormLabel>
                  <FormControl>
                    <Input  {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="taxPercent"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tax Percent</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="igstPercent"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>IGST Percent</FormLabel>
                  <FormControl>
                    <Input  {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="cgstPercent"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>CGST Percent</FormLabel>
                  <FormControl>
                    <Input  {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="sgstPercent"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>SGST Percent</FormLabel>
                  <FormControl>
                    <Input  {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className='flex md:gap-10 gap-4 my-3 md:col-span-2 col-span-1 items-end'>
              <Button type="button" onClick={handleClose}>Cancel</Button>
              <Button type="submit" className="headermenu">Save Tax</Button>
            </div>
          </form>
        </Form>


      </DialogContent>
    </Dialog>
  )
}
export default TaxSettings