import { apiSlice } from "../../apiSlice";


export const ADashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdminDashboardData: builder.query({
      query: (id) => ({
        url: `/admin/getAdminDashboardData`,
      }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Dashboard", id: "LIST" },
            ...result.ids.map((id: any) => ({ type: "Dashboard", id })),
          ];
        } else return [{ type: "Dashboard", id: "LIST" }];
      },
    }),
    getSalesCountByMonth: builder.query({
      query: (year) => ({
        url: `/report/getSalesCountByMonth/${year}`,
      }),
    }),
    getCustomerByYear: builder.query({
      query: (year) => ({
        url: `/report/getCustomerByYear/${year}`,
      }),
    }),
    getProductsBycategories: builder.query({
      query: () => ({
        url: `/report/getProductsBycategories`,
      }),
    }),
    franchiseEnquiryEmail: builder.mutation({
      query: (enquiryData) => ({
        url: "/common/franchiseEnquiryEmail",
        method: "POST",
        body: { ...enquiryData }
      }),
      invalidatesTags: [
        { type: 'Dashboard', id: "LIST" }
      ]
    }),
    chatbotEnquiryEmail: builder.mutation({
      query: (enquiryData) => ({
        url: "/common/chatbotEnquiryEmail",
        method: "POST",
        body: { ...enquiryData }
      }),
      invalidatesTags: [
        { type: 'Dashboard', id: "LIST" }
      ]
    }),
  }),
});


export const {
  useGetAdminDashboardDataQuery,useGetSalesCountByMonthQuery,
  useFranchiseEnquiryEmailMutation,useGetProductsBycategoriesQuery,
  useChatbotEnquiryEmailMutation,useGetCustomerByYearQuery
} = ADashboardApiSlice

