import { Button } from "../../components/ui/button";
import { DataTable } from "../../components/shared/admin/components/Table/data-table";
import { useGetActiveOrdersAQuery, useGetOrdersByDateAMutation } from "../../redux/slice/admin/ACreateOrderApiSlice";
import { orderColumns } from "../../components/shared/admin/components/OrdersTable/OrderColumns";
import { CalendarIcon } from "@radix-ui/react-icons"
import { addDays, format, subDays } from "date-fns"
import { Calendar } from "../../components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip"
import { useEffect, useMemo, useState } from "react";
import { cn } from "../../lib/utils"
import { Label } from "../../components/ui/label";
import { OrderColumnsByDate } from "../../components/shared/admin/components/OrdersTable/OrderColumnsByDate";
import { BiSolidFileExport } from 'react-icons/bi'
import * as XLSX from 'xlsx'; 
import { toast } from 'react-toastify'

const DateRangeOrders = () => {
  const [getOrdersByDate, { isSuccess, isError, error, isLoading, data: getAllOrders }] = useGetOrdersByDateAMutation()
  const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 1))
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [refetch, SetRefetch] = useState(false)



  useEffect(() => {
    if (startDate && endDate) {
      const formattedDates = {
        startDate: startDate.toLocaleDateString('en-GB'), // 'en-GB' for DD/MM/YYYY format
        endDate: endDate.toLocaleDateString('en-GB')
      };
      getOrdersByDate(formattedDates)
    }

  }, [getOrdersByDate, startDate, endDate,refetch])

  const handleExport = async () => {
     if(getAllOrders?.result?.length>0){
      const worksheet = XLSX.utils.json_to_sheet(getAllOrders?.result);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Ordered Bills');
      XLSX.writeFile(workbook, 'OnlineSalesBills.xlsx');
     }else{
      toast.error("No data available to download")
     }
    }
  
  const columns = useMemo(()=>OrderColumnsByDate({SetRefetch}),[SetRefetch])

  let DateRange = <div className="flex gap-10">
    <div className="flex gap-4 items-center">
      <Label>Start Date:</Label>
      <DatePicker date={startDate} setDate={setStartDate} />
    </div>
    <div className="flex gap-4 items-center">
      <Label>End Date:</Label>
      <DatePicker date={endDate} setDate={setEndDate} />
    </div>
    <div className="flex gap-4 items-center">
      <TooltipProvider delayDuration={100}>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="outline" onClick={handleExport}><BiSolidFileExport size={20} color='blue' /></Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Export To excel</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  </div>

  let content
  if (isLoading) {
    content = (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }
  if (isError) content = <p>{getAllOrders?.result?.message}</p>
  if (!getAllOrders?.result?.length) {
    content = <div className="p-4 flex justify-between flex-wrap gap-10  text-lg font-semibold w-full">
      <div className="flex justify-between  flex-wrap gap-10 p-4">
        <h5 className='h5-bold'>ORDERS BY DATE</h5>
        {DateRange}
      </div>
      NO ORDERS PLACED ON SELECTED RAGNE DATE

    </div>
  }

  if (isSuccess && getAllOrders?.result?.length > 0) {
    content = <>
      <div className="flex gap-10 p-4">
        <h5 className='h5-bold'>ORDERS BY DATE</h5>
        {DateRange}
      </div>
      <div className=" px-2 ">
        {getAllOrders?.result?.length > 0 && <DataTable data={getAllOrders?.result} columns={columns} />}
      </div>
    </>
  }
  return (<section className="p-2">
    <main className=" border-2   border-gray-500 rounded-md">
      {content}
    </main>
  </section>)
}




export function DatePicker({ date, setDate }: any) {

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[240px] justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}


export default DateRangeOrders
