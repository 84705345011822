"use client"
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../../../../components/ui/tooltip"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { Button } from "../../../../components/ui/button"
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../../components/ui/form"
import { Checkbox } from "../../../../components/ui/checkbox"
import { InputBorder } from "../../../../components/ui/input"
import { useNavigate } from "react-router-dom"
import { productDefaultValues, productFormSchema } from "../../../../constants"
import { Separator } from "../../../../components/ui/separator"
import ProductDescriptionNew from "./ProductDescription"
import { IoIosClose } from "react-icons/io"
import { useEffect, useState } from "react"
import { FaInfoCircle } from "react-icons/fa"
import { IMGURL } from "../../../../constants/appConfig"
import { Label } from "../../../../components/ui/label"
import { toast } from "react-toastify"
import { GiPerspectiveDiceSixFacesRandom } from "react-icons/gi";
import { productDataT, ProductWithImages } from "@/react-app-env"
import { useAddNewProductMutation, useUpdateProductMutation } from "../../../../redux/slice/admin/AProductsApiSlice"
import { useGetGSTbyHSNMutation, useGetRandomNumberMutation } from "../../../../redux/slice/ACustomerAddressApiSlice"
import { AddColor, BranchSelect, CategorySubCatSelect, ColorsSelect } from "./Dropdown"
import { ViewImages, ViewStockManagement } from "./Table/data-table-row-actions"

type colorIdT = {
    id: number,
    colorCode: string
}

type ProductAddEditProps = {
    type?: string,
    product?: ProductWithImages
    refetch?: any
}

const ProductAddEdit = ({ type, product, refetch }: ProductAddEditProps) => {
    const [colorIdArr, setColorIdArr] = useState<colorIdT>({ id: product?.colorId! || 0, colorCode: product?.colorCode! || "" });
    const [imageBase64, setImageBase64] = useState<string | undefined>();
    const navigate = useNavigate()
    const [isViewStock, setIsViewStock] = useState<boolean>(false)
    const [isViewImages, setIsViewImages] = useState<boolean>(false)
    const [addNewProduct, { isSuccess: isSaved, data: savedProduct }] = useAddNewProductMutation()
    const [updateProduct, { isSuccess: isEdited, data: editedProduct }] = useUpdateProductMutation()
    const [getGSTbyHSN, { data: hsnDetails, isSuccess }] = useGetGSTbyHSNMutation()
    const [getRandomNumber, { isSuccess: isRandomSuccess, data: randomNumber }] = useGetRandomNumberMutation()
    const [hsnVerified, setHsnVerified] = useState("")


    const defaultValues = type === "Create" ? productDefaultValues : { ...product, wholesalePriceDiscount: Number(product?.wholesalePriceDiscount), branchId: 1, availableQuantity: 0, soldQuantity: 0 }
    const form = useForm<z.infer<typeof productFormSchema>>({
        resolver: zodResolver(productFormSchema),
        defaultValues: { ...defaultValues },
    })

    const handleRemoveColorLoc = () => {
        setColorIdArr({ id: 0, colorCode: '' })
        setValue("colorId", 0)
    }

    const { watch, setValue } = form;
    const catId = watch("categoryId");
    const hsnCode = watch("hsnCode");

    useEffect(() => {
        if (isSaved && savedProduct?.success) {
            toast("Product Added Successfully")
            navigate(`/admin/products/${catId}`)
        } else if (isEdited && editedProduct?.success) {
            toast("Product Updated Successfully")
            navigate(`/admin/products/${catId}`)
        }
    }, [editedProduct?.success, isEdited, isSaved, navigate, savedProduct?.success,catId])

    useEffect(() => {
        if (isSuccess && hsnDetails?.success) {
            toast(`Hsn Code verified successfully - GST - ${hsnDetails?.result?.igstPercent}`, { autoClose: 1000 })
            setHsnVerified(hsnDetails?.result?.igstPercent)
        } else if (isSuccess && !hsnDetails?.success) {
            toast.error("Hsn Code not verified", { autoClose: 1000 })
            setHsnVerified("")
        }
    }, [hsnDetails, isSuccess, setValue])

    useEffect(() => {
        if (isRandomSuccess && randomNumber?.success) {
            toast("Random Sku Generated", { autoClose: 1000 })
            setValue("sku", randomNumber?.result)
        }
    }, [isRandomSuccess, randomNumber?.result, randomNumber?.success, setValue])

    useEffect(() => {
        if (hsnCode.length >= 6) {
            getGSTbyHSN({ hsnCode })
        } setHsnVerified("")
    }, [getGSTbyHSN, hsnCode])

    const handleRandomNumber = async () => {
        await getRandomNumber({})
    }

    async function onSubmit(values: z.infer<typeof productFormSchema>) {
        if (!hsnVerified) {
            toast.error("verify Hsn Code")
            return;
        }
        if (type === "Create") {
            await addNewProduct({ ...values, image: values.thumbnail })
        } if (type === "Update") {
            const { branchId, availableQuantity, soldQuantity, ...filteredValues } = values;
            await updateProduct({ ...filteredValues, image: values.thumbnail, id: product?.id, isThumnailchanged: imageBase64?.length && true })
        }
    }


    return (<>

        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
                <div className="grid sm:grid-cols-2 grid-col-1 ">
                    {/* Product Information Section */}
                    <div className="p-3 sm:border-r-2  border-green-700">
                        <h2 className="text-base  font-semibold mb-4 border-b pb-2">Product Information</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
                            <div className="xl:col-span-3 sm:col-span-2 col-span-1">
                                <FormField
                                    control={form.control}
                                    name="productName"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Product Name</FormLabel>
                                            <FormControl>
                                                <InputBorder placeholder="Product Name" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <FormField
                                control={form.control}
                                name="hsnCode"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel className="flex justify-between gap-2 pt-2">HSN {hsnVerified.length > 0 && <span className="text-orange-500">GST:{hsnVerified}%</span>}</FormLabel>
                                        <FormControl>
                                            <InputBorder placeholder="HSN No" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="sku"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel className="flex gap-2 justify-between pt-2">SKU <button className="text-xs flex items-center mr-2" type="button" onClick={handleRandomNumber}><GiPerspectiveDiceSixFacesRandom color="orange" size={16} className="mr-1 text-orange-500 " />Generate</button></FormLabel>
                                        <FormControl>
                                            <InputBorder placeholder="SKU No" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="brandName"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Brand Name</FormLabel>
                                        <FormControl>
                                            <InputBorder placeholder="Brand Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <Separator className="bg-green-700 mt-6 sm:block hidden" />
                    </div>

                    {/* Pricing Section */}
                    <div className="p-3 ">
                        <h2 className="text-base  font-semibold mb-4 border-b pb-2">Pricing</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
                            <FormField
                                control={form.control}
                                name="priceMRP"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Price MRP</FormLabel>
                                        <FormControl>
                                            <InputBorder placeholder="Price Mrp" {...field} onChange={e => field.onChange(+e.target.value)} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="priceINR"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Price INR</FormLabel>
                                        <FormControl>
                                            <InputBorder placeholder="Price Inr" {...field} onChange={e => field.onChange(+e.target.value)} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="discountPercent"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Discount (%)</FormLabel>
                                        <FormControl>
                                            <InputBorder placeholder="Discount %" {...field} onChange={e => field.onChange(+e.target.value)} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="priceUSD"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Price USD</FormLabel>
                                        <FormControl>
                                            <InputBorder placeholder="Price USD" {...field} onChange={e => field.onChange(+e.target.value)} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="wholesalePriceINR"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Wholesale Price INR</FormLabel>
                                        <FormControl>
                                            <InputBorder placeholder="Wholesale Price INR" {...field} onChange={e => field.onChange(+e.target.value)} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="wholesalePriceDiscount"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Wholesale Price Dis (%)</FormLabel>
                                        <FormControl>
                                            <InputBorder placeholder="Wholesale Price Discount" {...field} onChange={e => field.onChange(+e.target.value)} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <Separator className="bg-green-700 mt-6 sm:block hidden" />
                    </div>

                    {/* Categories and Branch Section */}
                    <div className="p-3 sm:border-r-2 border-green-700">
                        <h2 className="text-base  font-semibold mb-4 border-b pb-2">Categories & Branches</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
                            <FormField
                                control={form.control}
                                name="categoryId"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Category</FormLabel>
                                        <FormControl>
                                            <CategorySubCatSelect value={field.value} onChange={field.onChange} varient="Category" />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="subcategoryId"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>SubCategory</FormLabel>
                                        <FormControl>
                                            <CategorySubCatSelect catId={catId} value={field.value} onChange={field.onChange} varient="SubCategory" />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="section"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Section</FormLabel>
                                        <FormControl>
                                            <InputBorder placeholder="Section" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    {/* Inventory Section */}
                    {type === "Create" ? <div className="p-3 ">
                        <h2 className="text-base  font-semibold mb-4 border-b pb-2">Inventory</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
                            <FormField
                                control={form.control}
                                name="availableQuantity"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Available Quantity</FormLabel>
                                        <FormControl>
                                            <InputBorder placeholder="Available Quantity" {...field} onChange={e => field.onChange(+e.target.value)} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="soldQuantity"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Sold Quantity</FormLabel>
                                        <FormControl>
                                            <InputBorder placeholder="Sold Quantity" {...field} onChange={e => field.onChange(+e.target.value)} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="branchId"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Branch</FormLabel>
                                        <FormControl>
                                            <BranchSelect onChange={field.onChange} value={field.value} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div> : <div className="p-3 ">
                        <h2 className="text-base  font-semibold mb-4 border-b pb-2">Inventory & Web Images</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
                            <div className="flex flex-col gap-4">
                                <Label>Quantity</Label>
                                <Button variant={"outline"} type="button" onClick={() => setIsViewStock(true)}>View Quantity</Button>
                            </div>
                            <div className="flex flex-col gap-4">
                                <Label>Web Images</Label>
                                <Button variant={"outline"} type="button" onClick={() => setIsViewImages(true)}>View Images</Button>
                            </div>
                        </div>
                    </div>}
                </div>

                <Separator className=" mt-6 sm:block hidden h-[2px]" />
                <div className="px-3 ">
                    <div className="grid grid-cols-1 sm:grid-cols-2  gap-4">
                        <div className="h-80 ">
                            <FormField
                                control={form.control}
                                name="productDescription"
                                render={({ field }) => (
                                    <FormItem className="w-full ">
                                        <FormLabel>Product Description</FormLabel>
                                        <FormControl>
                                            <ProductDescriptionNew onChangeHandler={field.onChange}
                                                value={field.value.toString()} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            /></div>
                        <div className="grid grid-cols-1 sm:grid-cols-2  gap-4">
                            <div className="flex flex-col gap-2 ">
                                <FormField
                                    control={form.control}
                                    name="thumbnail"
                                    render={({ field }) => (
                                        <FormItem className="w-full">
                                            <FormLabel>Choose Image Thumbnail</FormLabel>
                                            <FormControl>
                                                <InputBorder
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(event) => {
                                                        const file = event.target.files && event.target.files[0];
                                                        if (file) {
                                                            const reader = new FileReader();
                                                            reader.onload = () => {
                                                                const base64String = reader.result as string;
                                                                setImageBase64(base64String);
                                                                setValue("thumbnail", base64String)
                                                            };
                                                            reader.readAsDataURL(file); // Convert the selected file to Base64
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                {imageBase64 ? (<img src={imageBase64} alt="imgphot" width={100} height={100} />) : (
                                    product?.thumbnail !== undefined && (<img src={`${IMGURL}${product?.thumbnail}`} alt="imgphot" width={100} height={100} />)
                                )}
                            </div>
                            <div className="flex flex-col gap-2 px-4">
                                <div className="flex gap-12  items-center">
                                    <FormField
                                        control={form.control}
                                        name="colorId"
                                        render={({ field }) => (
                                            <FormItem className="w-full ">
                                                <FormControl>
                                                    <ColorsSelect
                                                        onChangeHandler={(event: any) => {
                                                            setColorIdArr(event);
                                                            field.onChange(event.id)
                                                        }}
                                                        value={field.value}
                                                        type={type}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <AddColor />
                                </div>

                                {colorIdArr.id > 0 && <div className="flex flex-wrap gap-4 items-center">
                                    <p className="text-sm font-semibold">Color:</p>
                                    <div className="relative ">
                                        <p className="w-[40px] h-[40px] rounded-full border border-gray-500 shadow-lg" style={{ backgroundColor: colorIdArr.colorCode }}></p>
                                        {type === "Create" && <IoIosClose className="absolute top-2 left-2 z-10" size={22} color="black" onClick={() => handleRemoveColorLoc()} />}
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex  gap-4 my-4 justify-end">
                    <Button type="button" onClick={() => navigate(-1)} className="w-28">Cancel</Button>
                    <Button type="submit" className="headermenu w-28">Save</Button>
                </div>
            </form>
        </Form >
        {isViewStock && <ViewStockManagement type={type} isViewStock={isViewStock} setIsViewStock={setIsViewStock} productId={product?.id!} />}
        {isViewImages && <ViewImages refetch={refetch} isViewImages={isViewImages} setIsViewImages={setIsViewImages} productImages={product?.images} />}
    </>
    )
}

export default ProductAddEdit