import { ColumnDef } from "@tanstack/react-table";
import {  DataTableRowActionsTax } from "./data-table-row-actions";
import { DataTableColumnHeader } from "../Table/data-table-column-header";
import { TaxDetailsT } from "@/react-app-env";


export const TaxColumnsSet: ColumnDef<TaxDetailsT>[] = [
  {
    accessorKey: "taxName",
    header: "Tax Name",
    cell: ({ row }) => (
      <div className="text-left">{row.getValue("taxName")}</div>
    ),
  },
  {
    accessorKey: "taxPercent",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Tax Percent (%)" />
    ),
    cell: ({ row }) => (
      <div className="text-left">{row.getValue("taxPercent")}</div>
    ),
  },
  {
    accessorKey: "igstPercent",
    header: "IGST Percent (%)"
  },
  {
    accessorKey: "cgstPercent",
    header: "CGST Percent (%)",
  },
  {
    accessorKey: "sgstPercent",
    header: "SGST Percent (%)"
  },
  {
    id: "actions",
    cell: ({ row }) => <DataTableRowActionsTax row={row}   />,
  }, 
];

