import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../components/ui/button";
import { IMGURL } from "../../../constants/appConfig";
import { TCartItems, cartProduct } from "@/react-app-env";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RootState } from "@/redux/store";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip"
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../components/ui/alert-dialog"
import { addSareeToCart, removeSareesFromCart } from "../../../redux/features/cartSlice";
import { useAddItemCartMutation, useDeleteItemCartMutation, useUpdateShoppingCartItemMutation } from "../../../redux/slice/CartApiSlice";
import { useDeleteItemWishlistMutation } from "../../../redux/slice/Wishlist";
import { Link } from "react-router-dom";
import { removesareeFromWhishlist } from "../../../redux/features/whishlistSlice";
import { useState } from "react";

const CartDetails = ({ saree, type }: { saree: TCartItems, type?: "WishList" | "Cart" }) => {
  const { currency } = useSelector((state: RootState) => state.counterCurrency);
  const dispatch = useDispatch()

  const handleAddToCart = async () => {
    await dispatch(addSareeToCart({ ...saree }))
  }
  return (
    <>
      <div className="flex justify-between flex-col md:flex-row md:px-4 items-center gap-2 max-md:border max-md:p-2 border-gray-50">
        <div className="flex gap-4">
          <img
            src={`${IMGURL}${saree.thumbnail}`}
            alt={""}
            className="rounded-md bg-cover w-[100px] h-[150px] "
          />
          <div className="flex flex-col gap-2 w-[150px]">
            <Link to={`/saree/${saree.id}`} className="text-md font-semibold hover:underline text-green-800">{saree.productName}</Link>
          </div>
        </div>
        <div>
          {type === "WishList" ? <Button className="headermenu" onClick={handleAddToCart} disabled={Boolean(!saree.availableQuantity!)} >Add to cart</Button> : <p>Qty: {saree.count}</p>}
        </div>
        <div>
          <AlertDialogDemo id={saree.id} type={type} />
        </div>
        <div>
          <p className="flex text-sm font-semibold items-center gap-1">
            {new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(currency === "USD" ? saree.priceUSD : saree.priceINR)}
          </p>
        </div>
      </div>
    </>
  );
};
export const CartDetailsAPI = ({ saree, type, setState }: { saree: cartProduct, type?: "WishList" | "Cart", setState?: any }) => {
  const { currency } = useSelector((state: RootState) => state.counterCurrency);
  const currentUser = JSON.parse(localStorage.getItem("sakhiweb")!);
  const [updateItemCart] = useUpdateShoppingCartItemMutation()
  const [addItemCart] = useAddItemCartMutation()
  const handleCheckboxChange = async () => {
    const response = await updateItemCart({ id: saree.id, readyToBuy: saree.readyToBuy === 0 ? 1 : 0 });
    if ('error' in response) {
      console.error('error:', response.error);
      return;
    } };

  const handleAddToCart = async () => {
    await addItemCart({ customerId: currentUser?.refId, productId: saree.productId, qty: 1, readyToBuy: 1 })
  }


  return (
    <>
      <div className=" flex justify-between flex-col md:flex-row md:px-4 items-center gap-2 max-md:border max-md:p-2 border-gray-50">
        <div className="flex gap-4">
          {!type && <input type="checkbox" checked={Boolean(saree.readyToBuy)} onChange={handleCheckboxChange} className="bg-black w-4 h-4" />}
          <img
            src={`${IMGURL}${saree.thumbnail}`}
            alt={""}
            className="rounded-md bg-cover w-[100px] h-[150px] "
          />
          <div className="flex flex-col gap-2 w-[150px]">
            <Link to={`/saree/${saree.productId}`} className="text-md font-semibold hover:underline text-green-800">{saree.productName}</Link>
          </div>
        </div>
        <div>
          {type === "WishList" ? <Button className="headermenu" onClick={handleAddToCart} disabled={Boolean(!saree.availableQuantity!)} >Add to cart</Button> : <p>Qty: {saree.quantity}</p>}
        </div>
        <div>
          <AlertDialogDemo id={saree.id} type={type} setState={setState} />
        </div>
        <div>
          <p className="flex text-sm font-semibold items-center gap-1">
            {new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(currency === "USD" ? saree.priceUSD : saree.priceINR)}
          </p>
        </div>
      </div>
    </>
  );
};


export function AlertDialogDemo({ id, type, setState }: { id: number, type?: string, setState?: any }) {
  const [deleteItemCart] = useDeleteItemCartMutation()
  const [deleteItemWishlist] = useDeleteItemWishlistMutation()
  const currentUser = JSON.parse(localStorage.getItem("sakhiweb")!);
  const [open,setOpen]=useState(false)
  const dispatch = useDispatch()
  const handleRemoveCart = async () => {
    if (currentUser?.token) {
      if (type === "WishList") {
        await deleteItemWishlist(id)
        setState((p: any) => p + 1);
      } else {
        await deleteItemCart(id)
      }
    } else {
      if (type === "WishList") {
        dispatch(removesareeFromWhishlist(id))
      } else {
        dispatch(removeSareesFromCart(id))
      }
    }
  }
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
     {/*  <AlertDialogTrigger asChild> */}
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button variant="ghost" onClick={()=>setOpen(true)}><RiDeleteBin6Line size={20} color="red" /></Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>{type === "WishList" ? "Remove Item From Wishlist" : "Remove Item From Cart"}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
  {/*     </AlertDialogTrigger> */}
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently remove the  item from cart.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleRemoveCart}>Continue</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}



export default CartDetails;