import { OrderT } from "@/react-app-env";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip";
import { Separator } from "../../../components/ui/separator";
import { IMGURL } from "../../../constants/appConfig";
import { Link } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../components/ui/alert-dialog"
import { Button } from "../../../components/ui/button";
import { useCancelOrderCustomerMutation } from "../../../redux/slice/admin/ACreateOrderApiSlice";
import { useState } from "react";
import { formatToCurrency } from "../../../constants";

type orderDetailsProps = {
  order: OrderT;
};
const OrdersDetails = ({ order }: orderDetailsProps) => {
  const { hno, street, city, pincode, state, country, mobile, fullName } =JSON.parse(order.deliveryAddress);
  const [day, month, year] = order.orderDate.split('/').map(Number);
const orderDate = new Date(year, month - 1, day);

const formattedDate = orderDate.toLocaleDateString(undefined, {
  year: "numeric",
  month: "long",
  day: "numeric",
});
  const OrderTotal = formatToCurrency(Number(order.orderTotal))



  return (<div className="flex flex-col  shadow-lg">
    <div className="w-full flex justify-between flex-wrap gap-4 items-center bg-gray-300 p-2 px-4">
      <div>
        <p className="text-xs font-medium">ORDER PLACED</p>
        <p className="text-sm ">{formattedDate}</p>
      </div>
      <div className="max-w-[150px]">
        <p className="text-xs font-medium">TOTAL</p>
        <p className="text-sm font-medium">
        {OrderTotal}
        </p>
      </div>
      <div className="max-w-[250px] flex flex-col items-start">
        <p className="text-xs font-medium">Quantity:</p>
        <p>{order.quantity}</p>
      </div>
      <div className="max-w-[200px] flex flex-col items-start">
        <p className="text-xs font-medium">SHIP TO</p>
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger className="text-red-500 underline text-sm  ">{fullName}</TooltipTrigger>
            <TooltipContent>
              <p className="text-md font-medium">{fullName}</p>
              <p className="text-md font-medium">Mb.No:{mobile}</p>
              <div className="flex gap-1">
                <p>{hno}{","}</p>
                <p>{street}{","}</p>
                <p>{city}</p>
              </div>
              <div className="flex gap-1">
                <p>{pincode}{","}</p>
                <p>{state}{","}</p>
                <p>{country}</p>
              </div>
  
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="max-w-[250px] flex flex-col items-start">
        <p className="text-xs md:font-medium">ORDER</p>
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger className=" hover:underline text-sm  ">{order.orderNumber}</TooltipTrigger>
            <TooltipContent>
            <p>{order.orderNumber}</p>
             <Link to={`${IMGURL}${order.imageUrl}`}target="_blank" className=" py-3 hover:underline">View Invoice</Link>
  
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
   
    <Separator />
    <div className="w-full flex justify-between md:flex-row flex-col gap-4 bg-gray-200 p-2 px-4">
      <div className="flex gap-4 justify-start">
        <img src={`${IMGURL}${order.thumbnail}`} alt={""} className="w-[140px] h-[180px]"/>
        <Link to={`/saree/${order.productId}`} className="text-base font-semibold text-green-500 hover:underline max-w-[300px]">{order.productName}</Link>
      </div>
      <div className="flex justify-between md:w-[50%]">
      <div className="flex flex-col gap-1 items-start w-[300px]">
      <p className="text-sm">ORDER STATUS:</p>
        <p className="text-md font-medium">{order.orderStatus}</p>
      </div>
      <div>
       <AlertDialogDemo id={order.id} status={order.orderStatus}/>
      </div>
      </div>
    </div>
    </div>);
};


export function AlertDialogDemo({id,status}:{id:number,status:string}) {
  const [cancelOrderCustomer]=useCancelOrderCustomerMutation()
  const [open,setOpen]=useState<boolean>(false)
  
  const handleClose=()=>{
    setOpen(false)
  }
   const handleRemoveCart =async()=>{
    await cancelOrderCustomer({ordId:id})
    setOpen(false)
   }
    return (
      <AlertDialog open={open} onOpenChange={() => {
        if (!open) {
          setOpen(true);
        } else {
          handleClose();
        }}}>
        <AlertDialogTrigger asChild>
          <Button variant="default" className="headermenu" disabled={status==="Cancelled - Pending Refund"}>Cancel Order</Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently cancel order.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Button onClick={handleRemoveCart}>Continue</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }
export default OrdersDetails;
