"use client"
import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { Row } from "@tanstack/react-table"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../ui/alert-dialog"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "../../../../ui/dropdown-menu"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../ui/form"
import { Button } from "../../../../ui/button"
import {  MdDeleteOutline, MdEdit } from "react-icons/md"
import { useEffect, useState } from "react"
import {  HSNCodeDetailsT } from "@/react-app-env"
import { toast } from "react-toastify"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {  HSNFormSchema } from "../../../../../constants"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog"
import { Textarea } from "../../../../ui/textarea"
import { TaxSelect } from "../../../../../Pages/admin/HSNCodeSettings"
import { Input } from "../../../../../components/ui/input"
import { useDeleteHSNMutation, useSaveHSNMutation } from "../../../../../redux/slice/admin/ACategoryApiSlice"

interface DataTableRowActionsProps<TData> {
  row: Row<HSNCodeDetailsT>,
  setRefetch:any
}
export function DataTableRowActionsHSN<TData>({
  row,setRefetch
}: DataTableRowActionsProps<TData>) {
  const hsnD = row.original;
  const [isDeleteopen, setIsDeleteOpen] = useState<boolean>(false)
  const [isEditopen, setIsEditOpen] = useState<boolean>(false)

  return (<>
    {isDeleteopen && <DeleteTax hsnD={hsnD} setIsDeleteOpen={setIsDeleteOpen} setRefetch={setRefetch} isDeleteopen={isDeleteopen} />}
    {isEditopen && <EditTax hsnDet={hsnD} setIsEditOpen={setIsEditOpen} setRefetch={setRefetch}  isEditopen={isEditopen} />}
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={()=>setIsEditOpen(true)}>
          Edit Tax<DropdownMenuShortcut><MdEdit size={20} /></DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => setIsDeleteOpen(true)}>
          Delete
          <DropdownMenuShortcut><MdDeleteOutline color="red" size={20} /></DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  </>)
}


export function EditTax({ hsnDet, isEditopen, setIsEditOpen,setRefetch }: {setRefetch:any,  hsnDet: HSNCodeDetailsT, isEditopen: boolean, setIsEditOpen: any }) {
  const [open, setOpen] = useState(false)
  const [SaveHSN, { data: hsnD, isSuccess }] = useSaveHSNMutation()

  const form = useForm<z.infer<typeof HSNFormSchema>>({
    resolver: zodResolver(HSNFormSchema),
    defaultValues: hsnDet
  })

  useEffect(() => {
    if (isEditopen) {
      setOpen(true)
    }
  }, [isEditopen])

  useEffect(() => {
    if (isSuccess && hsnD?.success) {
        toast.success("Edit HSN Code  Successfully")
        setOpen(false);
        setIsEditOpen(false)
        setRefetch((p:any)=>!p)
    }
  }, [isSuccess, setIsEditOpen, setRefetch, hsnD?.success])

  async function onSubmit(values: z.infer<typeof HSNFormSchema>) {
    await SaveHSN({ ...values,id:hsnDet.id })
  }

  const handleClose = () => {
    setOpen(false);
    setIsEditOpen(false)

  };

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true)
      } else {
        handleClose()
      }
    }}>
     {/*  <DialogTrigger asChild>
        <Button size={"sm"} className='bg-gradient-to-r from-green-500 to-green-600 py-1'>Add Tax</Button>
      </DialogTrigger> */}
      <DialogContent className="sm:max-w-[500px] overflow-y-auto max-h-screen">
        <DialogHeader>
          <DialogTitle>Edit HSN Code</DialogTitle>

        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="md:grid-cols-2 grid-cols-1 gap-4 py-2 grid">
            <FormField
              control={form.control}
              name="chapterCode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Chapter Code</FormLabel>
                  <FormControl>
                    <Input placeholder="Chapter Code" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="chapterName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Chapter Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Chapter Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="hsnCode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>HSN Code</FormLabel>
                  <FormControl>
                    <Input placeholder="HSN Code" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="taxId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tax</FormLabel>
                  <FormControl>
                    <TaxSelect value={field.value} onChange={field.onChange} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className=" md:col-span-2 col-span-1">
              <FormField
                control={form.control}
                name="productDescription"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea placeholder="Description" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className='flex md:gap-10 gap-4 my-3 md:col-span-2 col-span-1 items-end'>
              <Button type="button" onClick={handleClose}>Cancel</Button>
              <Button type="submit" className="headermenu">Save HSN Code</Button>
            </div>
          </form>
        </Form>

      </DialogContent>
    </Dialog>
  )
}
export function DeleteTax({ hsnD, isDeleteopen, setIsDeleteOpen,setRefetch }: { setRefetch:any, hsnD: HSNCodeDetailsT, isDeleteopen: boolean, setIsDeleteOpen: any }) {
  const [open, setOpen] = useState(false)
  const [deleteTax,{data,isSuccess}] = useDeleteHSNMutation()

  useEffect(() => {
    if (isDeleteopen) {
      setOpen(true)
    }
  }, [isDeleteopen])

  useEffect(() => {
    if (data?.success&&isSuccess) {
    toast.success("HSN Code Deleted SuccessFully")
    setOpen(false)
    setIsDeleteOpen(false)
    setRefetch((p:any)=>!p)
    }
  }, [data?.success, isSuccess, setIsDeleteOpen, setRefetch])


  const handleClose = () => {
    setOpen(false)
    setIsDeleteOpen(false)
  }


  const handleDelete = async () => {
    await deleteTax(hsnD.id)
  }

  return (<>
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <AlertDialogTrigger asChild>
        {/*  <Button size={"sm"}
      className="flex bg-red-500 data-[state=open]:bg-muted"
    >
      Delete Bill
    </Button> */}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete HSN Code from servers.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleDelete}>Delete</Button>
          {/*  <AlertDialogAction onClick={handleDelete}>Delete</AlertDialogAction> */}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  </>)
}





