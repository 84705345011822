import { FaDirections, FaMapMarkerAlt } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Locations = () => {
  const locations = [
    {
      name: "Sakhi the house of Kanchi weaves - Wholesale Weavers",
      address: "Dr No :2-17, G. V. R Building, Main Bazar Rd, opposite Congress Party Office, Near Gali Gopuram, Mangalagiri, Andhra Pradesh 522503",
      googleMapsLink: "https://www.google.com/maps/place/Sakhi+the+house+of+Kanchi+weaves+-+Wholesale+Weavers/@16.4323094,80.568411,15z/data=!4m6!3m5!1s0x3a35f1286aba421d:0x67841f58a9895a0c!8m2!3d16.4323094!4d80.568411!16s%2Fg%2F11st901211?hl=en-IN&entry=ttu"
    },
    {
      name: "Sakhi The House of Kanchi Weaves - Saree Shop",
      address: "3/7, near Sai Baba Temple, Brodipet, Guntur, Andhra Pradesh 522002",
      googleMapsLink: "https://www.google.com/maps/place/Sakhi.The+house+of+Kanchi+weaves.Saree+Shop./@16.3014397,80.4365368,15z/data=!4m6!3m5!1s0x3a4a75001d9eff97:0x9efd33bfcb263ee0!8m2!3d16.3014397!4d80.4365368!16s%2Fg%2F11y7b21d8y?hl=en-IN&entry=ttu"
    },
    {
      name: "Sakhi the house of kanchi weaves - KPHB",
      address: "H-No :3, Ground Floor, Rd Number 1, MIG No 1, Kukatpally Housing Board Colony, Kukatpally, Hyderabad, Telangana 500085",
      googleMapsLink: "https://www.google.com/maps/place/Sakhi+the+house+of+kanchi+weaves+-+KPHB/@17.4929997,78.4025035,15z/data=!4m6!3m5!1s0x3bcb91e1ac4501d3:0xb076e2c8aa8b8c0b!8m2!3d17.4929997!4d78.4025035!16s%2Fg%2F11l32zjmcn?hl=en-IN&entry=ttu"
    },
    {
      name: "Sakhi the house of kanchi weaves - Secunderabad",
      address: "Taj Tristar Patny, Patny center, opp: Head post office, 1-1-92 to 94, Kummari Guda, Shivaji Nagar, Secunderabad, Telangana 500003",
      googleMapsLink: "https://www.google.com/maps/place/Sakhi+the+house+of+kanchi+weaves/@17.4415605,78.49573,15z/data=!4m6!3m5!1s0x3bcb9b9942049067:0x5d4ef996f30d16c0!8m2!3d17.4415605!4d78.49573!16s%2Fg%2F11k3vlgl8z?hl=en-IN&entry=ttu"
    },
    {
      name: "Sakhi the house of kanchi weaves - Malkajgiri",
      address: " 1-1-241/10/NR, Circle-1-Kapra, Plot No.10, Saket Road, Kapra Bhavani Nagar Colony Main Road, Hyderabad, Medchal, Malkajgiri, Telangana-500062",
      googleMapsLink: "https://www.google.com/maps/place/10,+Kapra+-+Saket+Rd,+Bhavani+Nagar+Colony,+Kushaiguda,+Secunderabad,+Telangana+500062/@17.4810528,78.5592373,17z/data=!3m1!4b1!4m10!1m2!2m1!1sPlot+No.10,+Saket+Road,+Kapra+Bhavani+Nagar+Colony+Main+Road,++Hyderabad,+Medchal,+Malkajgiri,+Telangana-500062!3m6!1s0x3bcb9b7fbb0c8f8f:0xf881bebd2732e8f5!8m2!3d17.4810529!4d78.5641082!15sCm9QbG90IE5vLjEwLCBTYWtldCBSb2FkLCBLYXByYSBCaGF2YW5pIE5hZ2FyIENvbG9ueSBNYWluIFJvYWQsICBIeWRlcmFiYWQsIE1lZGNoYWwsIE1hbGthamdpcmksIFRlbGFuZ2FuYS01MDAwNjKSARBnZW9jb2RlZF9hZGRyZXNz4AEA!16s%2Fg%2F11n0__h5r6?entry=ttu"
    }
  ];
 return (
    <div className=" py-6">
      <div className="wrapper flex flex-col gap-6">
        <div>
          <p className="text-2xl font-bold text-center">OUR LOCATIONS</p>
        </div>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {locations.map((location, index) => (
            <div
              key={index}
              className="bg-gradient-to-r from-green-50  to-green-200 shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
            >
              <div className="flex items-center mb-4">
                <div className="bg-green-800 text-white p-3 rounded-full">
                  {/* Example icon: Map Pin */}
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17.657 16.657L13 21.313l-4.657-4.657a8 8 0 1111.314 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </div>
                <div className="ml-4">
                  <p className="font-bold text-lg">{location.name}</p>
                </div>
              </div>
              <p className="text-gray-700 mb-4">{location.address}</p>
              <Link
                to={location.googleMapsLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-800 flex gap-2 items-center underline hover:text-blue-700"
              >
               <FaArrowRight className="ml-2" /> Get Directions
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};



export default Locations