"use client"

import { CalendarIcon } from "lucide-react"
import { Bar, BarChart, CartesianGrid, XAxis, Label, Pie, PieChart, Line, LineChart } from "recharts"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../../components/ui/card"
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../../components/ui/chart"
import { useGetCustomerByYearQuery, useGetProductsBycategoriesQuery, useGetSalesCountByMonthQuery } from "../../../../../redux/slice/admin/ADashboardApiSlice"
import React, { useState } from "react"
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../../components/ui/popover"
import { Button } from "../../../../../components/ui/button"
import { format } from "date-fns"
import { cn } from "../../../../../lib/utils"


const chartConfig3 = {
  onlineSales: {
    label: "Online Sales",
    color: "hsl(var(--chart-1))",
  },
  offlineSales: {
    label: "Offline Sales",
    color: "hsl(var(--chart-2))",
  },
} satisfies ChartConfig


export function MonthSales() {
  const [date, setDate] = useState(new Date())
  const { data: salesData } = useGetSalesCountByMonthQuery(date.getFullYear())
  const chartData = salesData?.result?.length > 0 && salesData?.result;

  return (
    <Card>
      <CardHeader >
        <div className="flex justify-between gap-4 w-full">
          <div>
            <h1>Sales Report</h1>
            <CardDescription>January - December {date.getFullYear()}</CardDescription>
          </div>
          <DatePickerYear date={date} setDate={setDate} />
        </div>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig3} className="aspect-auto h-[300px] w-full">
          <BarChart accessibilityLayer data={chartData} >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="saleMonth"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              /*   tickFormatter={(value) => value.slice(0, 3)} */
              tickFormatter={(value) => {
                const year = date.getFullYear()
                return `${value.slice(0, 3)}-${year}`
              }}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent />}
            />

            <Bar dataKey="offlineSales" fill="var(--color-offlineSales)" radius={8} />
            <Bar dataKey="onlineSales" fill="var(--color-onlineSales)" radius={8} />
            <ChartLegend content={<ChartLegendContent />} />

          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}

const chartColors = ["var(--color-chrome)",
  "var(--color-safari)",
  "var(--color-firefox)",
  "var(--color-edge)",
  "var(--color-other)",
]

const chartConfig1 = {
  chrome: {
    label: "PURE SILK SAREES",
    color: "hsl(var(--chart-1))",
  },
  safari: {
    label: "BANARAS SAREES",
    color: "hsl(var(--chart-2))",
  },
  firefox: {
    label: "FANCY SAREES",
    color: "hsl(var(--chart-3))",
  },
  edge: {
    label: "COTTON SAREES",
    color: "hsl(var(--chart-4))",
  },
  other: {
    label: "Other",
    color: "hsl(var(--chart-5))",
  },
} satisfies ChartConfig

export function CatProducts() {
  const { data: prodcutsCount } = useGetProductsBycategoriesQuery({})
  const totalVisitors = React.useMemo(() => {
    return prodcutsCount?.result?.reduce((acc: number, curr: any) => acc + curr.count, 0)
  }, [prodcutsCount?.result])
  const chartData = prodcutsCount?.result?.length > 0 && prodcutsCount?.result?.map((pro: any, idx: number) => ({ ...pro, colorName: Object.keys(chartConfig1)[idx], fill: chartColors[idx] }));

  return (
    <Card className="flex flex-col h-full">
      <CardHeader className="items-center pb-0">
        <h1>Products vs Category</h1>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <ChartContainer
          config={chartConfig1}
          className="mx-auto aspect-square max-h-[320px]"
        >
          <PieChart>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent className="min-w-48" labelKey="categoryName" nameKey="count" />}
            />
            <ChartLegend content={<ChartLegendContent nameKey="colorName" />} />

            <Pie
              data={chartData}
              dataKey="count"
              nameKey="categoryName"
              innerRadius={60}
              strokeWidth={5}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-3xl font-bold"
                        >
                          {totalVisitors.toLocaleString()}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground"
                        >
                          Products
                        </tspan>
                      </text>
                    )
                  }
                }}
              />
            </Pie>
          </PieChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}

const chartConfig2 = {
  chrome: {
    label: "PURE SILK SAREES",
    color: "hsl(var(--chart-1))",
  },
  safari: {
    label: "Active Orders",
    color: "hsl(var(--chart-2))",
  },
  firefox: {
    label: "Cancelled Orders",
    color: "hsl(var(--chart-3))",
  },
  edge: {
    label: "Delivered Orders",
    color: "hsl(var(--chart-4))",
  },
  other: {
    label: "Other",
    color: "hsl(var(--chart-5))",
  },
} satisfies ChartConfig
export function CatOrders({ orderData }: any) {

  const totalVisitors = React.useMemo(() => {
    return orderData.reduce((acc: number, curr: any) => acc + curr.count, 0)
  }, [orderData])
  const chartData = orderData?.length > 0 && orderData?.map((pro: any, idx: number) => ({ ...pro, colorName: Object.keys(chartConfig1)[idx + 1], fill: chartColors[idx + 1] }));

  return (
    <Card className="flex flex-col h-full">
      <CardHeader className="items-center pb-0">
        <h1>Online Orders</h1>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <ChartContainer
          config={chartConfig2}
          className="mx-auto aspect-square max-h-[320px]"
        >
          <PieChart>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent className="min-w-48" labelKey="name" nameKey="count" />}
            />
            <ChartLegend content={<ChartLegendContent nameKey="colorName" />} />

            <Pie
              data={chartData}
              dataKey="count"
              nameKey="name"
              innerRadius={60}
              strokeWidth={5}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-3xl font-bold"
                        >
                          {totalVisitors.toLocaleString()}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground"
                        >
                          Total Orders
                        </tspan>
                      </text>
                    )
                  }
                }}
              />
            </Pie>
          </PieChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}
const chartConfig = {
  Online: {
    label: "Online",
    color: "hsl(var(--chart-1))",
  },
  Offline: {
    label: "Offline",
    color: "hsl(var(--chart-2))",
  },
} satisfies ChartConfig

export function CustomersChart() {
  const [date, setDate] = useState(new Date())
  const { data: customersData } = useGetCustomerByYearQuery(date.getFullYear())
  const chartData = customersData?.result?.length > 0 && customersData?.result;

  const [activeChart, setActiveChart] =
    React.useState<keyof typeof chartConfig>("Online")

  const total = chartData?.length > 0 && {
    Online: chartData?.reduce((acc: number, curr: any) => acc + Number(curr.Online), 0),
    Offline: chartData?.reduce((acc: number, curr: any) => acc + Number(curr.Offline), 0),
  };

  return (
    <Card>
      <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
        <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
          <div className="flex gap-4">
            <h1>New Customers</h1>
            <DatePickerYear date={date} setDate={setDate} />
          </div>
          <CardDescription>
            Showing total customers registered by month
          </CardDescription>
        </div>
        <div className="flex">
          {["Online", "Offline"].map((key) => {
            const chart = key as keyof typeof chartConfig
            return (
              <button
                key={chart}
                data-active={activeChart === chart}
                className="flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-muted/70 sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
                onClick={() => setActiveChart(chart)}
              >
                <span className="text-xs text-muted-foreground">
                  {chartConfig[chart].label}
                </span>
                <span className="text-lg font-bold leading-none sm:text-3xl">
                  {total[key as keyof typeof total]}
                </span>
              </button>
            )
          })}
        </div>
      </CardHeader>
      <CardContent className="px-2 sm:p-6">
        <ChartContainer
          config={chartConfig}
          className="aspect-auto h-[250px] w-full"
        >
          <LineChart
            accessibilityLayer
            data={chartData}
            margin={{
              left: 12,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="registerMonth"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              minTickGap={32}
              tickFormatter={(value) => value.slice(0, 3)}
            /* tickFormatter={(value) => {
              const date = new Date(value)
              return date.toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
              })
            }} */
            />
            <ChartTooltip
              content={
                <ChartTooltipContent
                  className="w-[150px]"
                  nameKey="views"
                /*  labelFormatter={(value) => {
                   return new Date(value).toLocaleDateString("en-US", {
                     month: "short",
                     day: "numeric",
                     year: "numeric",
                   })
                 }} */
                />
              }
            />
            <ChartLegend content={<ChartLegendContent nameKey={activeChart} />} />

            <Line
              dataKey={activeChart}
              type="monotone"
              stroke={`var(--color-${activeChart})`}
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}

export function DatePickerYear({ date, setDate }: any) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "max-w-[240px] justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "yyyy") : <span>Pick a year</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto h-auto p-0" align="end">
        <DatePicker
          onChange={date => setDate(date!)}
          selected={date}
          inline
          dateFormat="yyyy"
          showYearPicker
          minDate={new Date('2023-01-01')}
          maxDate={new Date('2025-12-31')}
        />
      </PopoverContent>
    </Popover>
  )
}
