
import { apiSlice } from "../apiSlice";


export const wishlistApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addItemWishlist: builder.mutation({
            query: initialCartData => ({
                url: '/customer/addItemWishlist',
                method: 'POST',
                body: {
                    ...initialCartData,
                }
            }),
            invalidatesTags: [
                { type: 'Wishlist', id: "LIST" }
            ]
        }),
        deleteItemWishlist: builder.mutation({
            query: (id) => ({
                url: `/customer/deleteItemWishlist/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Wishlist', id: arg.id }
            ]
        }),
        getWishlistCountByCustomer: builder.query({
            query: (id) => ({ url: `/customer/getWishlistCountByCustomer/${id}` }),
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: "Wishlist", id: "LIST" },
                        ...result.ids.map((id: any) => ({ type: "Wishlist", id })),
                    ];
                } else return [{ type: "Wishlist", id: "LIST" }];
            },
        }),
        getWishlistByCustomer: builder.mutation({
            query: initialData => ({ url: `/customer/getWishlistByCustomer`,
                 method: "POST", body: { ...initialData } }),
            invalidatesTags: [{ type: "Wishlist", id: "LIST" },],
        }),
    }),
})

export const {
    useAddItemWishlistMutation,
    useDeleteItemWishlistMutation,
    useGetWishlistByCustomerMutation,
    useGetWishlistCountByCustomerQuery
} = wishlistApiSlice

