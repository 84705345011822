import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { Row } from "@tanstack/react-table"
import { Button } from "../../../../ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "../../../../ui/dropdown-menu"
import { z } from "zod"
import { ProductStockByBranchT, productDataT } from "@/react-app-env"
import { useNavigate } from "react-router-dom"
import { FiEdit } from "react-icons/fi";
import { useDeleteProductAMutation, useGetProductStockByProductIdMutation, useUpdateProductStockMutation } from "../../../../../redux/slice/admin/AProductsApiSlice"
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "../../../../../components/ui/alert-dialog"
import { IoIosEye } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { IoMdImages } from "react-icons/io";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from "../../../../../components/ui/sheet"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { productStockFormSchema } from "../../../../../constants"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../../../../components/ui/dialog"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../../../components/ui/form"
import { InputBorder } from "../../../../../components/ui/input"
import { BranchSelect } from "../Dropdown"
import { MdDeleteOutline, MdEdit } from "react-icons/md"
import { FaBoxOpen } from "react-icons/fa6"
import { toast } from "react-toastify"
import { DeleteImage } from "../ProductForm"
import { IMGURL } from "../../../../../constants/appConfig"

interface DataTableRowActionsProps<TData> {
  row: Row<productDataT>,
  setReftch?:any
}

export function DataTableRowActions<TData>({
  row,setReftch
}: DataTableRowActionsProps<TData>) {
  const product = row.original;
  const navigate = useNavigate();
const [isViewStock, setIsViewStock] =useState(false)
const [isDeleteopen, setIsDeleteOpen] = useState<boolean>(false)
  return (<>
      {isViewStock && <ViewStockManagement isViewStock={isViewStock} setIsViewStock={setIsViewStock} productId={product.id} />}
      {isDeleteopen&&<DeleteProduct setReftch={setReftch} isDeleteopen={isDeleteopen} setIsDeleteOpen={setIsDeleteOpen} productId={product.id}/>}
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px] flex-col flex gap-1">
        <DropdownMenuItem onClick={() => navigate(`/admin/uploadimages/${product.id}/${product.categoryId}`)}>Upload More Images
          <DropdownMenuShortcut><IoMdImages  size={20} /></DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate(`/admin/view/${product.id}`)}>View
          <DropdownMenuShortcut><IoIosEye  size={20} /></DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate(`/admin/edit/${product.id}`)}>Edit
          <DropdownMenuShortcut><FiEdit  size={20} /></DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setIsViewStock(true)}>View Stock
          <DropdownMenuShortcut><FaBoxOpen  size={20} /></DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => setIsDeleteOpen(true)}>
          Delete
          <DropdownMenuShortcut><MdDeleteOutline color="red" size={20} /></DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
    </>
 );
}export function DeleteProduct({setReftch, productId, isDeleteopen, setIsDeleteOpen }: { setReftch:Dispatch<SetStateAction<boolean>>, productId: number, isDeleteopen: boolean, setIsDeleteOpen: any }) {

  const [open, setOpen] = useState(false)
  const [deleteProduct,{data:productData,isSuccess}] = useDeleteProductAMutation()

  useEffect(() => {
    if (isDeleteopen) {
      setOpen(true)
    }
  }, [isDeleteopen])

  useEffect(() => {
    if (productData?.success && isSuccess) {
    toast.success("Product Deleted SuccessFully")
    setReftch(p=>!p)
    setOpen(false)
    setIsDeleteOpen(false)
    }
  }, [productData?.success, isSuccess, setIsDeleteOpen, setReftch])

  

  const handleClose = () => {
    setOpen(false)
    setIsDeleteOpen(false)
  }


  const handleDelete = async () => {
    await deleteProduct(productId)
  }

  return (<>
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <AlertDialogTrigger asChild>
        {/*  <Button size={"sm"}
      className="flex bg-red-500 data-[state=open]:bg-muted"
    >
      Delete Bill
    </Button> */}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete Product from servers.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleDelete}>Delete</Button>
         {/*  <AlertDialogAction onClick={handleDelete}>Delete</AlertDialogAction> */}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  </>)
}

export function ViewStockManagement({ isViewStock, setIsViewStock, productId, type }: { type?: string, isViewStock: boolean, setIsViewStock: Dispatch<SetStateAction<boolean>>, productId: number }) {
  const [open, setOpen] = useState(false)
  const [isEditStock, setIsEditStock] = useState(false)
  const [productStock, setProductStock] = useState<ProductStockByBranchT | null>()
  const [getProductStockByProductId, { data }] = useGetProductStockByProductIdMutation()
  const [updateProductStock, { data: updateStockData, isSuccess }] = useUpdateProductStockMutation()

  useEffect(() => {
    if (updateStockData?.success && isSuccess) {
      getProductStockByProductId({ id: productId })
    }
  }, [getProductStockByProductId, isSuccess, productId, updateStockData?.success])

  useEffect(() => {
    if (isViewStock) {
      setOpen(true)
      getProductStockByProductId({ id: productId })
    }
  }, [getProductStockByProductId, isViewStock, productId])



  const handleClose = () => {
    setOpen(false)
    setIsViewStock(false)
  }

  const handleEdit = (stock: ProductStockByBranchT) => {
    setProductStock(stock)
    setIsEditStock(true)
  }
  return (<>
    <Sheet open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true)
      } else {
        handleClose()
      }
    }}>
      <SheetContent className="h-screen overflow-y-auto">
        <SheetHeader>
          <SheetTitle>View Stock</SheetTitle>
          <SheetDescription>
            Product Stock By Each Branch.
          </SheetDescription>
        </SheetHeader>
        <div className=" py-4">
          {data?.result?.length > 0 ? <table className="table-auto w-full border py-4">
            <thead>
              <tr className="bg-green-400 text-white text-sm py-2">
                <th className="py-1">Branch Name</th>
                <th className="py-1">Available QTY</th>
                <th className="py-1">Sold QTY</th>
                {(type === "Update") && <th className="py-1">Action</th>}
              </tr>
            </thead>
            <tbody>
              {data?.result?.map((stock: ProductStockByBranchT) => (
                <tr key={stock.id}>
                  <td className="border text-center text-sm py-2">{stock.branchName}</td>
                  <td className="border text-center text-sm py-2">{stock.availableQuantity}</td>
                  <td className="border text-center text-sm py-2">{stock.soldQuantity}</td>
                  {(type === "Update") && <td onClick={() => handleEdit(stock)} className="border py-2  flex justify-center hover:cursor-pointer"><MdEdit size={20} /></td>}
                </tr>
              ))}
            </tbody>
          </table>
            : <div>No Stock Available</div>}
        </div>
      </SheetContent>
    </Sheet>
    {isEditStock && <EditStock updateProductStock={updateProductStock} isEditStock={isEditStock} setIsEditStock={setIsEditStock} productStock={productStock!} />}
  </>)
}
export function ViewImages({ isViewImages,refetch, setIsViewImages, productImages }: {refetch:any, isViewImages: boolean, setIsViewImages: Dispatch<SetStateAction<boolean>>, productImages: any }) {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (isViewImages) {
      setOpen(true)
    }
  }, [ isViewImages])

  const handleClose = () => {
    setOpen(false)
    setIsViewImages(false)
  }

 
  return (<>
    <Sheet open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true)
      } else {
        handleClose()
      }
    }}>
      <SheetContent className="h-screen overflow-y-auto">
        <SheetHeader>
          <SheetTitle>View Images</SheetTitle>
          <SheetDescription>
           Images For web.
          </SheetDescription>
        </SheetHeader>
        <div className=" py-4 flex flex-wrap gap-4">
        {productImages?.map((image:any) => {
              return <div className="relative" key={image.id}><img src={`${IMGURL}${image.imageUrl}`} alt={image.imageUrl} width={100} height={100} />
                <DeleteImage image={image} refetch={refetch} />
              </div>
            })}
        </div>
      </SheetContent>
    </Sheet>
  </>)
}


export function EditStock({ isEditStock, setIsEditStock, productStock, updateProductStock }: { updateProductStock: any, isEditStock: boolean, setIsEditStock: Dispatch<SetStateAction<boolean>>, productStock: ProductStockByBranchT }) {
  const [open, setOpen] = useState(false)
  const defaultValues = { ...productStock }
  // const defaultValues = productStockDefaultValues
  const form = useForm<z.infer<typeof productStockFormSchema>>({
    resolver: zodResolver(productStockFormSchema),
    defaultValues: { ...defaultValues },
  })

  useEffect(() => {
    if (isEditStock) {
      setOpen(true)
    }
  }, [isEditStock])

  const handleClose = () => {
    setOpen(false)
    setIsEditStock(false)
  }

  async function onSubmit(values: z.infer<typeof productStockFormSchema>) {
    await updateProductStock({ ...values, id: productStock.id })
    handleClose()
  }

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true)
      } else {
        handleClose()
      }
    }}>
      {/* <DialogTrigger asChild>
        <Button variant="outline">Edit Stock Quantity</Button>
      </DialogTrigger> */}
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit Stock Quantity</DialogTitle>
          <DialogDescription> </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
            <div className="grid grid-cols-1 sm:grid-cols-2  gap-4">
              <FormField
                control={form.control}
                name="availableQuantity"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Available Quantity</FormLabel>
                    <FormControl>
                      <InputBorder placeholder="Available Quantity" {...field} onChange={e => field.onChange(+e.target.value)} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="soldQuantity"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Sold Quantity</FormLabel>
                    <FormControl>
                      <InputBorder placeholder="Sold Quantity" {...field} onChange={e => field.onChange(+e.target.value)} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="branchId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Branch</FormLabel>
                    <FormControl>
                      <BranchSelect onChange={field.onChange} value={field.value} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex gap-10 justify-end mt-10">
              <Button type="button" onClick={handleClose}>Cancel</Button>
              <Button type="submit" className="headermenu">Save Quantity</Button>
            </div>
          </form>
        </Form >
      </DialogContent>
    </Dialog >
  )
}
