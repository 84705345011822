
import { DataTable } from "../../components/shared/admin/components/Table/data-table";
import { useGetActiveOrdersAQuery } from "../../redux/slice/admin/ACreateOrderApiSlice";
import { orderColumns } from "../../components/shared/admin/components/OrdersTable/OrderColumns";



const OrdersAdmin = () => {
   const { isSuccess, isError, error,isLoading, data: getAllOrders } = useGetActiveOrdersAQuery("ordersList", {pollingInterval: 6000,refetchOnFocus: true,refetchOnMountOrArgChange: true,})
  
   let content
   if (isLoading) {
    content = (
        <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
    );
}
   if (isError) content = <p>{getAllOrders?.result?.message}</p>
   if (!getAllOrders?.result?.length){
     content= <p className="p-4 text-center text-lg font-semibold w-full">
       NO ORDERS PLACED YET
     </p>}
   if (isSuccess&& getAllOrders?.result?.length>0){
     content=  <>
     <div className="flex justify-between p-4">
        <h5 className='h5-bold'>ACTIVE ORDERS</h5>
      </div>
      <div className="px-2">
        {getAllOrders?.result?.length>0 && <DataTable data={getAllOrders?.result} columns={orderColumns}/>}
      </div>
     </>
   }
  return (<section className="p-2">
    <main className=" border-2  border-gray-500 rounded-md">
      {content}
    </main>
    </section>)
}

export default OrdersAdmin
