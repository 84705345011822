import { MdCurrencyRupee } from "react-icons/md"
import { Card } from "../../ui/card"
import { IMGURL } from "../../../constants/appConfig"
import { useSelector } from "react-redux"
import { RootState } from "@/redux/store"
import { BsCurrencyDollar } from "react-icons/bs"
import { productDataT } from "@/react-app-env"
import { Link } from "react-router-dom"

const CardComponent = ({shopbyprice}:{shopbyprice:productDataT}) => {

  const {currency} = useSelector((state: RootState)=>state.counterCurrency)
  return (
    <div>
          <Card className="max-w-[330px]  h-[465px] mx-1 shadow-lg border-2 border-gray-150 headermenu text-white">
              <Link to={`/saree/${shopbyprice.id}`}>
                  <img
                    src={`${IMGURL}${shopbyprice.thumbnail}`}
                    alt={shopbyprice.productName}
                    width={200}
                    height={150}
                    className="rounded-t-md w-full h-[350px] object-cover "
                  />

              </Link>
              <div className="flex justify-center  p-4 flex-col items-center gap-2">
                <p className="text-lg  line-clamp-2 overflow-ellipsis">{shopbyprice.productName}</p>
                <p className="text-md  flex gap-2 items-center">
                { new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(currency==="USD"?shopbyprice.priceUSD:shopbyprice.priceINR)}
                </p>
              </div>
            </Card>
    </div>
  )
}
/* const CardComponent = ({shopbyprice}:{shopbyprice:productDataT}) => {

  const {currency} = useSelector((state: RootState)=>state.counterCurrency)
  return (
    <div>
          <Card className="w-[330px]  h-[465px] mx-1 shadow-lg border-2 border-gray-150 headermenu text-white">
              <Link to={`/saree/${shopbyprice.id}`}>
                  <img
                    src={`${IMGURL}${shopbyprice.thumbnail}`}
                    alt={shopbyprice.productName}
                    width={200}
                    height={150}
                    className="rounded-t-md w-full h-[350px] focus-visible:ring-0 focus:ring-0"
                  />

              </Link>
              <div className="flex justify-center  p-4 flex-col items-center gap-2">
                <p className="text-lg  line-clamp-2 overflow-ellipsis">{shopbyprice.productName}</p>
                <p className="text-md  flex gap-2 items-center">
                { new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(currency==="USD"?shopbyprice.priceUSD:shopbyprice.priceINR)}
                </p>
              </div>
            </Card>
    </div>
  )
} */

export default CardComponent