import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Button } from "../../ui/button"
import {Form,FormControl,FormField,FormItem,FormLabel,FormMessage,} from "../../ui/form"
import { Input } from "../../ui/input"
import { useState } from "react"
import ReCAPTCHA from 'react-google-recaptcha';
import {  RECAPCHASITEKEY } from "../../../constants/appConfig"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@/redux/store"
import { useLoginMutation } from "../../../redux/slice/UserApiSlice"
import { useAddItemCartMutation } from "../../../redux/slice/CartApiSlice"
import { useAddItemWishlistMutation } from "../../../redux/slice/Wishlist"
import { removeAllSareesFromCart } from "../../../redux/features/cartSlice"
import { setCredentials } from "../../../redux/features/authDetails"

const formSchema = z.object({
  mobile: z.string().refine((value) => /^\d{10}$/.test(value), {
    message: 'Mobile number must be exactly 10 digits.',
  }),
  password: z.string().min(8, {
    message: 'Password must be at least 8 characters.',
  }),
})



const SignInComponent = () => {
  const [isVerified, setIsVerified] = useState(true);
  const [error, setError] = useState(false);
  const { cartItems } = useSelector((state: RootState) => state.counterCart)
  const { wishlist } = useSelector((state: RootState) => state.counterWishlist);
  const [Login] = useLoginMutation()
  const [AddItemCart] = useAddItemCartMutation()
  const [AddItemWishlist] = useAddItemWishlistMutation()
  const navigate=useNavigate()
  const dispatch=useDispatch()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      mobile: "",
      password: "",
    },
  });

  const handleVerify = () => {
    setIsVerified(true);
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (isVerified) {
        const response = await Login({ userPassword: values.password, userName: values.mobile });
  
        if ('error' in response) {
          console.error('error:', response.error);
          setError(true);
          return;
        }
  
        const { success, result } = response?.data;
  
        if (success && result) {
          localStorage.setItem("sakhiweb", JSON.stringify(result));
          dispatch(setCredentials());

          if (cartItems.length > 0) {
            await Promise.all(
              cartItems.map(async (item: any) => {
                const response = await AddItemCart({ customerId: result?.refId, productId: item.id, qty: item.count,readyToBuy:1 });
                if ('error' in response) {
                  console.error('error:', response.error);
                  return;
                }
                console.log('CartItemResponse:', response?.data);
              })
            );
            dispatch(removeAllSareesFromCart())
          }

          if (wishlist.length > 0) {
            await Promise.all(
              wishlist.map(async (item: any) => {
                const response = await AddItemWishlist({ customerId: result?.refId, productId: item.id});
                if ('error' in response) {
                  console.error('error:', response.error);
                  return;
                }
                console.log('WishlistItemResponse:', response?.data);
              })
            );
          }

          if (result.userRoleId === 1) {
            navigate('/admin');
          } else {
            navigate('/');
          }
        } else {
          //console.log(response.data);
          setError(true);
        }
      } else {
        console.error('reCAPTCHA verification failed.');
      }
    } catch (error) {
      console.log(error);
    }
  };  

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        {error&& <p className="text-red-500 font-bold my-2">Invalid Credentials</p>}
        <FormField
          control={form.control}
          name="mobile"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Mobile Number</FormLabel>
              <FormControl>
                <Input placeholder="Login Mobile Number" {...field} className="input-field" />
              </FormControl>
              <FormMessage>{fieldState?.error?.message}</FormMessage>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input placeholder="Login Password" type="password" {...field} className="input-field" />
              </FormControl>
              <FormMessage>{fieldState?.error?.message}</FormMessage>
            </FormItem>
          )}
        />
        {/* <ReCAPTCHA
            sitekey={RECAPCHASITEKEY}
            size="normal"
            onChange={handleVerify} 
          /> */}
        <Button type="submit" className="w-full headermenu" disabled={!form.formState.isValid || !isVerified}>
          Login
        </Button>
        <div className="flex justify-between">
            {/* <p>Dont have an account?</p> */}
            <Link to="/forgotpwd" className="text-blue-400 font-semibold">Forgot Password</Link>
            <Link to="/signup" className="text-red-400 font-semibold">Register</Link>
        </div>
      </form>
    </Form>
  );
};


export default SignInComponent