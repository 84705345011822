import {useParams } from 'react-router-dom'
import SareePage from '../components/shared/Detail/SareePage'
import { useGetProductByIdQuery } from '../redux/slice/ProductsApiSlice'

const SareeDetail = () => {
    
    const{id}=useParams()
    const { data: product, isError, isLoading, isSuccess, error} = useGetProductByIdQuery(id)
   
    let content
  
    if (isLoading) {
      content = (
          <div className="flex justify-center items-center h-full">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
          </div>
      );
  }
  
    if (isError) content = <p>{product?.result?.message}</p>

    if (isSuccess&& product?.result){
      content=  <SareePage saree={product?.result} />
    }
    return (
        <div className="py-5 bgcolorgold">
       {content}
    </div>
    )
}

export default SareeDetail



  