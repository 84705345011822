import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Button } from "../../../components/ui/button";
import { useGetCustomerQuery } from "../../../redux/slice/UserApiSlice";
import ShippingInfo, { PaymentInfo } from "./ShippingInfo";
import Payment from "./Payment";
import { useCreateOrderMutation, useUploadBillImageMutation } from "../../../redux/slice/admin/ACreateOrderApiSlice";
import { useGetShoppingCartByCustomerCheckoutMutation } from "../../../redux/slice/CartApiSlice";
import { CheckoutProductT, RazorpaySuccessResponse, cartProduct } from "@/react-app-env";
import {
  useCreateOrderRazorPayMutation,
  useRazorPayValidateDataMutation,
} from "../../../redux/slice/RazoryPayApiSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import domtoimage from 'dom-to-image';
import { formatToCurrency, formatCurrentTime } from "../../../constants";
import { CLIENTNAME, RazorPayKey } from "../../../constants/appConfig";
import { useGetRandomNumberMutation } from "../../../redux/slice/ACustomerAddressApiSlice";

const CHECKOUT_STEPS = [
  {
    name: "Shipping Info",
    component: ({ user }: any) => <ShippingInfo user={user} />,
  },
  {
    name: "Payment Info",
    component: ({ user }: any) => <ShippingInfo user={user} />,
  },
  {
    name: "Payment",
    component: () => <Payment />,
  },
];

const CheckoutStepper = () => {
  const currentUser = JSON.parse(localStorage.getItem("sakhiweb")!);
  const { data, refetch } = useGetCustomerQuery(currentUser?.refId);
  const user = data?.result;
  const [currentStep, setCurrentstep] = useState<number>(1);
  const [FinalPrice, setFinalPrice] = useState();
  const [addressId, setAddressId] = useState();
  const [currency, setCurrency] = useState();
  const [createOrder, { data: orderData, isSuccess: orderSuccess }] = useCreateOrderMutation();
  const [createOrderRazorPay] = useCreateOrderRazorPayMutation();
  const [RazorPayValidateData] = useRazorPayValidateDataMutation();
  const [getShoppingCart, { data: cartData }] = useGetShoppingCartByCustomerCheckoutMutation()
  const navigate = useNavigate()
  const [razorpayRes, setRazorpayRes] = useState<RazorpaySuccessResponse>();
  const componentRef = useRef<HTMLDivElement>(null);
  const [giftVou, setGiftVou] = useState({ id: 0, voucherCode: "", amount: 0 })
  const [getRandomNumber, { isSuccess: isRandomSuccess, data: randomNumber }] = useGetRandomNumberMutation()
  const [orderNumber, setOrderNumber] = useState("")

  useEffect(() => {
    getRandomNumber({})
  }, [getRandomNumber])

  useEffect(() => {
    if (isRandomSuccess && randomNumber?.success) {
      setOrderNumber(randomNumber?.result)
    }
  }, [isRandomSuccess, randomNumber?.result, randomNumber?.success])

  useEffect(() => {
    if (addressId && user?.id && currency) {
      getShoppingCart({ addressId, customerId: user?.id, currency })
    }
  }, [addressId, currency, getShoppingCart, user?.id])


  useEffect(() => {
    if (orderData?.success && orderSuccess) {
      toast.success("Your order got placed successfully")
      navigate(`/myorders`)
    } else if (!orderData?.success && orderSuccess) {
      toast.error("If amount got deducted from your account and order not placed Successfully. Amount will be credit in 7 working days", { autoClose: 5000 })
      navigate(`/cart`)
    }
  }, [orderData, orderSuccess, navigate])

  useEffect(() => {
    async function fetchData() {
      if (currentStep === 3 && user && componentRef.current && addressId && FinalPrice && currency && razorpayRes?.msg && razorpayRes?.paymentId) {
        const dataUrl = await domtoimage.toPng(componentRef.current);
        await Promise.all(cartData?.result?.products?.map(async (item: CheckoutProductT) => {
          let productId = item.productId
          let quantity = item.quantity
          let branchId = item.branchId
          let shippingCost = cartData?.result?.shippingCost;
          let price = currency === "INR" ? item.priceINR : item.priceUSD
          await createOrder({
            customerId: user.id, addressId, productId, currency, quantity, discount: item.discountAmount, price,
            totalAmount: item.grossAmount - item.discountAmount, voucherAmount: giftVou.amount, giftVoucherId: giftVou.id,
            orderTotal: item.netAmount, returnQty: 0, returnAmount: 0, billImage: dataUrl, orderNumber: `${orderNumber}-${productId}`,
            transactionPaymentId: razorpayRes?.paymentId, transactionNo: razorpayRes?.razorpay_order_id, branchId,
            sgstPercent: item.sgstPercent, sgstAmount: item.sgstAmount, cgstPercent: item.cgstPercent, shippingCost,
            cgstAmount: item.cgstAmount, igstPercent: item.igstPercent, igstAmount: item.igstAmount
          });
        }));
      }
    }
    fetchData();
  }, [currentStep, orderNumber, createOrder, user, addressId, FinalPrice, currency, cartData, razorpayRes, giftVou.amount, giftVou.id])

  async function handleProceedToPay() {
    if (currency && FinalPrice) {
      const amount = Math.round(FinalPrice * 100);
      const response = await createOrderRazorPay({ amount, currency });
      if ("data" in response && response?.data && response?.data?.success) {
        const order = response.data;

        var options = {
          key: `${RazorPayKey}`,
          amount,
          currency,
          name: `${CLIENTNAME}`, //your business name
          description: "Test Transaction",
          order_id: order?.result?.id,
          handler: async function (response: any) {
            const body = {
              ...response,
            };
            const valRes = await RazorPayValidateData({ ...body });
            if ("data" in valRes && valRes?.data && valRes?.data?.success) {
              setRazorpayRes({ ...body, msg: "success", paymentId: body?.razorpay_payment_id });
              setCurrentstep((p) => p + 1)
            } else {
              toast.error("Error: Transaction is not valid")
              return;
            }
          },
          prefill: {
            name: "vila",
            email: "vila@example.com",
            contact: "9000000000",
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        var rzp1 = new window.Razorpay(options);

        rzp1.on("payment.failed", function (response: any) {
          alert(response.error.code);
          alert(response.error.description);
          /*  alert(response.error.source);
           alert(response.error.step);
           alert(response.error.reason);
           alert(response.error.metadata.order_id);
           alert(response.error.metadata.payment_id); */
        });
        rzp1.open();
        /* e.preventDefault(); */
      } else {
        toast.error("Error: Create payment order failed")
        return;
      }
    }

  }

  return (<div className="bgcolorgold">
    <div className="wrapper">
      <div className="flex justify-between items-center wrapper">
        {(currentStep === 1 || currentStep === 2) &&
          CHECKOUT_STEPS?.map((step, idx) => {
            return (
              <div
                key={step.name}
                className={`flex justify-center flex-col items-center`}
              >
                <div
                  className={` ${currentStep === idx + 1 ? `bg-blue-500` : currentStep > idx + 1 ? `bg-green-500` : ` bg-gray-400`} flex justify-center rounded-full w-[30px] h-[30px] p-1`}
                >
                  {idx + 1}
                </div>
                <div>{step.name}</div>
              </div>
            );
          })}
      </div>

      {currentStep === 1 && (<ShippingInfo user={user} reftechUser={refetch} setAddressId={setAddressId} addressId={addressId} />)}
      {currentStep === 2 && (<PaymentInfo cartData={cartData} giftVou={giftVou} setGiftVou={setGiftVou} user={user} addressId={addressId} setOrderTotal={setFinalPrice} setCurrency={setCurrency} />)}

      {(currentStep === 3 && razorpayRes?.paymentId && user?.fullName && cartData?.result?.products?.length > 0) && <div>
        <Invoice
          ref={componentRef}
          netAmount={FinalPrice}
          orderNumber={orderNumber}
          giftAmt={giftVou.amount}
          paymentId={razorpayRes?.paymentId}
          type={currency}
          filterReadyToBuyPr={cartData?.result}
        />
      </div>}
      {/*  {(currentStep === 2 && user?.fullName && cartData?.result?.products?.length > 0) && <div>
        <Invoice
          ref={componentRef}
          netAmount={FinalPrice}
          orderNumber={orderNumber}
          giftAmt={giftVou.amount}
          paymentId={razorpayRes?.paymentId}
          type={currency}
          filterReadyToBuyPr={cartData?.result}
        />
      </div>} */}

      {(currentStep === 2) ? (<div className=" flex justify-between my-4">
        <Button
          onClick={() => setCurrentstep(p => p - 1)}
          className="w-[120px] headermenu"
        /*  disabled={(!FinalPrice)} */
        >
          Edit Address
        </Button>
        <Button
          onClick={handleProceedToPay}
          className="w-[120px] headermenu"
          disabled={(!FinalPrice)}
        >
          Proceed To Pay
        </Button>
      </div>) : (currentStep === 1 && <div className=" flex justify-end my-4"> <Button
        onClick={() => setCurrentstep(p => p + 1)}
        className="w-[120px] headermenu"
        disabled={(!addressId)}
      >
        Proceed To Pay
      </Button> </div>)}
    </div>


  </div>);
};

export const Invoice = forwardRef<HTMLDivElement, any>((props, ref) => {
  const { netAmount, giftAmt, type, orderNumber, filterReadyToBuyPr } = props;
  const Subtotal = (filterReadyToBuyPr?.products?.reduce((acc: number, product: CheckoutProductT) => {
    return acc + product?.grossAmount;
  }, 0))

  let shippingAmount = filterReadyToBuyPr?.shippingCost ?? 0
  /*   let shippingAmount = (filterReadyToBuyPr?.products?.reduce((acc: number, product: CheckoutProductT) => {
      return acc + product?.shippingCost;
    }, 0)) */

  const GstAmount = (filterReadyToBuyPr?.products?.reduce((acc: number, product: CheckoutProductT) => {
    return acc + product?.cgstAmount + product?.igstAmount + product?.sgstAmount;
  }, 0))

  const DisAmount = (filterReadyToBuyPr?.products?.reduce((acc: number, product: CheckoutProductT) => {
    return acc + product?.discountAmount
  }, 0))


  const groupedByTax = Object.values(filterReadyToBuyPr?.products?.reduce((acc: any, product: CheckoutProductT) => {
    const gst = parseFloat(product.igstPercent) + parseFloat(product.cgstPercent) + parseFloat(product.sgstPercent);
    const taxKey = `${gst}`;

    if (!acc[taxKey]) {
      acc[taxKey] = { gst, cgst: 0, igst: 0, sgst: 0, products: [] };
    }
    acc[taxKey].cgst += product.cgstAmount;
    acc[taxKey].sgst += product.sgstAmount;
    acc[taxKey].igst += product.igstAmount;
    acc[taxKey].products.push(product);

    return acc;
  }, {}));

  const totalQty = filterReadyToBuyPr?.products?.reduce((inv: any, pro: any) => inv + pro.quantity, 0)
  const totalCgst = groupedByTax.reduce((inv: number, pro: any) => inv + pro.cgst, 0) as number;
  const totalSgst = groupedByTax.reduce((inv: number, pro: any) => inv + pro.sgst, 0) as number;
  const totalIgst = groupedByTax.reduce((inv: number, pro: any) => inv + pro.igst, 0) as number;
  const totalTax = groupedByTax.reduce((inv: number, pro: any) => inv + pro.cgst + pro.sgst + pro.igst, 0) as number;

  return (
    <div ref={ref} className="invoice p-2 max-w-[266px] bg-white rounded-md text-xs mx-0">
      <div className="text-center w-full text-[10px] leading-normal">
        <img src="/assets/logo/sakhi_logo.png" alt="sakhi" className="w-[180px] mx-auto h-auto" />
        <h2 className="text-sm font-semibold mb-1">SS Enterprise</h2>
        <p>Phno: 888888663</p>
        <p>GSTIN: 36AFFS7174J1ZL</p>
      </div>
      <div className="text-xs flex gap-1 justify-between mt-3 w-full px-1">
        <p>Order No:{orderNumber}</p>
        <p>Date:{new Date().toLocaleDateString()}</p>
      </div>

      <table className="w-full border-collapse border border-black mt-2 text-[10px]">
        <thead>
          <tr>
            <th className="border border-black p-[2px] font-normal">Product</th>
            <th className="border border-black p-[2px] font-normal">MRP</th>
            <th className="border border-black p-[2px] font-normal">Qty</th>
            <th className="border border-black p-[2px] font-normal">Dis</th>
            <th className="border border-black p-[2px] font-normal">Amount</th>
          </tr>
        </thead>
        <tbody>
          {filterReadyToBuyPr?.products?.map((scp: CheckoutProductT) => <tr key={scp.id}>
            <td className="border-l border-r border-black p-[1px]">
              <p className="flex justify-between"><span>{scp.hsnCode}</span></p>
              <span className="line-clamp-1 max-w-24"> {scp.productName}</span></td>
            <td className="p-[1px] text-right">{type === "INR" ? (scp.priceINR).toFixed(2) : (scp.priceUSD).toFixed(2)}</td>
            <td className="border-l border-r border-black text-center">{scp.quantity}</td>
            <td className="p-[1px] text-right"> {scp.discountAmount > 0 ? scp.discountAmount : "0.00"}
            </td>
            <td className="border-l border-r border-black p-[1px] text-right">
              {scp.grossAmount - scp.discountAmount}
            </td>
          </tr>)}
        </tbody>
      </table>

      <div className="mt-1 p-1 text-[10px]">
        <p className="justify-between flex gap-2 "><span>Gross Amount:</span> <span className="font-medium">{formatToCurrency(Subtotal, type)}</span></p>
        {DisAmount > 0 && <p className="justify-between flex gap-2 text-wrap"><span>Dis Amount:</span> <span className="font-medium">-{formatToCurrency(DisAmount, type)}</span></p>}
        {giftAmt > 0 && <p className="justify-between flex gap-2 text-wrap"><span>Voucher Amount:</span> <span className="font-medium">-{formatToCurrency(giftAmt, type)}</span></p>}
        <p className="justify-between flex gap-2 text-wrap"><span>Tax Amount:</span> <span className="font-medium">+{formatToCurrency(GstAmount, type)}</span></p>
        <p className="justify-between flex gap-2 text-wrap"><span>Shipping Amount:</span> <span className="font-medium">+{formatToCurrency(shippingAmount, type)}</span></p>
        <p className="justify-between flex gap-2 text-sm text-black border-t border-b border-black font-semibold"><span>Net Amount:</span> <span>{formatToCurrency(netAmount, type)}</span> </p>
      </div>
      <p className="mt-1 text-[11px]">GST Summery</p>
      <table className="w-full text-[10px] border border-black">
        <thead>
          <tr className="border border-black p-1 ">
            <th className="font-normal" >#</th>
            <th className="font-normal" >CGST</th>
            <th className="font-normal" >SGST</th>
            <th className="font-normal" >IGST</th>
            <th className="font-normal" >Tax Amt</th>
          </tr>
        </thead>
        <tbody>
          {groupedByTax.map((taxGroup: any, index: any) => (
            <React.Fragment key={taxGroup.gst}>
              <tr>
                <td className="p-[2px] text-center">{taxGroup.products?.length} </td>
                <td className="p-[2px] text-center">{(taxGroup.cgst).toFixed(2)}</td>
                <td className="p-[2px] text-center">{(taxGroup.sgst).toFixed(2)}</td>
                <td className="p-[2px] text-center">{(taxGroup.igst).toFixed(2)}</td>
                <td className="p-[2px] text-center">{formatToCurrency(taxGroup.cgst + taxGroup.sgst + taxGroup.igst, type)}</td>
              </tr>
            </React.Fragment>
          ))}
          <tr className="border border-black">
            <td className=" p-[2px] text-center">Total</td>
            <td className=" p-[2px] text-center">{formatToCurrency(totalCgst, type)}</td>
            <td className=" p-[2px] text-center">{formatToCurrency(totalSgst, type)}</td>
            <td className=" p-[2px] text-center">{formatToCurrency(totalIgst, type)}</td>
            <td className=" p-[2px] text-center">{formatToCurrency(totalTax, type)}</td>
          </tr>
        </tbody>
      </table>
      <div className="mt-2 text-xs grid grid-cols-3 gap-3 items-center p-1">
        <p>Time:{formatCurrentTime(new Date())}</p>
        {/* <p>User: Cashier</p> */}
        <p>Tot Qty:{totalQty}</p>
        <p>Tot Items:{filterReadyToBuyPr?.length}</p>
      </div>
      <div className="mt-2 text-xs border-y-1 border-black p-1">
        <p>Price Inclusive of All Taxes</p>
        <p>Goods once sold cannot be returned</p>
        <p>NO Guarantee for color - Jari, Silk Cotton and Stiching of goods</p>
      </div>
      <div className="mt-2 mb-0 space-y-1 flex justify-end flex-col items-end p-2">
        <p>S S Enterprises</p>
        <p>Thank you & Buy again!</p>
      </div>

    </div>
  );
});


export default CheckoutStepper;
