"use client"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { Row } from "@tanstack/react-table"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../ui/alert-dialog"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "../../../../ui/dropdown-menu"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog"
import { Button } from "../../../../ui/button"
import { MdDeleteOutline, MdEdit } from "react-icons/md"
import { useEffect, useState } from "react"
import { Input } from "../../../../ui/input"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../ui/form"
import { toast } from "react-toastify"
import { useSaveGiftVoucherMutation } from "../../../../../redux/slice/admin/ACategoryApiSlice"
import { GiftVoucherT } from "@/react-app-env"


interface DataTableRowActionsGiftVouProps<TData> {
  row: Row<GiftVoucherT>,
  onDelete: any,
  onEdit: any
}

export function DataTableRowActionsGiftVou<TData>({
  row, onDelete, onEdit
}: DataTableRowActionsGiftVouProps<TData>) {
  const giftVou = row.original;
  const [isDeleteopen, setIsDeleteOpen] = useState<boolean>(false)
  const [isEditopen, setIsEditOpen] = useState<boolean>(false)

  return (<>
    {isDeleteopen && <DeleteGiftVoucher giftVou={giftVou} setIsDeleteOpen={setIsDeleteOpen} onDelete={onDelete} isDeleteopen={isDeleteopen} />}
    {isEditopen && <EditGiftVoucher giftVou={giftVou} isEditopen={isEditopen} onEdit={onEdit} setIsEditOpen={setIsEditOpen} />}
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => setIsEditOpen(true)}>
          Edit Voucher<DropdownMenuShortcut><MdEdit size={20} /></DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => setIsDeleteOpen(true)}>
          Delete
          <DropdownMenuShortcut><MdDeleteOutline color="red" size={20} /></DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  </>)
}

export function DeleteGiftVoucher({ giftVou, isDeleteopen, setIsDeleteOpen, onDelete }: { onDelete: any, giftVou: GiftVoucherT, isDeleteopen: boolean, setIsDeleteOpen: any }) {

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (isDeleteopen) {
      setOpen(true)
    }
  }, [isDeleteopen])


  const handleClose = () => {
    setOpen(false)
    setIsDeleteOpen(false)
  }


  const handleDelete = async () => {
    onDelete(giftVou.id)
    setOpen(false)
    setIsDeleteOpen(false)
    toast.success("Gift Voucher Deleted SuccessFully")
  }

  return (<>
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <AlertDialogTrigger asChild>
        {/*  <Button size={"sm"}
      className="flex bg-red-500 data-[state=open]:bg-muted"
    >
      Delete Bill
    </Button> */}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete Gift Voucher from servers.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleDelete}>Delete</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  </>)
}

const formSchema = z.object({
  voucherCode: z.string().min(2, {
    message: "Voucher code must be at least 2 characters.",
  }),
  voucherAmount: z.string().regex(/^\d+(\.\d{1,2})?$/, {
    message: "Voucher amount must be a valid decimal number.",
  }),
  quantity: z.number().int().nonnegative({
    message: "Quantity must be a non-negative integer.",
  }),
});
export function EditGiftVoucher({ giftVou, isEditopen, setIsEditOpen, onEdit }: { onEdit: any, giftVou: GiftVoucherT, isEditopen: boolean, setIsEditOpen: any }) {
  const [open, setOpen] = useState(false)
  const [saveGiftVoucher, { data: giftVouRes, isSuccess }] = useSaveGiftVoucherMutation()

  useEffect(() => {
    if (isSuccess && giftVouRes.success) {
      toast.success("Gift Voucher Edited Successfully")
      onEdit()
    }/* else if(!isSuccess || !giftVou.success){
      toast.error("Failed To Add New Gift Voucher")
    } */
  }, [giftVouRes, isSuccess, onEdit])

  useEffect(() => {
    if (isEditopen) {
      setOpen(true)
    }
  }, [isEditopen])

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { ...giftVou },
  })


  async function onSubmit(values: z.infer<typeof formSchema>) {
    await saveGiftVoucher({ ...values, id: giftVou.id,branchId:1 })
  }

  const handleClose = () => {
    setOpen(false);
    setIsEditOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true)
      } else {
        handleClose()
      }
    }}>
      {/*   <DialogTrigger asChild>
        <Button size={"sm"} className='bg-gradient-to-r from-green-500 to-green-600 py-1'>Add EGift Voucher</Button>
      </DialogTrigger> */}
      <DialogContent className="sm:max-w-[500px] overflow-y-auto max-h-screen">
        <DialogHeader>
          <DialogTitle>Edit Gift Voucher</DialogTitle>

        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="md:grid-cols-2 grid-cols-1 gap-4 py-2 grid">
            <FormField
              control={form.control}
              name="voucherCode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Voucher Code</FormLabel>
                  <FormControl>
                    <Input placeholder="voucherCode" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="voucherAmount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Voucher Amount</FormLabel>
                  <FormControl>
                    <Input placeholder="voucherAmount" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="quantity"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Quntity</FormLabel>
                  <FormControl>
                    <Input placeholder="quantity" {...field} onChange={e => field.onChange(+e.target.value)} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className='flex md:gap-10 gap-4 my-3'>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" className="headermenu">Edit Voucher</Button>
            </div>

          </form>
        </Form>


      </DialogContent>
    </Dialog>
  )
}


