import { ColumnDef } from "@tanstack/react-table";
import { HSNCodeDetailsT } from "@/react-app-env";
import { DataTableRowActionsHSN } from "./data-table-row-actions";
import { DataTableColumnHeader } from "../Table/data-table-column-header";


export const HSNCodeColumns = ({ setRefetch }: { setRefetch: any }): ColumnDef<HSNCodeDetailsT>[] => [

  {
    accessorKey: "chapterCode",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Chapter Code" />
    ),
    cell: ({ row }) => (
      <div className="text-left">{row.getValue("chapterCode")}</div>
    ),
  },
  {
    accessorKey: "taxId",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="taxId" />
    ),
    cell: ({ row }) => (
      <div className="text-left">{row.getValue("taxId")}</div>
    ),
  },
  {
    accessorKey: "hsnCode",
    header: "HSN Code"
  },
  {
    accessorKey: "chapterName",
    header: "Chapter Name",
  },
  {
    accessorKey: "productDescription",
    header: "Description"
  },
  {
    id: "actions",
    cell: ({ row }) => <DataTableRowActionsHSN row={row} setRefetch={setRefetch} />,
  },
];

