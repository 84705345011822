import { useDeleteColorAMutation, useSaveColorAMutation, } from "../../redux/slice/admin/ACategoryApiSlice";
import { Button } from "../../components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog"
import { Input } from "../../components/ui/input"
import { Label } from "../../components/ui/label"
import { ChangeEvent, startTransition, useState } from "react";
import { useGetAllColorsQuery } from "../../redux/slice/CategoryApiSlice";
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from 'react-icons/fa';


type ColorCodeT = {
  id: number,
  colorCode: string
}

const ColorAdmin = () => {
  const { data: allColors } = useGetAllColorsQuery("colorsList", { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true, })

  return (
    <div className="max-w-full rounded-md  border-2 border-gray-300 md:p-4 p-2 md:m-2">
      <div className="space-y-6">
        <div className="flex gap-10 items-center justify-between">
          <p className="text-lg font-semibold">Product Colors</p>
          <AddColor />
        </div>
        <div className="my-10 flex flex-wrap gap-6 ">
          {allColors?.result?.length > 0 && allColors?.result?.map((color: ColorCodeT) => (
            <div key={color.id} className="flex flex-col items-center border-2 border-gray-500 shadow-lg p-2 rounded-md">
                <div
                  className="w-16 h-16 rounded-full shadow-lg transition-all duration-300 hover:scale-110"
                  style={{ backgroundColor: `${color.colorCode}` }}
                ></div>
              <div className="flex gap-2 mt-4">
                <EditColor colorCodeE={color} />
                <DeleteColor id={color.id} />
              </div>
            </div>
          ))}
        </div>
    {/*     <div className="my-10 flex flex-wrap gap-10">
          {allColors?.result?.length > 0 &&
            allColors?.result?.map((color: ColorCodeT) => <div key={color.id} className="flex justify-between ">
              <div className='w-10 h-10 rounded-full ' style={{ backgroundColor: `${color.colorCode}` }}></div>
              <div className="flex gap-4">
                <EditColor colorCodeE={color} />
                <DeleteColor id={color.id} />
              </div>
            </div>)}
        </div> */}
      </div>
    </div>
  )
}


export function AddColor() {
  const [addNewColor] = useSaveColorAMutation();
  const [colorCode, setColorCode] = useState<string>("")
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
    setColorCode("")
  }
  const handleCategory = async () => {
    await addNewColor({ colorCode });
    setColorCode("")
    setOpen(false)
  };

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <DialogTrigger asChild>
        <Button variant="default" size={"sm"} className='headermenu'>Add New Color</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add New Color</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Select Color
            </Label>
            <Input
              id="name"
              type='color'
              onChange={(e: ChangeEvent<HTMLInputElement>) => setColorCode(e.target.value)}
              className="col-span-3 input-field max-w-20"
            />
          </div>
        </div>
        {colorCode && <div className='flex gap-4 items-center'>Selected Color: <div className='w-10 h-10 rounded-full' style={{ backgroundColor: `${colorCode}` }}></div></div>}
        <DialogFooter>
          <Button type="button" onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={handleCategory} className='headermenu'>Add Color</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
export function EditColor({ colorCodeE }: { colorCodeE: ColorCodeT }) {
  const [addNewColor] = useSaveColorAMutation();
  const [colorCode, setColorCode] = useState<string>("")
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
    setColorCode("")
  }

  const handleCategory = async () => {
    await addNewColor({ colorCode, id: colorCodeE.id });
    setColorCode("")
    setOpen(false)
  };

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <DialogTrigger asChild>
        <Button variant={"ghost"} ><FaRegEdit size={20} /></Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit New Color</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Select Color
            </Label>
            <Input
              id="name"
              type='color'
              onChange={(e: ChangeEvent<HTMLInputElement>) => setColorCode(e.target.value)}
              className="col-span-3 input-field max-w-20"
            />
          </div>
        </div>
        {colorCode ? <div className='flex gap-4 items-center'>Selected Color: <div className='w-10 h-10 rounded-full' style={{ backgroundColor: `${colorCode}` }}></div></div> :
          <div className='flex gap-4 items-center'>Selected Color Previous: <div className='w-10 h-10 rounded-full' style={{ backgroundColor: `${colorCodeE.colorCode}` }}></div></div>}
        <DialogFooter>
          <Button type="button" onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={handleCategory} className='headermenu'>Update Color</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export function DeleteColor({ id }: { id: number }) {
  const [deleteColor] = useDeleteColorAMutation()

  const handleRemoveCat = () => {
    deleteColor(id)
  };
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="ghost"><MdDelete size={20} color="red" /></Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete Color.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={() => startTransition(handleRemoveCat)}
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}


const NavbarM = () => {
  return <div className='navbarTop'>
    <p className="text-indigo-500 font-medium">Product Color</p>
  </div>
}


export default ColorAdmin