import {
  Sheet,
  SheetContent,
  SheetTitle,
  SheetTrigger,
} from "../../ui/sheet";
import { Separator } from "../../ui/separator";
/*   import NavItems from "./NavItems" */
import { FaBars } from "react-icons/fa";
import { SubNavigation } from "./NavItems";
import Search from "./Search";
import { useGetAllSubcategoryQuery } from "../../../redux/slice/CategoryApiSlice";
import React, { useState } from "react";
import { Button } from "../../../components/ui/button";
import { subHeaderLinks } from "../../../constants";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";

const MobileNav = () => {
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <nav className="md:hidden sticky top-0">
      <Sheet key="left" open={open} onOpenChange={() => {
        if (!open) {
          setOpen(true)
        } else {
          handleClose()
        }
      }}>
        <SheetTrigger className="align-middle">
          <FaBars size="26px" color="black" />
        </SheetTrigger>
        <SheetContent className="flex flex-col gap-4 bg-white md:hidden overflow-auto" side="left">
          <SheetTitle className="flex justify-center">
          <img
            src="/assets/logo/sakhi_logo.png"
            alt="logo"
            width={128}
            height={38}
          />
          </SheetTitle>
          <Separator className="border border-gray-100" />
          <div>
            {/* <Search/> */}
            {/* <SubNavigation/> */}
            <MobileNavItems setOpen={setOpen} />
          </div>
        </SheetContent>
      </Sheet>
    </nav>
  );
};


const MobileNavItems = ({ setOpen }: { setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const [catId, setCatId] = useState<number | null>(null);
  const { data: AllSubCategories } = useGetAllSubcategoryQuery("subCategoriesList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const navigate = useNavigate()
  const [showSubC, setShowSubC] = useState(false);

  const handleCategory = (categoryId: number) => {
    if (catId === categoryId) {
      setShowSubC(!showSubC);
    } else {
      setCatId(categoryId);
      setShowSubC(true);
    }
  };

  return (
    <div>
      {subHeaderLinks.map((links) => {
        const hasSubCategories =
          links.categoryId === catId && showSubC && AllSubCategories?.result?.length > 0;

        return (
          <div key={links.categoryId} className="space-y-2">
            <Button variant={"link"} onClick={() => handleCategory(links.categoryId)} className="flex w-full justify-between hover:text-green-600 lg:text-green-900  font-medium items-start rounded-none border-b border-gray-400 my-2">
              <span>{links.label}</span>
              <span>
                {hasSubCategories ? (
                  <FaChevronUp size={18} className="ml-4" />
                ) : (
                  <FaChevronDown size={18} className="ml-4" />
                )}
              </span>
            </Button>
            {hasSubCategories && (
              <div className="ml-6 flex flex-col gap-3">
                <button key={0} onClick={() => { navigate(`/category/${catId}`); setOpen(false) }} className="text-sm  hover:text-green-600  text-start">
                  {links.label}
                </button>
                {AllSubCategories.result
                  .filter((sub: any) => sub.categoryId === catId)
                  .map((sub: any) => (
                    <button key={sub.id} onClick={() => { navigate(`/subcategory/${sub.id}`); setOpen(false) }} className="text-sm text-start">
                      {sub.subcategoryName}
                    </button>
                  ))}
              </div>
            )}
            {/* {hasSubCategories && (
              <div className="ml-6 flex flex-col gap-2">
                <Link key={0} to={`/category/${catId}`} className="py-1 text-sm">
                  {links.label}
                </Link>
                {AllSubCategories.result
                  .filter((sub: any) => sub.categoryId === catId)
                  .map((sub: any) => (
                    <Link key={sub.id} to={`/subcategory/${sub.id}`} className="text-sm">
                      {sub.subcategoryName}
                    </Link>
                  ))}
              </div>
            )} */}
          </div>
        );
      })}
      <button onClick={() => { navigate(`/shopbyprice/10000`); setOpen(false) }} className={`ml-5 my-4 text-sm font-medium hover:text-green-600 lg:text-green-900 flex gap-4 items-center`}>BUDGET SAREES
      </button>
    </div>
  );
};



export default MobileNav;


