import { Row } from "@tanstack/react-table";

import { Button } from "../../../../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../ui/select";
import { OrderT, } from "@/react-app-env";
import { Link, useNavigate } from "react-router-dom";
import { useDeleteProductAMutation } from "../../../../../redux/slice/admin/AProductsApiSlice";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../ui/alert-dialog";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { useUpdateOrderCourierInfoMutation, useUpdateOrderStatusAMutation } from "../../../../../redux/slice/admin/ACreateOrderApiSlice";
import { cancelledStatuses, formatToCurrency, orderStatuses } from "../../../../../constants/index";
import { Input } from "../../../../ui/input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";
import { Separator } from "../../../../../components/ui/separator";
import { DropdownMenu, DropdownMenuContent, DropdownMenuShortcut, DropdownMenuTrigger } from "../../../../../components/ui/dropdown-menu";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { IMGURL } from "../../../../../constants/appConfig";
import { MdOutlineRemoveRedEye } from "react-icons/md";

interface DataTableRowActionsProps<TData> {
  row: Row<OrderT>;
  type?: string,
  SetRefetch?: any
}

export function DataTableRowActions<TData>({
  row
}: DataTableRowActionsProps<TData>) {
  const viewBill = row.original;

  return (<>
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
          <Link target="_blank" to={`${IMGURL}${viewBill.imageUrl}`} className="flex items-center justify-between text-sm p-1">
          View Invoice<DropdownMenuShortcut><MdOutlineRemoveRedEye size={20} /></DropdownMenuShortcut></Link>

      </DropdownMenuContent>
    </DropdownMenu>
  </>)
}
export function DataTableRowActionsOrder<TData>({
  row, type, SetRefetch
}: DataTableRowActionsProps<TData>) {
  const order = row.original;
  const [updateorderStatus, { data, isSuccess }] = useUpdateOrderStatusAMutation();
  const [orderStatus, setOrderStatus] = useState<string>(order.orderStatus);
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const status = type === "cancelled" ? cancelledStatuses : orderStatuses

  const handleUpdateOrder = async () => {
    await updateorderStatus({ ordStatus: orderStatus, ordId: order.id });
    if(SetRefetch){
      SetRefetch((p: any) => !p)
    }
    setOpen(false)
  };
  const changeHandler = (value: string) => {
    setOrderStatus(value);
  };
  return (
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <AlertDialogTrigger asChild>
        <Button variant="ghost" className="text-sm font-normal">
          {order.orderStatus} <FaRegEdit size={16} className="pl-1" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Change Order Status</AlertDialogTitle>
          <AlertDialogDescription>
            <Select value={orderStatus} onValueChange={changeHandler}>
              <SelectTrigger className="w-[60%] bg-white ring-1">
                <SelectValue placeholder="Theme" />
              </SelectTrigger>
              <SelectContent className="bg-gray-100">
                {status?.map((status) => (
                  <SelectItem key={status.id} value={status.name}>
                    {status.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleUpdateOrder}>Update Status</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
export function DataTableRowActionsCourier<TData>({
  row, SetRefetch
}: DataTableRowActionsProps<TData>) {
  const order = row.original;
  const [updateCourierStatus] = useUpdateOrderCourierInfoMutation();
  const [cName, setCName] = useState<string>("DTDC Express Limited");
  const [cBillNo, setCBillNo] = useState<string>("");
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }


  const handleUpdateCourierInfo = async () => {
    await updateCourierStatus({ courierName: cName, orderId: order.id, billNo: cBillNo });
    setOpen(false)
    if(SetRefetch){
      SetRefetch((p: any) => !p)
    }
  };

  return (
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <TooltipProvider delayDuration={100}>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" className="text-sm" onClick={() => setOpen(true)}>
              <FaRegEdit size={20} className={`pl-1 ${order.CourierBillNo?"text-green-800":"text-orange-800" }`} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            {order.CourierBillNo?<>
            <p className=" font-medium">Courier Info</p>
            <Separator/>
            <p className=" font-medium">{order.courierName}</p>
            <p className=" font-medium">{order.CourierBillNo}</p>
            </>:<p className=" font-medium">Enter Courier Info</p>}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      {/*  <AlertDialogTrigger asChild>
        <Button variant="ghost" className="text-sm">
          {order.courierName}{order.CourierBillNo&&", "}{order.CourierBillNo} <FaRegEdit size={20}  className="pl-1 text-orange-800" />
        </Button>
      </AlertDialogTrigger> */}
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Change/Enter Courier Info</AlertDialogTitle>
          <AlertDialogDescription className="flex flex-col gap-2">
            <Input className="text-black" value={cName} onChange={(event) => setCName(event.target.value)} placeholder="Courier Service" />
            <Input className="text-black" value={cBillNo} onChange={(event) => setCBillNo(event.target.value)} placeholder="Courier Bill No" />
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleUpdateCourierInfo}>Update/Add Courier Info</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
export function TaxCalulations<TData>({
  row,
}: DataTableRowActionsProps<TData>) {
  const order = row.original;
  const currency = order.currency;
  const igstAmount = Number(order.igstAmount);
  const cgstAmount = Number(order.cgstAmount)
  const sgstAmount = Number(order.sgstAmount)
  const igstPercent = Number(order.igstPercent);
  const cgstPercent = Number(order.cgstPercent)
  const sgstPercent = Number(order.sgstPercent)
  const formatted = formatToCurrency(igstAmount + cgstAmount + sgstAmount, currency)
  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger className=" underline text-sm  ">{formatted}</TooltipTrigger>
        <TooltipContent>
          <p className=" font-medium">IGST Amt:({igstPercent}%) {igstAmount}</p>
          <p className=" font-medium">SGST Amt:({sgstPercent}%) {sgstAmount}</p>
          <p className=" font-medium">CGST Amt:({cgstPercent}%) {cgstAmount}</p>

        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
export function RazorPayTransactions<TData>({
  row,
}: DataTableRowActionsProps<TData>) {
  const order = row.original;

  return (
    <div>
      <p >{order.transactionNo}</p>
      <p >{order.transactionPaymentId}</p>
    </div>
  );
}
export function OrderAmountCalulations<TData>({
  row,
}: DataTableRowActionsProps<TData>) {
  const order = row.original;
  const currency = order.currency;
  const price = formatToCurrency(Number(order.price), currency);
  const qty = order.quantity;
  const totalAmount = formatToCurrency(Number(order.totalAmount), currency);
  const discount = formatToCurrency(Number(order.discount), currency);
  const orderTotal = formatToCurrency(Number(order.orderTotal), currency);
  const shippingCost = formatToCurrency(Number(order.shippingCost), currency);
  const igstAmount = Number(order.igstAmount);
  const cgstAmount = Number(order.cgstAmount)
  const sgstAmount = Number(order.sgstAmount)
  const formattedTax = formatToCurrency(igstAmount + cgstAmount + sgstAmount, currency)
  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger className=" underline text-sm  ">{orderTotal}</TooltipTrigger>
        <TooltipContent>
          <p className=" font-medium">Price: {price}</p>
          <p className=" font-medium">Qty: {qty}</p>
          <p className=" font-medium">Discount: -{discount}</p>
          <Separator />
          <p className=" font-medium">Total Amount: +{totalAmount}</p>
          <p className=" font-medium">Shipping Cost: +{shippingCost}</p>
          <p className=" font-medium">Tax Amount: +{formattedTax}</p>

        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export function AlertDialogDemo({ id }: { id: number }) {
  const [deleteProductA, { isSuccess, data }] = useDeleteProductAMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess && data?.success) {
      /*  navigate("/admin/dashboard/products") */
      window.location.reload();
    }
  }, [isSuccess, navigate, data]);

  const handleRemoveProduct = async () => {
    await deleteProductA(id);
  };
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="ghost" className="pl-0">
          Delete <RiDeleteBin6Line size={18} />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently remove the
            Product from app.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleRemoveProduct}>Continue</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
