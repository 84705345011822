import React, { Dispatch, useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from '../../components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog"
import { Input } from "../../components/ui/input"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form"
import { DataTable } from '../../components/shared/admin/components/Table/data-table'
import { SCDefaultValues, SCFormSchema } from '../../constants'
import { toast } from 'react-toastify'
import { useGetAllShippingCostQuery, useSaveShippingCostMutation } from '../../redux/slice/admin/ACreateOrderApiSlice'
import {  SCArea, SCCountrySelect } from '../../components/shared/admin/components/Dropdown'
import { SCColumns } from '../../components/shared/admin/components/Shipping/SCColumns'

const ShippingCost = () => {
  const { data: scData, isLoading, isSuccess, isError,refetch } = useGetAllShippingCostQuery("SCList", {refetchOnFocus: true, refetchOnMountOrArgChange: true })

let content;

if (isLoading) {
  content = (
    <div className="flex justify-center items-center h-full mt-48">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
    </div>
  );
}

const columns = useMemo(()=>SCColumns({refetch}),[])
if (isError) content = <p>{scData?.result?.message}</p>

if (isSuccess){
  content=  <section >
  <main className=" border-2 rounded-md border-gray-500 md:m-2">
     <div className="flex justify-between p-4">
      <h5 className='h5-bold'>Shipping Cost Settings</h5>
      <AddSC />
    </div>
    {scData?.result.length>0?<div className=" px-2">
      {scData?.result?.length>0 &&    <DataTable data={scData?.result} columns={columns}  />}
    </div>:<p className=" p-4 rounded-md border-2  md:overflow-hidden shadow-lg">
      No Shipping Cost Available 
    </p>}
  </main>
  </section>
}

return <>{content}</>;

}


export function AddSC() {
  const [open, setOpen] = useState(false)
  const [saveShippingCost, { data: SCD, isSuccess }] = useSaveShippingCostMutation()

  const form = useForm<z.infer<typeof SCFormSchema>>({
    resolver: zodResolver(SCFormSchema),
    defaultValues: SCDefaultValues
  })

  useEffect(() => {
    if (isSuccess && SCD?.success) {
        toast.success("New Shipping Cost Added Successfully")
    }
  }, [isSuccess, SCD?.success])

  async function onSubmit(values: z.infer<typeof SCFormSchema>) {
    await saveShippingCost({ ...values })
    handleClose()
  }

  const { reset,watch } = form;
  const country = watch("country")
  const handleClose = () => {
    setOpen(false);
    reset()
  };

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true)
      } else {
        handleClose()
      }
    }}>
      <DialogTrigger asChild>
        <Button size={"sm"} className='bg-gradient-to-r from-green-500 to-green-600 py-1'>Add Shipping Cost</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px] overflow-y-auto max-h-screen">
        <DialogHeader>
          <DialogTitle>Add Shipping Cost</DialogTitle>

        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="md:grid-cols-2 grid-cols-1 gap-4 py-2 grid">
          <FormField
                control={form.control}
                name="country"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Country</FormLabel>
                    <FormControl>
                      <SCCountrySelect value={field.value} onChange={field.onChange} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            <FormField
              control={form.control}
              name="area"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Area</FormLabel>
                  <FormControl>
                {country==="India" ? <SCArea value={field.value} onChange={field.onChange} />:
                    <Input  {...field} className='input-field' placeholder='any' />}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Shipping Amount</FormLabel>
                  <FormControl>
                    <Input placeholder="10.2" {...field} onChange={(e)=>field.onChange(+e.target.value)} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <div className='flex md:gap-10 gap-4 my-3 md:col-span-2 col-span-1 items-end'>
              <Button type="button" onClick={handleClose}>Cancel</Button>
              <Button type="submit" className="headermenu">Save Shipping Cost</Button>
            </div>
          </form>
        </Form>


      </DialogContent>
    </Dialog>
  )
}
export default ShippingCost